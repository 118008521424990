import React from 'react'
import Header from "../../common/Header"
import Footer from '../../common/Footer'
function FoundetionlStage() {
  return (
    <div>
   <Header></Header>
   <div class="edu-breadcrumb-area breadcrumb-style-2 bg-image bg-image--19">
    <div class="container">
      <div class="breadcrumb-inner">
        <div class="page-title">
          <h1 class="title">Academics</h1>
        </div>
      </div>
    </div>
  </div>

  <div class="blog-details-area section-gap-equal">
        <div class="container">
          <div class="row row--30">
            <div class="col-lg-12">
              <div class="blog-details-content foun_stage_main">
                <div class="entry-content">
                  <span class="category"></span>
                  <h3 class="title">Foundational Stage</h3>
                  <div class="thumbnail">
                    <img src="https://www.collinsdictionary.com/images/full/school_309241295.jpg" alt="Blog Image" />
                  </div>
                </div>
                <p>As per the guidelines of CBSE, we follow the curriculum from class Nursery to XII as mentioned below.
Pre-Primary is a stage where a small kid tries to explore this world. We help him/her by providing a happy and caring environment that encourages learning, exploring, individuality and values. As a result, when a child steps out of pre-Primary stage, he or she has not only mastered the environment but also possesses a sense of confidence and the proficiency to tackle higher levels of learning. At this stage learning comes from the child’s own natural inner guidance and expresses itself in outward behavior as the child’s various individual interests are at work. Do make a child more creative, innovative and confident generally we do various activities based on Maths, Language, Practical Life , Sensorial and Culture. </p>
                {/*<ul>
                  <li>Aute irure dolor in reprehenderit</li>
                  <li>Occaecat cupidatat non proident sunt in culpa</li>
                  <li>Pariatur enim ipsam.</li>
  </ul>*/}
               {/* <blockquote>
                  <p>Lorem ipsum dolor amet con sectur elitadicing elit sed do usmod tempor uincididunt enim minim veniam nostrud.</p>
                  <h5 class="author">Simon Baker</h5>
                </blockquote>*/}
              </div>
            </div>
          </div>
        </div>
      </div> 
      <Footer></Footer>
       </div>
  )
}

export default FoundetionlStage