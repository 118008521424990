import React, { useRef } from "react";
import "./CampusTour.css";
import { ImFileText2, ImDownload } from "react-icons/im";
import { AiTwotoneBulb, AiOutlineSmile } from "react-icons/ai";
import { Link } from "react-router-dom";
function CampusTour() {
  const ref = useRef();
  const func = () => {
    ref.current.window.scrollTo(0, 0);
  };

  return (
    <div>
      <div>
        <div className="home-one-cta-two cta-area-1 banner_1 ">
          <div className="container">
            <div className="row text-center">
              <div className="col-md-12">
                <div className="about_over" />
                <div className="about_school campustour">
                  <h1>CAMPUS TOUR</h1>
                  <br />
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="missionandvision-content-contaioner"
        style={{
          margin: "15px",
          position: "relative",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          class="edu-course  course-style-14 courserdtyle"
          style={{ position: "absolute", top: "-170px", borderRadius: "25px" }}
        >
          <div class="inner">
            <div>
              <div
                className="inner-content"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div class=" videogallerypositionrelative">
                  <div
                    style={{ marginTop: "0" }}
                    class="video-gallery video-gallery-5 videogallery-position"
                    data-sal-delay="150"
                    data-sal="slide-left"
                    data-sal-duration="800"
                  >
                    <div class="thumbnail" style={{ marginRight: "0" }}>
                      <img
                        style={{ borderRadius: "25px" }}
                        src="assets/images/others/video-03.webp"
                        alt="Thumb"
                      />
                      <a
                        style={{}}
                        href="http://www.youtube.com/watch?v=-wtIMTCHWuI"
                        class="video-play-btn video-popup-activation"
                      >
                        <i class="icon-18">
                          <iframe src="http://www.youtube.com/watch?v=-wtIMTCHWuI"></iframe>
                        </i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="missionandvision-content-contaioner">
        <div
          class="edu-course  course-style-14 courserdtyle"
          style={{ paddingTop: "220px" }}
        >
          <div class="inner">
            <div>
              <div className="inner-content">
                <div style={{}} className="campustour-list">
                  <ul
                    style={{
                      display: "flex",
                      justifyContent: "space-around",

                      flexWrap: "wrap",
                    }}
                  >
                    <li style={{ listStyle: "none", padding: "0 10px" }}>
                      <Link ref={ref} onClick={func} to="/AddmisionEnquiry">
                        <div style={{ color: "black" }}>
                          <a>
                            <ImFileText2
                              style={{ width: "100%", height: "50px" }}
                            />
                            <p
                              style={{
                                margin: " 19px 0 0 -10px",
                                color: "black",
                              }}
                            >
                              {" "}
                              <h6>Aplication From</h6>{" "}
                            </p>
                          </a>{" "}
                        </div>
                      </Link>
                    </li>
                    <li style={{ listStyle: "none", padding: "0 10px" }}>
                      {" "}
                      <Link onClick={func} to="/DownloadBrochure">
                        <div style={{ color: "black" }}>
                          <a>
                            <ImDownload
                              style={{ width: "100%", height: "50px" }}
                            />{" "}
                            <p
                              style={{
                                margin: "19px 0 0 -10px",
                                color: "black",
                              }}
                            >
                              <h6>Brochure Download</h6>
                            </p>
                          </a>
                        </div>
                      </Link>
                    </li>{" "}
                    <li style={{ listStyle: "none", padding: "0 10px" }}>
                      <Link onClick={func} to="/feestructure">
                        <div style={{ color: "black" }}>
                          <a>
                            <AiTwotoneBulb
                              style={{ width: "100%", height: "50px" }}
                            />{" "}
                            <p
                              style={{
                                margin: " 19px 0 0 -10px",
                                color: "black",
                              }}
                            >
                              {" "}
                              <h6>Fee Details</h6>
                            </p>
                          </a>
                        </div>{" "}
                      </Link>
                    </li>{" "}
                    <li style={{ listStyle: "none", padding: "0 10px" }}>
                      {" "}
                      <Link onClick={func} to="/contact">
                        <div style={{ color: "black" }}>
                          {" "}
                          <a>
                            <AiOutlineSmile
                              style={{ width: "100%", height: "50px" }}
                            />{" "}
                            <p
                              style={{
                                margin: "19px 0 0 -10px",
                                color: "black",
                              }}
                            >
                              <h6>Quick Contact</h6>
                            </p>
                          </a>{" "}
                        </div>{" "}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <br />
        </div>
      </div>
    </div>
  );
}

export default CampusTour;
