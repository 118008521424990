import React from "react";
import Header from "../../common/Header";
import Footer from "../../common/Footer";

function FeeStructure() {
  return (
    <div>
      <Header></Header>
      <div>
        <div className="home-one-cta-two cta-area-1 banner_1">
          <div className="container">
            <div className="row text-center">
              <div className="col-md-12">
                <div className="about_over" />
                <div className="about_school">
                  <h1>Admission</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="missionandvision-content-contaioner">
        <div class="edu-course course-style-4 course-style-14">
          <div class="inner">
            <div class="content">
              <div className="inner-content">
                {" "}
                <h2>Fees Structure</h2>
                <h4 style={{ textAlign: "center" }} class="title">
                  Fees Structure 2023-24
                </h4>
                <div
                  className="fee-structure-table-parent"
                  style={{ fontSize: "15px" }}
                >
                  {" "}
                  <table class="table">
                    <thead>
                      <tr className="fee-structure-table-tr">
                        <th scope="col">S.No</th>
                        <th scope="col">Class</th>
                        <th scope="col">Tution Fees</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">1</th>
                        <td>Nursery</td>
                        <td>3000</td>
                      </tr>
                      <tr>
                        <th scope="row">2</th>
                        <td>KG I & KG II</td>
                        <td>35000</td>
                      </tr>
                      <tr>
                        <th scope="row">3</th>
                        <td>I to IV</td>
                        <td>41000</td>
                      </tr>{" "}
                      <tr>
                        <th scope="row">4</th>
                        <td>V & VI</td>
                        <td>51000</td>
                      </tr>{" "}
                      <tr>
                        <th scope="row">5</th>
                        <td>VII & VIII</td>
                        <td>61000</td>
                      </tr>{" "}
                      <tr>
                        <th scope="row">6</th>
                        <td>IX & X</td>
                        <td>71000</td>
                      </tr>
                      <tr>
                        {" "}
                        <th scope="row">7</th>
                        <td>XI & XII</td>
                        <td>88000</td>
                      </tr>
                      <tr style={{ borderColor: "#cccccc" }}>
                        {" "}
                        <th scope="row">8</th>
                        <td>--</td>
                        <td>---</td>
                      </tr>
                    </tbody>
                  </table>
                  <h5>Admission Fees (Non-Refundable)</h5>
                  <table class="table">
                    <thead></thead>
                    <tbody>
                      <tr>
                        <td>Nursery to KG II</td>
                        <td>1000</td>
                      </tr>
                      <tr>
                        <td>I to XII</td>
                        <td>5000</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default FeeStructure;
