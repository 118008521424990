import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import "./Header.css";
import logoDark from "../../assets/images/logo/logo-dark.png";
import logoWhite from "../../assets/images/logo/logo-white.png";
import MenuIcon from "@material-ui/icons/Menu";

export default function Header() {
  const [show, setShow] = useState(false);
  const userefhook = useRef();
  const [dataArray, setDataArray] = useState([
    {
      name: "Jay Gurudev International School",
      number: "07162 292 949",
      email: "info@JGIS.com",
      address:
        "Isha Nagar, Parasia Road, Partala, Chhindwara, Madhya Pradesh-480001",
    },
  ]);

  return (
    <>
      {" "}
      <header class="edu-header header-style-1 header-fullwidth">
        <div class="header-top-bar">
          <div class="container-fluid">
            <div class="header-top">
              {dataArray.map((item) => (
                <>
                  <div class="header-top-left">
                    <div class="header-notify"> {item.name} </div>
                  </div>
                  <div class="header-top-right">
                    <ul class="header-info">
                      <li>
                        <a href="#">
                          <i class="icon-phone"></i>Call: {item.number}{" "}
                        </a>
                      </li>
                      <li>
                        <a href="#" target="_blank">
                          <i class="icon-envelope"></i>Email: {item.email}{" "}
                        </a>
                      </li>
                    </ul>
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
        <div id="edu-sticky-placeholder"></div>
        <div class="header-mainmenu">
          <div class="container-fluid">
            <div class="header-navbar">
              <div class="header-brand">
                <div class="logo">
                  <a href="#">
                    <img
                      class="logo-light"
                      src={logoDark}
                      alt="Corporate Logo"
                    />
                    <img
                      class="logo-dark"
                      src="assets/images/logo/logo-white.png"
                      alt="Corporate Logo"
                    />
                  </a>
                </div>
              </div>
              <div className="hamburger displaynone">
                <span onClick={() => setShow(!show)}>
                  <MenuIcon className="menuhamburger" />
                </span>
              </div>
              <div
                class={`header-mainnav header-right ${
                  show ? "dispblock" : "dispnone"
                }`}
              >
                <nav class="mainmenu-nav ">
                  <ul class="mainmenu">
                    <li>
                      <a>
                        <Link to="/">
                          {" "}
                          <b>HOME </b>
                        </Link>
                      </a>
                    </li>
                    <li class="has-droupdown">
                      <a href="#">
                        <b>ABOUT</b>
                      </a>
                      <ul class="submenu">
                        <li>
                          <Link to="/overview">
                            <b>Overview</b>
                          </Link>
                        </li>
                        <li>
                          <Link to="/missionvission">
                            <b>Mission & Vision</b>
                          </Link>
                        </li>

                        <li>
                          <Link to="/principlemesssage">
                            <b> Principal's Message</b>
                          </Link>
                        </li>
                        <li>
                          <Link to="/AccedmicStructure">
                            <b> Academic Structure</b>
                          </Link>
                        </li>
                        <li>
                          <Link to="/AwardsAndAchivment">
                            <b>Awards & Achievement</b>
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li class="has-droupdown">
                      <a href="#">
                        <b>ACADEMIC</b>
                      </a>
                      <ul class="submenu">
                        <li>
                          <Link to="/fondetionl">
                            <b>Foundational Stage</b>
                          </Link>
                        </li>
                        <li>
                          <Link to="/primaryStage">
                            <b>Primary Stage</b>
                          </Link>
                        </li>
                        <li>
                          <Link to="/middleStage">
                            <b>Middle Stage</b>
                          </Link>
                        </li>
                        <li>
                          <Link to="/highersecondry">
                            <b> Higher Secondary Stage</b>
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li class="has-droupdown">
                      <a href="#">
                        <b>FACILITIES</b>
                      </a>
                      <ul class="submenu">
                        <li>
                          <Link to="/amenties">
                            <b>Amenities</b>
                          </Link>
                        </li>
                        <li>
                          <Link to="/labbotry">
                            <b>Laboratories</b>
                          </Link>
                        </li>
                        <li>
                          <Link to="/libbrery">
                            <b>Library</b>
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li class="has-droupdown">
                      <a href="#">
                        <b>ADMISSION</b>
                      </a>
                      <ul class="submenu">
                        <li>
                          <Link to="/AddmisionProcess">
                            <b>Admission Process</b>
                          </Link>
                        </li>
                        <li>
                          <Link to="/feestructure">
                            <b>Fee Structure</b>
                          </Link>
                        </li>
                        <li>
                          <Link to="/feepolicy">
                            <b>Fees Policy</b>
                          </Link>
                        </li>
                        <li>
                          <Link to="/AddmisionEnquiry">
                            <b>Admission Enquiry</b>
                          </Link>
                        </li>
                        <li>
                          <Link to="/DownloadBrochure">
                            <b>Download Brochure</b>
                          </Link>
                        </li>
                        <li>
                          <Link to="/uniforms">
                            <b>Uniforms</b>
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li class="has-droupdown">
                      <a href="#">
                        <b>ACTIVITIES</b>
                      </a>
                      <ul class="submenu">
                        <li>
                          <Link to="/house">
                            <b>House</b>
                          </Link>
                        </li>
                        <li>
                          <Link to="/club">
                            <b>Club</b>
                          </Link>
                        </li>
                        <li>
                          <Link to="/musicDrama">
                            <b>Music, Art & Drama</b>
                          </Link>
                        </li>
                        <li>
                          <Link to="/sport">
                            <b>Sports</b>
                          </Link>
                        </li>
                      </ul>
                    </li>
                    {/*<li>
                      <a href="#">
                        <b>ALUMNI</b>
                      </a>
              </li>*/}
                    <li class="has-droupdown">
                      <a href="#">
                        <b>GALLERY</b>
                      </a>
                      <ul class="submenu">
                        <li>
                          <Link to="/photgallery">
                            <b>Photo Gallery</b>
                          </Link>
                        </li>
                        <li>
                          <Link to="/videogallery">
                            <b>Video Gallery</b>
                          </Link>
                        </li>
                        <li>
                          <Link to="/pressgallery">
                            <b>Press Release</b>
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li class="has-droupdown">
                      <a href="#">
                        <b>CONTACT</b>
                      </a>
                      <ul class="submenu">
                        <li>
                          <Link to="/contact">
                            <b>Address</b>
                          </Link>
                        </li>
                        <li>
                          <Link to="/careers">
                            <b>Careers</b>
                          </Link>
                        </li>
                        {/*<li>
                          <a href="#">TC</a>
              </li>*/}
                      </ul>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div class="popup-mobile-menu">
          <div class="inner">
            <div class="header-top">
              <div class="logo">
                <a href="#">
                  <img
                    class="logo-light"
                    src="assets/images/logo/logo-dark.png"
                    alt="Corporate Logo"
                  />
                  <img
                    class="logo-dark"
                    src="assets/images/logo/logo-white.png"
                    alt="Corporate Logo"
                  />
                </a>
              </div>
              <div class="close-menu">
                <button class="close-button">
                  <i class="icon-73"></i>
                </button>
              </div>
            </div>
            <ul class="mainmenu">
              <li class="has-droupdown">
                <a href="index.html">Home</a>
                <ul class="mega-menu mega-menu-one">
                  <li>
                    <ul class="submenu mega-sub-menu mega-sub-menu-01">
                      <li>
                        <a href="#">
                          JGIS Education <span class="badge-1">hot</span>
                        </a>
                      </li>
                      <li>
                        <a href="#">Distant Learning</a>
                      </li>
                      <li>
                        <a href="#">University</a>
                      </li>
                      <li>
                        <a href="#">
                          Online Academy <span class="badge-1">hot</span>
                        </a>
                      </li>
                      <li>
                        <a href="#">Modern Schooling</a>
                      </li>
                      <li>
                        <a href="#">Kitchen Coach</a>
                      </li>
                      <li>
                        <a href="#">Yoga Instructor</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <ul class="submenu mega-sub-menu mega-sub-menu-01">
                      <li>
                        <a href="#">Kindergarten</a>
                      </li>
                      <li>
                        <a href="#">
                          Health Coch <span class="badge">new</span>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          Language Academy <span class="badge">new</span>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          Remote Training <span class="badge">new</span>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          Photography <span class="badge">new</span>
                        </a>
                      </li>
                      <li>
                        <a href="#" target="_blank">
                          Dark Version
                        </a>
                      </li>
                      <li>
                        <a href="#">Landing Demo</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <ul class="submenu mega-sub-menu-01">
                      <li>
                        <a href="#">
                          <img
                            src="assets/images/others/mega-menu-image.webp"
                            alt="advertising Image"
                          />
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li class="has-droupdown">
                <a href="#">Pages</a>
                <ul class="mega-menu">
                  <li>
                    <h6 class="menu-title">Inner Pages</h6>
                    <ul class="submenu mega-sub-menu-01">
                      <li>
                        <a href="#">About Us 1</a>
                      </li>
                      <li>
                        <a href="#">About Us 2</a>
                      </li>
                      <li>
                        <a href="#">About Us 3</a>
                      </li>
                      <li>
                        <a href="#">Instructor 1</a>
                      </li>
                      <li>
                        <a href="#">Instructor 2</a>
                      </li>
                      <li>
                        <a href="#">Instructor 3</a>
                      </li>
                      <li>
                        <a href="#">Instructor Profile</a>
                      </li>
                      <li>
                        <a href="#">Faq's</a>
                      </li>
                      <li>
                        <a href="#">404 Error</a>
                      </li>
                      <li>
                        <a href="#">Coming Soon</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <h6 class="menu-title">Inner Pages</h6>
                    <ul class="submenu mega-sub-menu-01">
                      <li>
                        <a href="#">Gallery Grid</a>
                      </li>
                      <li>
                        <a href="#">Gallery Masonry</a>
                      </li>
                      <li>
                        <a href="#">Event Grid</a>
                      </li>
                      <li>
                        <a href="#">Event List</a>
                      </li>
                      <li>
                        <a href="#">Event Details</a>
                      </li>
                      <li>
                        <a href="#">Pricing Table</a>
                      </li>
                      <li>
                        <a href="#">Purchase Guide</a>
                      </li>
                      <li>
                        <a href="#">Privacy Policy</a>
                      </li>
                      <li>
                        <a href="#">Terms & Condition</a>
                      </li>
                      <li>
                        <a href="#">Sign In</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <h6 class="menu-title">Shop Pages</h6>
                    <ul class="submenu mega-sub-menu-01">
                      <li>
                        <a href="#">Shop</a>
                      </li>
                      <li>
                        <a href="#">Product Details</a>
                      </li>
                      <li>
                        <a href="#">Cart</a>
                      </li>
                      <li>
                        <a href="#">Wishlist</a>
                      </li>
                      <li>
                        <a href="#">Checkout</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li class="has-droupdown">
                <a href="#">Courses</a>
                <ul class="submenu">
                  <li>
                    <a href="#">Course Style 1</a>
                  </li>
                  <li>
                    <a href="#">Course Style 2</a>
                  </li>
                  <li>
                    <a href="#">Course Style 3</a>
                  </li>
                  <li>
                    <a href="#">Course Style 4</a>
                  </li>
                  <li>
                    <a href="#">Course Style 5</a>
                  </li>
                  <li>
                    <a href="#">Course Details 1</a>
                  </li>
                  <li>
                    <a href="#">Course Details 2</a>
                  </li>
                  <li>
                    <a href="#">Course Details 3</a>
                  </li>
                </ul>
              </li>
              <li class="has-droupdown">
                <a href="#">Blog</a>
                <ul class="submenu">
                  <li>
                    <a href="#">Blog Standard</a>
                  </li>
                  <li>
                    <a href="#">Blog Masonry</a>
                  </li>
                  <li>
                    <a href="#">Blog List</a>
                  </li>
                  <li>
                    <a href="#">Blog Details</a>
                  </li>
                </ul>
              </li>
              <li class="has-droupdown">
                <a href="#">Contact</a>
                <ul class="submenu">
                  <li>
                    <a href="#">Contact Us</a>
                  </li>
                  <li>
                    <a href="#">Contact Me</a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div class="edu-search-popup">
          <div class="content-wrap">
            <div class="site-logo">
              <img
                class="logo-light"
                src="assets/images/logo/logo-dark.png"
                alt="Corporate Logo"
              />
              <img
                class="logo-dark"
                src="assets/images/logo/logo-white.png"
                alt="Corporate Logo"
              />
            </div>
            <div class="close-button">
              <button class="close-trigger">
                <i class="icon-73"></i>
              </button>
            </div>
            <div class="inner">
              <form class="search-form" action="#">
                <input
                  type="text"
                  class="JGIS-search-popup-field"
                  placeholder="Search Here..."
                />
                <button class="submit-button">
                  <i class="icon-2"></i>
                </button>
              </form>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
