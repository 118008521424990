import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import ApiEndPoints from "../../NetworkCall/ApiEndPoint";
import ApiServices from "../../NetworkCall/ApiServices";
import Admin from "./Admin";
import AdminNavbar from "./AdminNavbar";
import AdminSidebar from "./AdminSidebar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./AdminCommon.css";
function AdminPhotoGallery() {
  const inputFileReels = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [savingImage, setSavingImage] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [category, setCategory] = useState([]);
  const [categories, setCategories] = useState({});
  const [showAllImages, setShowAllImages] = useState(false);
  const [addingMoreImages, setAddingMoreImages] = useState(false);
  const [currentCategory, setCurrentCategory] = useState("");
  const [showChooseImageMsg, setShowChooseImageMsg] = useState(false);

  const navigate = useNavigate();

  const fetchImages = () => {
    const payload = {
      category: selectedCategory, // Pass selected category in the payload
    };

    ApiServices.post(ApiEndPoints.getPhoto, payload)
      .then((response) => {
        if (response.status.code === 200) {
          console.log(response);
        }
      })
      .catch((error) => {
        console.log("Error fetching banner images:", error);
      });
  };

  const fetchCategories = () => {
    // Fetch the existing categories from the server
    ApiServices.get(ApiEndPoints.getCategories)
      .then((response) => {
        if (response.status.code === 200) {
          const categoriesData = response.data.categories;
          setCategory(categoriesData);
        }
      })
      .catch((error) => {
        console.log("Error fetching categories:", error);
      });
  };

  const fetchImagess = () => {
    const payload = {};

    ApiServices.post(ApiEndPoints.getPhoto, payload)
      .then((response) => {
        if (response.status.code === 200) {
          const photoData = response.data.photoData;
          const updatedCategories = {};

          // Group images by category
          photoData.forEach((item) => {
            const category = item.category;
            if (!updatedCategories[category]) {
              updatedCategories[category] = [];
            }
            updatedCategories[category].push(item);
          });

          setCategories(updatedCategories);

          // Extract the unique categories from the photoData
          const uniqueCategories = [
            ...new Set(photoData.map((item) => item.category)),
          ];
          setCategory(uniqueCategories);
        }
      })
      .catch((error) => {
        console.log("Error fetching banner images:", error);
      });
  };

  useEffect(() => {
    fetchImages();
    fetchImagess();
    fetchCategories();
  }, []);

  const handleBannerImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file);
    }
  };

  const handleShowMore = (category) => {
    // Navigate to a new page with the selected category as a parameter
    navigate(`/Admincategory/${encodeURIComponent(category)}`);
    setAddingMoreImages(true);
    setCurrentCategory(category);
  };

  const handleAddImage = () => {
    inputFileReels.current.click();
  };

  const handleCreatePhoto = () => {
    if (selectedImage) {
      setSavingImage(true);
      const formData = new FormData();
      formData.append("photoimg", selectedImage, selectedImage.name);
      formData.append("category", selectedCategory || "");

      if (selectedCategory && !category.includes(selectedCategory)) {
        setCategory([...category, selectedCategory]);
      }

      ApiServices.post(ApiEndPoints.CreatePhoto, formData)
        .then((response) => {
          if (response?.status?.code === 200) {
            console.log(response);
            fetchCategories();
            fetchImages();
            fetchImagess();
            setShowChooseImageMsg(true);
            toast.success("Photo upload successfully", {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.success("Please  try again", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((error) => {
          console.log("Error creating banner:", error);
        })
        .finally(() => {
          setSavingImage(false);
          setSelectedImage(null);
          setSelectedCategory("");
        });
    }
  };

  const handleSaveImages = () => {
    if (selectedCategory && selectedImage) {
      const formData = new FormData();
      formData.append("category", currentCategory || "");
      Array.from(selectedImage).forEach((image) => {
        formData.append("photoimg[]", image, image.name);
      });

      if (currentCategory && !category.includes(currentCategory)) {
        setCategory([...category, currentCategory]);
      }

      ApiServices.post(ApiEndPoints.CreatePhoto, formData)
        .then((response) => {
          if (response?.status?.code === 200) {
            console.log(response);
          } else {
            console.log("Error creating banner:", response);
          }
        })
        .catch((error) => {
          console.log("Error creating banner:", error);
        })
        .finally(() => {
          setAddingMoreImages(false);
          setSelectedImage(null);
          setSelectedCategory("");
          setCurrentCategory("");
        });
    }
  };

  const handleAddMoreImages = () => {
    setAddingMoreImages(true);
  };

  const isCategorySelected = selectedCategory !== "";

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
    setShowChooseImageMsg(false);
  };

  return (
    <div>
      <AdminNavbar />
      <div className="d-flex" style={{ position: "relative" }}>
        <div style={{ marginTop: "60px", position: "fixed", zIndex: "1" }}>
          <AdminSidebar />
        </div>

        <div
          className="photogalleryposition"
          style={{
            fontSize: "18px",
            marginTop: "60px",
            position: "absolute",
          }}
        >
          <div>
            <div className="my-5 mx-5">
              <h2>Add Photos</h2>
              <div className="mx-4 my-5">
                <input
                  style={{ border: "1px solid #cccccc", marginBottom: "10px" }}
                  type="text"
                  value={selectedCategory}
                  onChange={handleCategoryChange}
                  placeholder="Enter category"
                />
                <select
                  value={selectedCategory}
                  onChange={handleCategoryChange}
                >
                  <option value="">Select category</option>
                  {category.map((cat) => (
                    <option key={cat} value={cat}>
                      {cat}
                      {console.log(cat)}
                    </option>
                  ))}
                </select>

                <div className="d-flex">
                  <div className="my-3">
                    <button
                      onClick={handleAddImage}
                      disabled={!isCategorySelected}
                      class="btn btn-success btn-lg"
                    >
                      Choose File
                    </button>
                  </div>

                  <input
                    placeholder="Click here"
                    type="file"
                    accept="images/*"
                    multiple={addingMoreImages}
                    onChange={handleBannerImage}
                    ref={inputFileReels}
                    style={{ display: "none" }}
                  />

                  <div className="mx-2 my-3">
                    {addingMoreImages &&
                      currentCategory === selectedCategory && (
                        <>
                          <button
                            className="btn btn-primary btn-lg mx-5"
                            onClick={handleSaveImages}
                            disabled={
                              !selectedImage ||
                              savingImage ||
                              !isCategorySelected
                            }
                          >
                            {savingImage ? "Saving Images..." : "Save Images"}
                          </button>
                        </>
                      )}
                    {!addingMoreImages && (
                      <button
                        className="btn btn-primary  btn-lg mx-5"
                        onClick={handleCreatePhoto}
                        disabled={
                          !selectedImage || savingImage || !isCategorySelected
                        }
                      >
                        {savingImage ? "Saving Image..." : "Save Image"}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="inner-content">
            <div className="my-5 mx-5">
              <h2> Photo Gallery</h2>
            </div>
            <div className="inside-photogallery">
              <div>
                <div className="inside-photogallery-child">
                  {Object.keys(categories).map((category) => (
                    <div key={category}>
                      <a
                        className="inside-photogallery-child-anchor"
                        onClick={() => handleShowMore(category)}
                      >
                        <li>
                          {showAllImages ? (
                            categories[category].map((item) => (
                              <div>
                                <img
                                  key={item.id}
                                  src={categories[category][0].photoImgUrl}
                                  alt={item.name}
                                />
                              </div>
                            ))
                          ) : (
                            <img
                              key={categories[category][0].id}
                              src={categories[category][0].photoImgUrl}
                              alt={categories[category][0].name}
                            />
                          )}
                        </li>
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => handleShowMore(category)}
                        >
                          {category}
                        </span>
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminPhotoGallery;
