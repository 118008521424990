import React, { useState, useEffect } from "react";
import ApiEndPoints from "../../NetworkCall/ApiEndPoint";
import Carousel from "react-multi-carousel";
import ApiServices from "../../NetworkCall/ApiServices";
import "react-multi-carousel/lib/styles.css";
import AdminNavbar from "./AdminNavbar";
import AdminSidebar from "./AdminSidebar";
import "./NewsAndEventAdminpanel.css";
function NewsAndEventAdminpanel() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const [title, setTitle] = useState("");
  const [eventDate, setEventDate] = useState("");
  const [description, setDescription] = useState("");
  const [event, setEvent] = useState([]);

  const Get_news = () => {
    ApiServices.get(ApiEndPoints.getNews)
      .then((response) => {
        if (response.status.code === 200) {
          console.log(response);
          setEvent(response.data.newsData);
        }
      })
      .catch((error) => {
        console.log("Error fetching banner images:", error);
      });
  };

  useEffect(() => {
    Get_news();
  }, []);

  const handleCreateNewsEvent = () => {
    const payload = {
      title: title,
      date: eventDate,
      description: description,
    };

    ApiServices.post(ApiEndPoints.CreateNews, payload)
      .then((response) => {
        if (response?.status?.code === 200) {
          console.log(response);
          setTitle("");
          setEventDate("");
          setDescription("");
          Get_news();
        } else {
          console.log("Error creating news event:", response);
        }
      })
      .catch((error) => {
        console.log("Error creating news event:", error);
      });
  };

  const handleDeleteNews = (bannerId) => {
    ApiServices.delete(ApiEndPoints.DeleteNews + bannerId)
      .then((response) => {
        if (response.status.code === 200) {
          console.log("Banner deleted successfully");
          Get_news(); // Refresh the images after successful deletion
        } else {
          console.log("Error deleting banner:", response);
        }
      })
      .catch((error) => {
        console.log("Error deleting banner:", error);
      });
  };

  return (
    <>
      <AdminNavbar />
      <div className="d-flex" style={{ position: "relative" }}>
        <div style={{ marginTop: "60px", position: "fixed", zIndex: "1" }}>
          <AdminSidebar />
        </div>
        <div
          className="newseventposition"
          style={{
            marginTop: "60px",
            alignItems: "center",
            position: "absolute",
          }}
        >
          <h2 className="mx-4">News & Event</h2>
          <div
            className="adminnewsandeventposition"
            style={{ position: "relative" }}
          >
            <div>
              <input
                className="adminnewsandeventinput"
                style={{ border: "1px solid #cccccc" }}
                value={title}
                type="text"
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Title"
              />
              <input
                className="adminnewsandeventinput"
                style={{
                  border: "1px solid #cccccc",
                  padding: "25px",
                  margin: "15px 0",
                  borderRadius: "4px",
                }}
                type="date"
                value={eventDate}
                onChange={(e) => setEventDate(e.target.value)}
                placeholder="Date"
              />
              <input
                className="adminnewsandeventinput"
                style={{ border: "1px solid #cccccc" }}
                type="text"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Description"
              />
              <button
                onClick={handleCreateNewsEvent}
                className="btn btn-primary  btn-lg my-2 adminnewsandeventbutton"
              >
                Save
              </button>
            </div>
          </div>

          <div style={{ position: "relative" }}>
            <div
              className="edu-blog-area blog-area-1 edu-section-gap   adminnewsandeventposition"
              style={{ position: "absolute" }}
            >
              <div className="container">
                <div
                  className="section-title section-center"
                  data-sal-delay={100}
                  data-sal="slide-up"
                  data-sal-duration={800}
                >
                  <span className="pre-title">Latest News</span>
                  <h2 className="title">Get News with JGIS</h2>
                  <span className="shape-line">
                    <i className="icon-19" />
                  </span>
                </div>
                <div className="adminnewsandeventcarousel">
                  <Carousel responsive={responsive}>
                    {event.map((item) => (
                      <div
                        style={{
                          padding: "20px 35px",
                          backgroundColor: "#fff",
                          margin: "20px",
                          borderRadius: "20px",
                          border: "1px solid green",
                        }}
                      >
                        <div className="">
                          <a href="#" className="blog-category">
                            {item.title}
                          </a>
                        </div>
                        <h5 className="title">
                          <a href="#"></a>
                        </h5>
                        <ul className="blog-meta">
                          <li>
                            <i className="icon-27" />
                            {item.date}
                          </li>
                        </ul>
                        <p>{item.description}</p>
                        <button onClick={() => handleDeleteNews(item._id)}>
                          Delete
                        </button>
                      </div>
                    ))}
                  </Carousel>
                </div>
              </div>

              <ul className="shape-group">
                <li className="shape-1 scene">
                  <img
                    data-depth="-1.4"
                    src="assets/images/about/shape-02.png"
                    alt="Shape"
                  />
                </li>
                <li className="shape-2 scene">
                  <span data-depth="2.5" />
                </li>
                <li className="shape-3 scene">
                  <img
                    data-depth="-2.3"
                    src="assets/images/counterup/shape-05.png"
                    alt="Shape"
                  />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NewsAndEventAdminpanel;
