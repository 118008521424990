import React, { useState, useRef, useEffect } from "react";
import ApiServices from "../../NetworkCall/ApiServices";
import ApiEndPoints from "../../NetworkCall/ApiEndPoint";
import AdminNavbar from "./AdminNavbar";
import AdminSidebar from "./AdminSidebar";

function AdminVideoGallery() {
  const inputFileReels = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [savingImage, setSavingImage] = useState(false);
  const [video, setVideo] = useState([]);
  const [uploadedVideoUrl, setUploadedVideoUrl] = useState(null);

  const fetchVideos = () => {
    ApiServices.get(ApiEndPoints.getVideos)
      .then((response) => {
        if (response.status.code === 200) {
          setVideo(response.data.videoData);
          console.log(response);
        }
      })
      .catch((error) => {
        console.log("Error fetching banner images:", error);
      });
  };

  useEffect(() => {
    fetchVideos();
  }, []);

  const handleVideo = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file);
      setUploadedVideoUrl(URL.createObjectURL(file));
    }
  };

  const handleAddVideo = () => {
    inputFileReels.current.click();
    inputFileReels.current.value = "";
  };

  const handleCreateVideo = () => {
    if (selectedImage) {
      setSavingImage(true); // Show loading state
      const formData = new FormData();
      formData.append("videos", selectedImage, selectedImage.name);

      ApiServices.post(ApiEndPoints.CreateVideo, formData)
        .then((response) => {
          if (response?.status?.code === 200) {
            console.log(response);
            fetchVideos(); // Refresh the videos after successful creation
          } else {
            console.log("Error creating video:", response);
          }
        })
        .catch((error) => {
          console.log("Error creating video:", error);
        })
        .finally(() => {
          setSavingImage(false); // Hide loading state
          setSelectedImage(null); // Reset selectedImage after successful save
          setUploadedVideoUrl(null);
          inputFileReels.current.value = ""; // Reset the file input value to empty string
        });
    }
  };

  const handleDeleteVideo = (bannerId) => {
    const updatedVideos = video.map((item) =>
      item._id === bannerId ? { ...item, playing: !item.playing } : item
    );
    setVideo(updatedVideos);

    ApiServices.delete(ApiEndPoints.DeleteVideo + bannerId)
      .then((response) => {
        if (response.status.code === 200) {
          console.log("Video deleted successfully");
          fetchVideos(); // Refresh the videos after successful deletion
        } else {
          console.log("Error deleting video:", response);
        }
      })
      .catch((error) => {
        console.log("Error deleting video:", error);
      });
  };

  return (
    <div>
      <AdminNavbar />
      <div className="d-flex" style={{ position: "relative" }}>
        <div style={{ marginTop: "60px", position: "fixed", zIndex: "1" }}>
          <AdminSidebar />
        </div>
        <div
          className="videogalleryposition"
          style={{
            marginTop: "60px",
            width: "100%",
            position: "absolute",
          }}
        >
          <div className="missionandvision-content-contaioner">
            <div class="edu-course course-style-4 course-style-14">
              <div class="inner">
                <div class="content ">
                  <div className="inner-content mx-5">
                    <div className="mx-5 my-5">
                      <h2>Add Videos</h2>
                      <div className="d-flex">
                        <div>
                          <input
                            placeholder="Click here"
                            type="file"
                            accept="videos/*"
                            onChange={handleVideo}
                            ref={inputFileReels}
                          />
                        </div>
                        <button
                          onClick={handleAddVideo}
                          style={{ display: "none" }}
                        >
                          Select Image
                          <div></div>
                        </button>
                        <div>
                          <button
                            className="btn btn-primary btn-lg"
                            onClick={handleCreateVideo}
                            disabled={!selectedImage || savingImage}
                          >
                            {savingImage ? "Saving Video..." : "Save Video"}
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="mx-5 my-5">
                      <h2>Video Gallery</h2>
                    </div>
                    <div>
                      <div className="row">
                        {video.map((item) => (
                          <div
                            key={item.id}
                            className="col-md-4 innerrow my-2"
                            style={{
                              width: "380px",
                              gap: "30px",
                              flexWrap: "wrap",
                              justifyContent: "space-between",
                            }}
                          >
                            <video
                              style={{ borderRadius: "10px" }}
                              src={item.videoUrl}
                              height="200"
                              width="100%"
                              title="Video Example"
                              controls={!item.playing}
                              autoPlay={item.playing}
                              onClick={() => handleDeleteVideo(item._id)}
                            ></video>
                            <div
                              style={{ marginTop: "15px", textAlign: "right" }}
                            >
                              <button
                                style={{
                                  border: "none",
                                  padding: "7px 20px",
                                  borderRadius: "5px",
                                  backgroundColor: "#000000",
                                  color: "#ffffff",
                                }}
                                onClick={() => handleDeleteVideo(item._id)}
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <br />
                    <br />
                  </div>
                </div>
              </div>
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminVideoGallery;
