import React from "react";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import "../Facilites/Facilities.css";
import club1 from "../../../assets/images/activities/club1.jpg";
import club2 from "../../../assets/images/activities/club2.jpg";
import club3 from "../../../assets/images/activities/club3.jpg";
import club4 from "../../../assets/images/activities/club4.jpg";
import club5 from "../../../assets/images/activities/club5.jpg";
import club6 from "../../../assets/images/activities/club6.jpg";

function Club() {
  return (
    <div>
      <Header></Header>
      <div class="edu-breadcrumb-area banner_1">
        <div class="container">
          <div class="breadcrumb-inner">
            <div class="page-title">
              <h1 class="title" style={{ position: "relative", zIndex: "1" }}>
                Activities
              </h1>
            </div>
          </div>
        </div>
        <div className="about_over"></div>
        {/** <ul class="shape-group">
          <li class="shape-1">
            <span></span>
          </li>
          <li class="shape-2 scene">
            <img
              data-depth="2"
              src="assets/images/about/shape-13.png"
              alt="shape"
            />
          </li>
          <li class="shape-3 scene">
            <img
              data-depth="-2"
              src="assets/images/about/shape-15.png"
              alt="shape"
            />
          </li>
          <li class="shape-4">
            <span></span>
          </li>
          <li class="shape-5 scene">
            <img
              data-depth="2"
              src="assets/images/about/shape-07.png"
              alt="shape"
            />
          </li>
        </ul> */}
      </div>

      <section class="section-gap-equal">
        <div class="container">
          <div class="row row--30">
            <div class="col-lg-12">
              <div class="amenities_head">
                <h3 class="title mb-0">Club</h3>
                <p>
                  In our school we have different clubs to provide opportunities
                  to students to interact with social institutions & learn
                  social values and life skills. These clubs are…...
                </p>
              </div>
            </div>

            <div class="course-overview">
              <div class="border-box">
                <h5
                  class="title"
                  data-sal-delay="150"
                  data-sal="slide-up"
                  data-sal-duration="800"
                >
                  What You’ll Learn?
                </h5>
                <div class="row g-5">
                  <div
                    class="col-lg-6"
                    data-sal-delay="150"
                    data-sal="slide-up"
                    data-sal-duration="800"
                  >
                    <ul>
                      <li>Eco Club</li>
                      <li>Cultural Diversity Club</li>
                    </ul>
                  </div>
                  <div
                    class="col-lg-6"
                    data-sal-delay="150"
                    data-sal="slide-up"
                    data-sal-duration="800"
                  >
                    <ul>
                      <li>Energy Conservation Club</li>
                      <li>Health and welness Club</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div class="sport_gallery mb-0">
              <div class="isotope-list gallery-grid-wrap">
                <div id="animated-thumbnials" className="flexboxprop">
                  <a
                    href="assets/images/gallery/gallery-01.webp"
                    class="edu-popup-image edu-gallery-grid p-gallery-grid-wrap isotope-item education"
                  >
                    <div>
                      <img
                        className="mainimagestag"
                        src={club1}
                        alt="Gallery Image"
                      />
                    </div>
                  </a>

                  <a
                    href="assets/images/gallery/gallery-02.webp"
                    class="edu-popup-image edu-gallery-grid p-gallery-grid-wrap isotope-item marketing"
                  >
                    <div>
                      <img
                        className="mainimagestag"
                        src={club2}
                        alt="Gallery Image"
                      />
                    </div>
                  </a>

                  <a
                    href="assets/images/gallery/gallery-03.webp"
                    class="edu-popup-image edu-gallery-grid p-gallery-grid-wrap isotope-item education health"
                  >
                    <div>
                      <img
                        className="mainimagestag"
                        src={club3}
                        alt="Gallery Image"
                      />
                    </div>
                  </a>

                  <a
                    href="assets/images/gallery/gallery-04.webp"
                    class="edu-popup-image edu-gallery-grid p-gallery-grid-wrap isotope-item marketing"
                  >
                    <div>
                      <img
                        className="mainimagestag"
                        src={club4}
                        alt="Gallery Image"
                      />
                    </div>
                  </a>

                  <a
                    href="assets/images/gallery/gallery-05.webp"
                    class="edu-popup-image edu-gallery-grid p-gallery-grid-wrap isotope-item education"
                  >
                    <div>
                      <img
                        className="mainimagestag"
                        src={club5}
                        alt="Gallery Image"
                      />
                    </div>
                  </a>

                  <a
                    href="assets/images/gallery/gallery-06.webp"
                    class="edu-popup-image edu-gallery-grid p-gallery-grid-wrap isotope-item health"
                  >
                    <div>
                      <img
                        className="mainimagestag"
                        src={club6}
                        alt="Gallery Image"
                      />
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </div>
  );
}

export default Club;
