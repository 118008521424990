import React, { useState } from "react";
import advertiseimg from "../../../assets/images/gallery/advertisementpopuimage.jpg";
import closebutton from "../../../assets/images/gallery/closebuttonimg.svg";
import "./Advertisement.css";

function AdvertisementPopup() {
  const [advertisement, setAdvertisement] = useState(true);
  return (
    <div className="advertise">
      {advertisement ? (
        <div className="advertise_container">
          <button onClick={() => setAdvertisement(!advertisement)}>
            <img src={closebutton} />
          </button>
          <img className="advertiseimg" src={advertiseimg} />
        </div>
      ) : null}
    </div>
  );
}

export default AdvertisementPopup;
