import React from "react";
import "..//App.css";
import "..//Style.css";
import About from "./pages/About.js";
import AdmissionEnquiry from "./pages/AdmissionEnquiry.js";
import Contact from "./pages/Contact/Contact.js";
import Faq from "./pages/Faq.js";
import PrivacyPolicy from "./pages/Privacy-policy.js";
import TermsAndConditions from "./pages/T&C.js";
import FoundetionlStage from "./pages/Acedemic/FoundetionlStage.js";
import PrimaryStage from "./pages/Acedemic/PrimaryStage.js";
import MiddleStage from "./pages/Acedemic/MiddleStage.js";
import HigherSceondry from "./pages/Acedemic/HigherSceondry.js";
import Overview from "./pages/AboutUs/OverView.js";
import PhotoGallery from "./pages/Gallery/PhotoGalary.js";
import PrincipleMessage from "./pages/AboutUs/PrincipleMessage.js";
import Missionvission from "./pages/AboutUs/Missionvission.js";
import AwardsAndAchivment from "./pages/AboutUs/AwardsAndAchivment.js";
import AccedmicStructure from "./pages/AboutUs/AccedmicStructure.js";
import AddmisionProcess from "./pages/Addmision/AddmisionProcess.js";
import AddmisionEnqurey from "./pages/Addmision/AddmisionEnqurey.js";
import DownloadBrochure from "./pages/Addmision/DownloadBrochure.js";
import FeeStructure from "./pages/Addmision/FeeStructure.js";
import FeePolicy from "./pages/Addmision/FeePolicy.js";
import Uniform from "./pages/Addmision/Uniform.js";
import NewsEvent from "./pages/NewsEvent.js";
import Amenities from "./pages/Facilites/Amenities.js";
import Labrottry from "./pages/Facilites/Labrottry.js";
import Libbrery from "./pages/Facilites/Libbrery.js";
import Hostel from "./pages/Facilites/Hostel.js";
import Sport from "./pages/Facilites/Sport.js";
import House from "./pages/Activites/House.js";
import Club from "./pages/Activites/Club.js";
import CategoryPage from "./pages/Gallery/CategoryPage.js";
import VideoGalary from "./pages/Gallery/VideoGalary.js";
import PressRelease from "./pages/Gallery/PressRelease.js";
import Bus from "./pages/Facilites/Bus.js";
import MusicDrama from "./pages/Activites/MusicDrama.js";
import { Route, Routes } from "react-router-dom";
import Header from "./common/Header.js";
import Footer from "./common/Footer.js";
import { ToastContainer } from "react-toastify";
//import Preloader from "./common/Preloader.js";
import BackToTop from "./common/BackToTop.js";
import HompPage from "./pages/HompPage.js";
import Careers from "./pages/Contact/Careers";

function Routing() {
  return (
    <div>
      <ToastContainer /> {/* Add the ToastContainer component here */}
      <Routes>
        <Route path="/" element={<HompPage />} />
        <Route path="/about" element={<About />} />

        <Route path="/contact" element={<Contact />} />
        <Route path="/careers" element={<Careers />} />

        <Route path="/faq" element={<Faq />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/termsCondition" element={<TermsAndConditions />} />
        <Route path="/fondetionl" element={<FoundetionlStage />} />
        <Route path="/primaryStage" element={<PrimaryStage />} />
        <Route path="/middleStage" element={<MiddleStage />} />
        <Route path="/highersecondry" element={<HigherSceondry />} />

        <Route path="/overview" element={<Overview />} />
        <Route path="/photgallery" element={<PhotoGallery />} />
        <Route path="/principlemesssage" element={<PrincipleMessage />} />
        <Route path="/missionvission" element={<Missionvission />} />
        <Route path="/AwardsAndAchivment" element={<AwardsAndAchivment />} />
        <Route path="/AccedmicStructure" element={<AccedmicStructure />} />
        <Route path="/AddmisionProcess" element={<AddmisionProcess />} />
        <Route path="/AddmisionEnquiry" element={<AddmisionEnqurey />} />
        <Route path="/DownloadBrochure" element={<DownloadBrochure />} />
        <Route path="/feestructure" element={<FeeStructure />} />
        <Route path="/feepolicy" element={<FeePolicy />} />
        <Route path="/uniforms" element={<Uniform />} />
        <Route path="/newsEvents" element={<NewsEvent />} />
        <Route path="/amenties" element={<Amenities />} />
        <Route path="/labbotry" element={<Labrottry />} />
        <Route path="/libbrery" element={<Libbrery />} />
        <Route path="/hostel" element={<Hostel />} />
        <Route path="/sport" element={<Sport />} />
        <Route path="/house" element={<House />} />
        <Route path="/club" element={<Club />} />
        <Route path="/category/:categoryName" element={<CategoryPage />} />
        <Route path="/videogallery" element={<VideoGalary />} />
        <Route path="/pressgallery" element={<PressRelease />} />
        <Route path="/bus" element={<Bus />} />
        <Route path="/musicDrama" element={<MusicDrama />} />
      </Routes>
    </div>
  );
}

export default Routing;
