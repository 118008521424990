import React from "react";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import school from "./Brouch/school.pdf";
import Aboutschooimg from "..//..//..//assets//images//course//Aboutschooimg.jpg";

function DownloadBrochure() {
  return (
    <div>
      <Header></Header>
      <div>
        <div className="home-one-cta-two cta-area-1 banner_1">
          <div className="container">
            <div className="row text-center">
              <div className="col-md-12">
                <div className="about_over" />
                <div className="about_school">
                  <h1>Admission</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="missionandvision-content-contaioner">
        <div class="edu-course course-style-4 course-style-14">
          <div class="inner">
            <div class="content">
              <div className="inner-content">
                {" "}
                <h2>Download Brochure</h2>
                <p>
                  <div>
                    <a
                      href="course-details.html"
                      className="academic-structure-image-anchor "
                    >
                      <img
                        className="download-brochure-image"
                        src={Aboutschooimg}
                        alt="Course Meta"
                      />
                      <br /> <br />
                      <div>
                        <a href={school} target="_blank">
                          <button
                            style={{
                              padding: "15px",
                              border: "none",
                              borderRadius: "6px",
                              color: "#ffffff",
                              backgroundColor: "#525fe1",
                            }}
                          >
                            Download Brochure
                          </button>
                        </a>
                      </div>
                    </a>
                  </div>
                </p>
              </div>
            </div>
          </div>
          <br /> <br />
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default DownloadBrochure;
