import React, { useState, useEffect } from "react";
import ApiEndPoints from "../../../NetworkCall/ApiEndPoint";
import ApiServices from "../../../NetworkCall/ApiServices";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import physiclab from "../../../assets/images/facilities/physicslab.jpg";
import chemistrylab from "../../../assets/images/facilities/chemistrylab.jpg";
import biologylab from "../../../assets/images/facilities/biologylab.jpg";
import computerlab from "../../../assets/images/facilities/computerlab.jpg";

function Labrottry() {
  const LabInfo = () => {
    ApiServices.get(ApiEndPoints.lab + "?category=lab")
      .then((response) => {
        if (response.status && response.status.code === 200) {
          console.log(response);
        } else {
          console.log(response);
        }
      })
      .finally(() => {
        // Any cleanup code...
      });
  };

  useEffect(() => {
    LabInfo();
  }, []);
  return (
    <div>
      <Header></Header>
      <div class="edu-breadcrumb-area banner_1">
        <div class="container">
          <div class="breadcrumb-inner">
            <div class="page-title">
              <h1 class="title" style={{ position: "relative", zIndex: "1" }}>
                Facilities
              </h1>
            </div>
          </div>
        </div>
        <div className="about_over"></div>
      </div>

      <section class="section-gap-equal">
        <div class="container">
          <div class="row row--30">
            <div class="col-lg-12">
              <div class="amenities_head">
                <h3 class="title mb-0">Laboratories</h3>
              </div>
            </div>
            <div class="col-lg-12">
              <div
                style={{ boxShadow: "none" }}
                class="edu-blog blog-style-list amenities_main"
                data-sal-delay="150"
                data-sal="slide-up"
                data-sal-duration="800"
              >
                <div class="inner">
                  <div class="thumbnail">
                    <img
                      style={{
                        width: "100%",
                        height: "270px",
                        borderRadius: "10px",
                      }}
                      src={physiclab}
                      alt="Blog Images"
                    />
                  </div>
                  <div class="content" style={{ textAlign: "justify" }}>
                    <h4 class="title">Physics Laboratory</h4>
                    <p>
                      “Learning by Doing” is the most effective concept of
                      teaching learning process Laboratory plays an important
                      role while learning is done practically. We have modern
                      Physics Laboratory well equipped with all the apparatus
                      and equipments as per the CBSE syllabus requirements.
                      Students undertake experiments themselves under the
                      supervision of experienced and trained faculty members.
                      Individual attention and guidance to students is given by
                      the teacher to explore their inherent abilities. So, the
                      students learn by doing themselves and lay their own
                      strong foundation of confidence and knowledge..
                    </p>
                  </div>
                </div>
              </div>

              <div
                style={{ boxShadow: "none" }}
                class="edu-blog blog-style-list amenities_main"
                data-sal-delay="150"
                data-sal="slide-up"
                data-sal-duration="800"
              >
                <div class="inner">
                  <div
                    class="content"
                    style={{ textAlign: "justify", paddingRight: "40px" }}
                  >
                    <h4 class="title">Chemistry Laboratory</h4>
                    <p>
                      We have a well equipped and spacious Chemistry Laboratory
                      with the latest facilities to students for qualitative as
                      well as quantitative analysis according to C.B.S.E.
                      syllabus. Well qualified and experienced faculties provide
                      proper guidance to students learn and absorb much more
                      through well performed experiments. Accurate result is the
                      prime concern of every experiment. So, every student gets
                      opportunity to do practical separately with the help of
                      high quality chemicals, acids, bases, salts, compounds and
                      apparatus.
                    </p>
                  </div>
                  <div class="thumbnail" style={{ marginRight: "0" }}>
                    <img
                      style={{
                        width: "100%",
                        height: "270px",
                        borderRadius: "10px",
                      }}
                      src={chemistrylab}
                      alt="Blog Images"
                    />
                  </div>
                </div>
              </div>

              <div
                style={{ boxShadow: "none" }}
                class="edu-blog blog-style-list amenities_main"
                data-sal-delay="150"
                data-sal="slide-up"
                data-sal-duration="800"
              >
                <div class="inner">
                  <div class="thumbnail">
                    <img
                      style={{
                        width: "100%",
                        height: "270px",
                        borderRadius: "10px",
                      }}
                      src={biologylab}
                      alt="Blog Images"
                    />
                  </div>
                  <div class="content" style={{ textAlign: "justify" }}>
                    <h4 class="title">Biology Laboratory</h4>
                    <p>
                      Our Biology Laboratory is equipped with modern equipments
                      and apparatus, specimens, slides and other material.
                      Students learn different concepts of living world through
                      models, charts, slides and specimens available in the
                      Biology Laboratory. Our teachers guide students at every
                      stage of their practical experiment conducted by them.
                    </p>
                  </div>
                </div>
              </div>

              <div
                style={{ boxShadow: "none" }}
                class="edu-blog blog-style-list amenities_main"
                data-sal-delay="150"
                data-sal="slide-up"
                data-sal-duration="800"
              >
                <div class="inner">
                  <div
                    class="content"
                    style={{ textAlign: "justify", paddingRight: "40px" }}
                  >
                    <h4 class="title">Computer Lab</h4>
                    <p>
                      It is a time of technology and awareness about latest
                      hi-tech environm ent. Computer education is provided to
                      all the students from class I onwards . We have a
                      well-equipped computer laboratory with the latest hardware
                      and software. Computers are properly installed with
                      concepts, programming languages like LOGO, BASIC, C++, and
                      Java, utility packages in Word Processing, Spread Sheet
                      and Data Base in Windows environment. Also a team of
                      expert educators of computer subject always give the
                      latest information to our students every time they
                      require, it keeps students update.
                    </p>
                  </div>
                  <div class="thumbnail" style={{ marginRight: "0" }}>
                    <img
                      style={{
                        width: "100%",
                        height: "270px",
                        borderRadius: "10px",
                      }}
                      src={computerlab}
                      alt="Blog Images"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </div>
  );
}

export default Labrottry;
