import React from 'react'
import Header from '../../common/Header'
import Footer from '../../common/Footer'

function Hostel() {
  return (
    <div>
        <Header></Header>
        <div class="edu-breadcrumb-area">
            <div class="container">
                <div class="breadcrumb-inner">
                    <div class="page-title">
                        <h1 class="title">Facilities</h1>
                    </div>
                </div>
            </div>
            <ul class="shape-group">
                <li class="shape-1">
                    <span></span>
                </li>
                <li class="shape-2 scene"><img data-depth="2" src="assets/images/about/shape-13.png" alt="shape" /></li>
                <li class="shape-3 scene"><img data-depth="-2" src="assets/images/about/shape-15.png" alt="shape"/></li>
                <li class="shape-4">
                    <span></span>
                </li>
                <li class="shape-5 scene"><img data-depth="2" src="assets/images/about/shape-07.png" alt="shape" /></li>
            </ul>
        </div>
        <section class="section-gap-equal">
            <div class="container">
                <div class="row row--30">
                    <div class="col-lg-12">
                      <div class="amenities_head">
                          <h3 class="title mb-0">Hostel</h3>
                          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                          tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                          quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                          consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                          cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                          proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                      </div>
                    </div>
                    <div class="col-lg-12">
                        <div class="edu-blog blog-style-list amenities_main" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                            <div class="inner">
                                <div class="thumbnail">
                                    <img src="assets/images/blog/class-room.jpg" alt="Blog Images" />
                                </div>
                                <div class="content">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Ut enim ad minim veniam,quis nostrud exercitation ullamco laboris.</p>
                                </div>
                            </div>
                        </div>
                       
                        <div class="course-overview">
                          <div class="border-box">
                              <h5 class="title" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">If we develop a reading habit among the students then we expect these out comes:-</h5>
                              <div class="row g-5">
                                  <div class="col-lg-6" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                                      <ul>
                                          <li>Learn to use Python professionally, learning both Python 2 & Python 3!</li>
                                          <li>Build 6 beautiful real-world projects for your portfolio (not boring toy apps)</li>
                                      </ul>
                                  </div>
                                  <div class="col-lg-6" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                                      <ul>
                                          <li>Understand the Theory behind Vue.js and use it in Real Projects</li>
                                          <li>Create responsive, accessible, and beautiful layouts</li>
                                      </ul>
                                  </div>
                              </div>
                          </div>
                        </div>
                      

                    </div>
                </div>
            </div>
        </section>
        <Footer></Footer>
    </div>
  )
}

export default Hostel