import React from "react";
import Header from "../../common/Header";
import Footer from "../../common/Footer";

function Overview() {
  return (
    <>
<Header></Header>
<div>
        <div className="home-one-cta-two cta-area-1 banner_1">
          <div className="container">
            <div className="row text-center">
              <div className="col-md-12">
                <div className="about_over" />
                <div className="about_school">
                  <h1>About Us</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="missionandvision-content-contaioner">
        <div class="edu-course course-style-4 course-style-14">
          <div class="inner">
            <div class="content ">
              <div className="inner-content">
                <h2>Overview</h2>

                <h6 class="title">
                  <a href="course-details.html">Established In 2011</a>
                </h6>

                <p>
                Jai Gurudev International School also known as JGIS   The school was established in 2011, spread over an area of about 10618.95 Sq. Metres of lush, green lawns, is a co-educational day-cum-boarding school. Founded in 2011, Jai Gurudev International School is a Senior Secondary, affiliated to CBSE. Affiliation ID is 1030705. Address of the school is: Isha Nagar Parasia Road Partala Chindwara,. PIN Code: 480001. Email address of the school is rashmibhargava@gmail.com. The school is being managed by Jai Gurudev Seva Samiti.
                  <div className="image-parent-float-right">
                    <a href="course-details.html">
                      <img
                        src="https://scontent.fixb1-1.fna.fbcdn.net/v/t39.30808-6/311569265_475017837979662_4079450510857644886_n.jpg?stp=dst-jpg_p960x960&_nc_cat=110&ccb=1-7&_nc_sid=e3f864&_nc_ohc=W_ui48P5D8MAX8pVV7U&_nc_ht=scontent.fixb1-1.fna&oh=00_AfCpnmnpNkgXos89jDiq5Oc0faNJcDvyoZHr-32C0-mq7A&oe=64AB0978"
                        alt="Course Meta"
                        style={{height:'280px'}}
                      />
                    </a>
                  </div>
                </p>
                <p>
                The school comes with an uncompromising commitment. It aims to achieve specific, measurable, observable and quantifiable results among all aspirants/students. Because the School has a vision to provide value based education to young minds and provide a dynamic learning environment.
However, the School aegis for every student which strives for excellence through active learning and education. Moreover, the School has the core committed faculty which has come from accomplished backgrounds with vast experience.


                </p>
                <br />
                <br />
                <div>
                  <h2>Green Campus</h2>
                  <ul
                    class="features-list"
                    data-sal-delay="150"
                    data-sal="slide-up"
                    data-sal-duration="800"
                  >
                    <li>Flexible Classes</li>
                    <li>Offline Classe Mode</li>
                    <li>Educator Support</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <br/><br/>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}

export default Overview;
