import React from "react";
import Header from "../../common/Header";
import Footer from "../../common/Footer";

function FeePolicy() {
  return (
    <div>
      <Header></Header>
      <div>
        <div className="home-one-cta-two cta-area-1 banner_1">
          <div className="container">
            <div className="row text-center">
              <div className="col-md-12">
                <div className="about_over" />
                <div className="about_school">
                  <h1>Admission</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="missionandvision-content-contaioner">
        <div class="edu-course course-style-4 course-style-14">
          <div className="inner-content">
            <h3>Fee Policy</h3>

            <div>
              <ul
                class="features-list"
                data-sal-delay="150"
                data-sal="slide-up"
                data-sal-duration="800"
              >
                <li>
                  {" "}
                  Fee should be deposited by cash on or before the due date
                  given in Installment Plan. In case of Cheque-Account payee
                  cheque must be given in name of “Bhandari Public School”
                  payble at Khandwa. Only local cheques will be accepted
                </li>{" "}
                <li>
                  {" "}
                  No deduction is made in the fee for the absence of children.
                </li>{" "}
                <li>
                  {" "}
                  t is advised to the parents that they should obtain ‘No Dues
                  Certificates’ from the Accounts Office, before the Final
                  Examination in prescribed format; without which, the Admit
                  Card for Examination may not be given to appear in the
                  examination
                </li>{" "}
                <li>
                  {" "}
                  Bus & Mess facility is available as optional. Once opted, it
                  is to be used for the whole session and not for any part
                  thereof. Only those who wish to utilize it for the whole
                  session should take it otherwise no refund will be made.
                </li>{" "}
                <li>
                  {" "}
                  One Month’s notice is to be given before withdrawing a
                  student.
                </li>{" "}
                <li>
                  {" "}
                  Fee can be deposited in any one of the two (Options) modes-(a)
                  Lumpsum full fee 1 time (b) in five equal installments Due
                  dates are-
                </li>{" "}
                <li>
                  If fee is not paid as per the last date given above, it should
                  be paid with a fine @ Rs. 10/- per day, from the last date of
                  the installment; failing to do so, the name of the child may
                  be struck off from the roll and re-admission fee Rs. 1000/-
                  will be charged, if re-admission is granted by the Principal.
                </li>{" "}
                <li>
                  {" "}
                  In case fee is not paid till 10th April, if parent is paying
                  fee during or after summer vacation in July or later month, it
                  is mandatory to pay fee along with fine @ 10/- day from due
                  date (10th April) till payment is made (including May & June
                  month’s fine).
                </li>{" "}
                <li>
                  {" "}
                  Five days grace period is given if payment is made through
                  cheque i.e. cheques will be accepted till 15th of the
                  respective Installment due date. In case the cheque is
                  dishonoured, fee has to be deposited in case, with a fine of
                  Rs. 200/- and Bank charge as charge by the Bank.
                </li>{" "}
                <li>
                  {" "}
                  New Academic session begins from March / April every year. If
                  fee is not paid by due date then fine @ Rs. 10/- per day will
                  be levied from the due date till fee is paid.
                </li>{" "}
                <li>
                  {" "}
                  Once mess option is taken, it will not be changed during the
                  current session. Mess menu will be available month wise on
                  school’s website – www.bhandarischool.com
                </li>{" "}
                <li>
                  {" "}
                  ​Transport facility is given for one academic session. Once it
                  is started, it won’t be stopped during the session.
                </li>
              </ul>
              <br />
              <strong>Additional Rules of on line Payment:</strong>
              <ul
                class="features-list"
                data-sal-delay="150"
                data-sal="slide-up"
                data-sal-duration="800"
              >
                <li>
                  {" "}
                  Fees once paid are refundable as per school policies but are
                  not transferable.
                </li>{" "}
                <li>
                  {" "}
                  All refund requests must be submitted by the student/parents
                  in writing addressed to the Principal, in person, by regular
                  mail, or by email. The Principal shall acknowledge the receipt
                  of the same. Telephone messages are NOT acceptable.
                </li>{" "}
                <li>
                  {" "}
                  The fee refund is solely based on the balance amount in the
                  student’s account after all applicable dues owed to the school
                  are deducted. Discounts or concessions provided, may be void
                  and may not apply upon withdrawal.
                </li>{" "}
                <li>
                  {" "}
                  The excess payment received through technical or other reasons
                  will be refunded only after due verification and/or within 30
                  days after receipt of written complaint by the parent/student.
                </li>{" "}
                <li>
                  {" "}
                  The mode of refund will be electronic/ physical and will be
                  refunded in the account from where the transaction was made.
                </li>{" "}
                <li>
                  {" "}
                  Service tax rate applicable will be as notified by the
                  Government from time to time will also be added to the charges
                  payable to the online payment gateway.
                </li>{" "}
                <li>
                  The net amount refunded will be after deduction of transaction
                  processing charges paid to the online payment gateway.
                </li>{" "}
              </ul>
              <br />
              <strong>Links To Other Sites:</strong>
            </div>
          </div>
          <br />
          <br />
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default FeePolicy;
