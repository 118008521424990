const APPConfig = {
 API_URL: "https://jgisserver.onrender.com/api",

};
const ApiEndPoints = {
  Login: `${APPConfig.API_URL}/user/login`,
  forgetPass: `${APPConfig.API_URL}/user/sendotp`,
  verifyOtp: `${APPConfig.API_URL}/user/verifyotp`,
  changepassword: `${APPConfig.API_URL}/user/changepassword`,
  CreateBanner: `${APPConfig.API_URL}/banner/create/`,
  getBanner: `${APPConfig.API_URL}/banner/`,
  deleteBanner: `${APPConfig.API_URL}/banner/delete/`,
  lab: `${APPConfig.API_URL}/facility/`,
  CreateNews: `${APPConfig.API_URL}/newsEvent/create`,
  getNews: `${APPConfig.API_URL}/newsEvent/`,
  DeleteNews: `${APPConfig.API_URL}/newsEvent/delete/`,
  CreatePhoto: `${APPConfig.API_URL}/photo/create`,
  getPhoto: `${APPConfig.API_URL}/photo/getphotos`,
  DeltePhoto: `${APPConfig.API_URL}/photo/delete/`,
  getVideos: `${APPConfig.API_URL}/video/`,
  CreateVideo: `${APPConfig.API_URL}/video/create`,
  DeleteVideo: `${APPConfig.API_URL}/video/delete/`,
  CreatePress: `${APPConfig.API_URL}/press/create`,
  DeletePress: `${APPConfig.API_URL}/press/Delete/`,
  getPress: `${APPConfig.API_URL}/press/`,
};
export default ApiEndPoints;
