import React from "react";
import "./style.css";
import logoDark from "../../assets/images/logo/logo-dark.png";
import Aboutschooimg from "../../assets/images/course/Aboutschooimg.jpg";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function AdminNavbar() {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("login");
    navigate("/login");
  };

  return (
    <div>
      <div class="header">
        <div class="header-left">
          <Link to="/" class="logo">
            <img src={logoDark} alt="Logo" />
          </Link>
        </div>

        <div class="menu-toggle"></div>

        <div class="top-nav-search">
          <form>
            <input type="text" class="form-control" placeholder="Search here" />
            <button class="btn" type="submit">
              <i class="fas fa-search"></i>
            </button>
          </form>
        </div>

        {/**<a class="mobile_btn" id="mobile_btn">
            <i class="fas fa-bars"></i>
          </a>
 */}
        <ul class="nav user-menu">
          <li
            class="nav-item dropdown has-arrow new-user-menus"
            style={{ position: "relative", bottom: "10px" }}
          >
            <a
              href="#"
              class="dropdown-toggle nav-link"
              data-bs-toggle="dropdown"
            >
              <span class="user-img">
                <img
                  class="rounded-circle"
                  src={Aboutschooimg}
                  width="31"
                  alt="Ryan Taylor"
                />
                <div class="user-text">
                  <h6>Admin</h6>
                  <p class="text-muted mb-0" onClick={handleLogout}>
                    Logout
                  </p>
                </div>
              </span>
            </a>
            <div
              class="dropdown-menu"
              style={{ position: "relative", bottom: "20px" }}
            >
              <div class="user-header">
                <div class="avatar avatar-sm">
                  <img
                    src={Aboutschooimg}
                    alt="User Image"
                    class="avatar-img rounded-circle"
                  />
                </div>
                <div class="user-text">
                  <h6>Admin</h6>
                  <p class="text-muted mb-0" onClick={handleLogout}>
                    Logout
                  </p>
                </div>
              </div>
              <a
                class="dropdown-item"
                href="https://preschool.dreamguystech.com/template/profile.html"
              >
                My Profile
              </a>
              <a
                class="dropdown-item"
                href="https://preschool.dreamguystech.com/template/inbox.html"
              >
                Inbox
              </a>
              <a
                class="dropdown-item"
                href="https://preschool.dreamguystech.com/template/login.html"
              >
                Logout
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default AdminNavbar;
