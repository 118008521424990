import React from 'react'

function Recentactivity() {
  return (
    <div> <div className="edu-cta-banner-area home-one-cta-wrapper bg-image">
    <div className="container">
      <div className="row">
        <div className="col-lg-5">
          <div className="brand-section-heading">
            <div
              className="section-title section-left"
              data-sal-delay={150}
              data-sal="slide-up"
              data-sal-duration={800}
            >
              <span className="pre-title">Our Partners</span>
              <h2 className="title">Learn with Our Partners</h2>
              <span className="shape-line">
                <i className="icon-19" />
              </span>
              <p>
                Lorem ipsum dolor sit amet consectur adipiscing elit sed
                eiusmod tempor incididunt.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-7">
          <div className="brand-grid-wrap">
            <div className="brand-grid">
              <img
                src="assets/images/brand/brand-01.png"
                alt="Brand Logo"
              />
            </div>
            <div className="brand-grid">
              <img
                src="assets/images/brand/brand-02.png"
                alt="Brand Logo"
              />
            </div>
            <div className="brand-grid">
              <img
                src="assets/images/brand/brand-03.png"
                alt="Brand Logo"
              />
            </div>
            <div className="brand-grid">
              <img
                src="assets/images/brand/brand-04.png"
                alt="Brand Logo"
              />
            </div>
            <div className="brand-grid">
              <img
                src="assets/images/brand/brand-05.png"
                alt="Brand Logo"
              />
            </div>
            <div className="brand-grid">
              <img
                src="assets/images/brand/brand-06.png"
                alt="Brand Logo"
              />
            </div>
            <div className="brand-grid">
              <img
                src="assets/images/brand/brand-07.png"
                alt="Brand Logo"
              />
            </div>
            <div className="brand-grid">
              <img
                src="assets/images/brand/brand-08.png"
                alt="Brand Logo"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div></div>
  )
}

export default Recentactivity