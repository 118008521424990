import { useState } from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  const [dataArray, setDataArray] = useState([
    {
      name: "Jay Gurudev International School",
      number: "07162 292 949",
      email: "info@JGIS.com",
      address:
        "Isha Nagar, Parasia Road, Partala, Chhindwara, Madhya Pradesh-480001",
    },
  ]);

  return (
    <>
      <footer className="edu-footer footer-lighten bg-image footer-style-1">
        <div className="footer-top">
          <div className="container">
            <div className="row g-5 " style={{ textAlign: "left" }}>
              <div className="col-lg-3 col-md-6">
                <div className="edu-footer-widget">
                  <div className="logo">
                    <a href="#">
                      <img
                        className="logo-light"
                        src="assets/images/logo/logo-dark.png"
                        alt="Corporate Logo"
                      />
                      <img
                        className="logo-dark"
                        src="assets/images/logo/logo-white.png"
                        alt="Corporate Logo"
                      />
                    </a>
                  </div>
                  <p className="description">
                    {dataArray.map((item) => item.name)}
                  </p>
                  <div className="widget-information">
                    <ul className="information-list">
                      {dataArray.map((item) => (
                        <>
                          <li>
                            <span>Campus Address</span>
                            {item.address}
                          </li>
                          <li>
                            <span>Call:</span>
                            <a href="#">+{item.number}</a>
                          </li>
                          <li>
                            <span>Email:</span>
                            <a href="#" target="_blank">
                              {item.email}
                            </a>
                          </li>
                        </>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6">
                <div className="edu-footer-widget explore-widget">
                  <h4 className="widget-title">Online Platform</h4>
                  <div className="inner">
                    <ul className="footer-link link-hover">
                      <li>
                        <Link to="/overview">About</Link>
                      </li>
                      <li>
                        <Link to="/">Courses</Link>
                      </li>
                      <li>
                        <Link to="/">Instructor</Link>
                      </li>
                      <li>
                        <Link to="/newsEvents">Events</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-sm-6">
                <div className="edu-footer-widget quick-link-widget">
                  <h4 className="widget-title">Links</h4>
                  <div className="inner">
                    <ul className="footer-link link-hover">
                      <li>
                        <Link to="/contact">Contact Us</Link>
                      </li>
                      <li>
                        <Link to="/photgallery">Gallery</Link>
                      </li>
                      <li>
                        <Link to="/faq">FAQ's</Link>
                      </li>
                      <li>
                        <Link to="/termsCondition">Term &amp; Conditions</Link>
                      </li>
                      <li>
                        <Link to="/privacy">Privacy Policy</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="edu-footer-widget">
                  <h4 className="widget-title">Contacts</h4>
                  <div className="inner">
                    <p className="description">
                      Enter your email address to contact us
                    </p>
                    <div className="input-group footer-subscription-form">
                      <input
                        type="email"
                        className="form-control"
                        placeholder=" Enter your email"
                      />
                      <button className="edu-btn btn-medium" type="button">
                        Send <i className="icon-4" />
                      </button>
                    </div>
                    <ul className="social-share icon-transparent">
                      <li>
                        <Link
                          to="https://www.facebook.com/jaigurudevinternationalschool"
                          className="color-fb"
                        >
                          <i className="icon-facebook" />
                        </Link>
                      </li>

                      <li>
                        <Link
                          to="https://instagram.com/jai_gurudev_inter_school?igshid=NTc4MTIwNjQ2YQ=="
                          className="color-ig"
                        >
                          <i className="icon-instagram" />
                        </Link>
                      </li>

                      <li>
                        <Link
                          to="https://www.youtube.com/@jaigurudevinternationalsch9606/featured"
                          className="color-yt"
                        >
                          <i className="icon-youtube" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner text-center">
                  <p>
                    Copyright 2023{" "}
                    <a href="#" target="_blank">
                      JGIS
                    </a>
                    All Rights Reserved
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
