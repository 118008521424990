import React, { useState, useEffect } from "react";
import ApiServices from "../../NetworkCall/ApiServices";
import ApiEndPoints from "../../NetworkCall/ApiEndPoint";
import { Link } from "react-router-dom";
function DiscoverSchool() {
  const [Lab, setLab] = useState([]);

  const fetchCategoryImages = async () => {
    try {
      const payload = { category: "Laboratory" };
      const response = await ApiServices.post(ApiEndPoints.getPhoto, payload);
      if (response.status.code === 200) {
        setLab(response.data.photoData);
        console.log(response.data.photoData);
      }
    } catch (error) {
      console.log("Error fetching category images:", error);
    }
  };

  useEffect(() => {
    fetchCategoryImages();
  }, []);

  return (
    <div>
      {" "}
      <div className="edu-course-area course-area-1 edu-section-gap bg-lighten01">
        <div className="container">
          <div
            className="section-title section-center"
            data-sal-delay={150}
            data-sal="slide-up"
            data-sal-duration={800}
          >
            <h2 className="title">Discover our School</h2>
            <span className="shape-line">
              <i className="icon-19" />
            </span>
          </div>

          <div className="row g-5">
            <div
              className="col-md-6 col-xl-4"
              data-sal-delay={150}
              data-sal="slide-up"
              data-sal-duration={800}
            >
              <div className="edu-course course-style-1 hover-button-bg-white">
                <div className="inner">
                  <div className="thumbnail" style={{ position: "relative" }}>
                    <Link to="/labbotry">
                      <div style={{ height: "253px" }}>
                        <img
                          style={{ height: "100%" }}
                          src="https://media.istockphoto.com/id/476694776/photo/japanese-high-school-an-empty-science-laboratory-microscopes-on-benches.webp?b=1&s=170667a&w=0&k=20&c=CICKGKKrabEMKdO6qDVQ9wRi_FloO0WBHRHfvU7gHPs="
                          alt="Course Meta"
                        />
                      </div>
                    </Link>
                    <p
                      className="hover-text text-body"
                      style={{
                        position: "absolute",
                        width: "100%",
                        bottom: "-30px",
                      }}
                    >
                      Laboratory
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 col-xl-4"
              data-sal-delay={150}
              data-sal="slide-up"
              data-sal-duration={800}
            >
              <div className="edu-course course-style-1 hover-button-bg-white">
                <div className="inner">
                  <div className="thumbnail" style={{ position: "relative" }}>
                    <Link to="/libbrery">
                      <div style={{ height: "253px" }}>
                        <img
                          style={{ height: "100%" }}
                          src="https://bbms.bluebells.org/wp-content/uploads/2022/08/LIBRARY.jpg"
                          alt="Course Meta"
                        />
                      </div>
                    </Link>
                    <p
                      className="hover-text text-body"
                      style={{
                        position: "absolute",
                        width: "100%",
                        bottom: "-30px",
                      }}
                    >
                      Library
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 col-xl-4"
              data-sal-delay={150}
              data-sal="slide-up"
              data-sal-duration={800}
            >
              <div className="edu-course course-style-1 hover-button-bg-white">
                <div className="inner">
                  <div className="thumbnail" style={{ position: "relative" }}>
                    <Link to="/amenties">
                      <div style={{ height: "253px" }}>
                        <img
                          style={{ height: "100%" }}
                          src="https://kamlanehruschool.in/uploads/Smart%20Class.jpg"
                          alt="Course Meta"
                        />
                      </div>
                    </Link>
                    <p
                      className="hover-text text-body"
                      style={{
                        position: "absolute",
                        width: "100%",
                        bottom: "-30px",
                      }}
                    >
                      Smart Class
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 col-xl-4"
              data-sal-delay={150}
              data-sal="slide-up"
              data-sal-duration={800}
            >
              <div className="edu-course course-style-1 hover-button-bg-white">
                <div className="inner">
                  <div
                    className="thumbnail bus"
                    style={{ position: "relative" }}
                  >
                    <Link to="/bus">
                      <div style={{ height: "253px" }}>
                        <img
                          style={{ height: "100%" }}
                          src="https://5.imimg.com/data5/SELLER/Default/2022/5/YX/YF/NP/81143335/tata-lp-410-marcopolo-school-variant-bus-500x500.jpg"
                          alt="Course Meta"
                        />
                      </div>
                    </Link>
                    <p
                      className="hover-text text-body"
                      style={{
                        position: "absolute",
                        width: "100%",
                        bottom: "-30px",
                      }}
                    >
                      Bus Facility
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 col-xl-4"
              data-sal-delay={150}
              data-sal="slide-up"
              data-sal-duration={800}
            >
              <div className="edu-course course-style-1 hover-button-bg-white">
                <div className="inner">
                  <div className="thumbnail" style={{ position: "relative" }}>
                    <Link to="/amenties">
                      <div style={{ height: "253px" }}>
                        <img
                          style={{ height: "100%" }}
                          src="https://assets.website-files.com/604a97c70aee09eed25ce991/61aa4e4b46409e674cf8c773_zelIDNbUG5b1u26i3RRCfXDZ8SLP3wPJ1637612723.jpg"
                          alt="Course Meta"
                        />
                      </div>
                    </Link>
                    <p
                      className="hover-text text-body"
                      style={{
                        position: "absolute",
                        width: "100%",
                        bottom: "-30px",
                      }}
                    >
                      Cafetria
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 col-xl-4"
              data-sal-delay={150}
              data-sal="slide-up"
              data-sal-duration={800}
            >
              <div className="edu-course course-style-1 hover-button-bg-white">
                <div className="inner">
                  <div className="thumbnail" style={{ position: "relative" }}>
                    <Link Link to="/musicDrama">
                      <div style={{ height: "253px" }}>
                        <img
                          style={{ height: "100%" }}
                          src="https://content3.jdmagicbox.com/comp/patna/v6/0612px612.x612.171003000352.u1v6/catalogue/sangeet-a-rhythmic-world-saguna-more-patna-music-classes-ima4avca7o.jpg"
                          alt="Course Meta"
                        />
                      </div>
                    </Link>
                    <p
                      className="hover-text text-body"
                      style={{
                        position: "absolute",
                        width: "100%",
                        bottom: "-30px",
                      }}
                    >
                      Music & Dance class
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DiscoverSchool;
