import Header from '../common/Header';
import React, { useState } from 'react';
import ApiEndPoints from '../../NetworkCall/ApiEndPoint';
import ApiServices from '../../NetworkCall/ApiServices';
import { useNavigate, Link } from 'react-router-dom';
import { MDBBtn, MDBContainer, MDBRow, MDBCol, MDBInput } from 'mdb-react-ui-kit';
import Footer from '../common/Footer';

function ForgetPass() {
  const [email, setEmail] = useState('');
  const [name, setUserName] = useState('');
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');

  const LoginAdmin = async (e) => {
    e.preventDefault();
    console.log("callllll");
    const payload = {
     
      email: email
    };

    try {
      const response = await ApiServices.post(ApiEndPoints.forgetPass, payload);
      if (response.data.message === 'OTP send successfully') {
        console.log(response);
        setEmail('');
       
        navigate('/verifyotp');
      } else {
        console.log(response);
        setErrorMessage('Your email is not  registered ');
      }
    } catch (error) {
      console.log(error);
      setErrorMessage('Your email is not  registered');
    }
  };

  return (
    <div>
      <Header />
      <MDBContainer className="my-5 gradient-form">
        <MDBRow>
          <MDBCol col='6' className="mb-5">
            <div className="d-flex flex-column ms-5">
              <div className="text-center">
                <img src="assets/images/logo/logo-dark.png" style={{ width: '185px' }} alt="logo" />
                <h4 className="mt-1 mb-5 pb-1"></h4>
              </div>
              <p>Please verify your email</p>
              
              <MDBInput
                wrapperClass='mb-4'
                label='Email'
                id='form2'
                type='email'
                value={email}
                placeholder='Enter your email'
                onChange={(e) => setEmail(e.target.value)}
              />
              <div className="text-center pt-1 mb-5 pb-1">
                <button
                  className="mb-4 w-100 gradient-custom-2"
                  onClick={LoginAdmin}
                  style={{ backgroundColor: 'blue', color: 'white' }}
                >
                  Send OTP
                </button>
              </div>
              <div className="d-flex flex-row align-items-center justify-content-center pb-4 mb-4">
                {errorMessage && (
                  <p className="mb-0" style={{ color: 'red' }}>
                    {errorMessage}
                  </p>
                )}
              </div>
            </div>
          </MDBCol>
          <MDBCol col='6' className="mb-5 vis">
            <div className="d-flex flex-column justify-content-center gradient-custom-2 h-100 mb-4">
              <div className="text-white px-3 py-4 p-md-5 mx-md-4">
                <h4 class="mb-4">Jai Gurudev International School</h4>
                <p class="small mb-0">
                  Jai Gurudev International School also known as JGIS. The school was established in 2011. Jai Gurudev
                  International School is a Co-Ed school affiliated to Central Board of Secondary Education (CBSE). It is
                  managed by Jai Gurudev Seva Samiti.
                </p>
              </div>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <Footer />
    </div>
  );
}

export default ForgetPass;
