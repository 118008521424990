import React, { useState, useEffect } from "react";
import ApiServices from "../NetworkCall/ApiServices";
import ApiEndPoints from "../NetworkCall/ApiEndPoint";
import { useNavigate, Link } from "react-router-dom";
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBInput,
} from "mdb-react-ui-kit";
import Header from "./common/Header";
import Footer from "./common/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleLogout = () => {
    localStorage.removeItem("login");
    navigate("/login");
  };

  const LoginAdmin = async (e) => {
    e.preventDefault();
    const payload = {
      email: email,
      password: password,
    };

    try {
      const response = await ApiServices.post(ApiEndPoints.Login, payload);
      console.log(response)
      if (response.data.message === "logged in successfully") {
        setEmail("");
        setPassword("");
        setIsLoggedIn(true);
        localStorage.setItem("login", true);
        navigate("/admin");
        LogoutAuto();
        toast.success("Login successful", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        navigate("/login");
        toast.success("Login Failed", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setErrorMessage("Please enter a valid email and password");
      }
    } catch (error) {
      navigate("/login");
      setErrorMessage("Please enter a valid email and password");
    }
  };

  const LogoutAuto = () => {
    setTimeout(() => {
      handleLogout();
    }, 60 * 60 * 1000); // 30 minutes
  };

  useEffect(() => {
    LogoutAuto();
  }, []);

  return (
    <>
      <Header />
      <MDBContainer className="my-5 gradient-form">
        <MDBRow>
          <MDBCol col="6" className="mb-5">
            <div className="d-flex flex-column ms-5">
              <div className="text-center">
                <img
                  src="assets/images/logo/logo-dark.png"
                  style={{ width: "185px" }}
                  alt="logo"
                />
                <h4 className="mt-1 mb-5 pb-1"></h4>
              </div>
              <p>Please login to your account</p>
              <MDBInput
                style={{ border: "1px solid #cccccc" }}
                wrapperClass="mb-4"
                label="Username"
                id="form1"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <MDBInput
                style={{ border: "1px solid #cccccc" }}
                wrapperClass="mb-4"
                label="Password"
                id="form2"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <div className="text-center pt-1 mb-5 pb-1">
                <button
                  className="mb-4 w-100 gradient-custom-2"
                  onClick={LoginAdmin}
                  style={{ backgroundColor: "blue", color: "white" }}
                >
                  Sign in
                </button>
                <Link className="text-muted" to="/forgetpassword">
                  Forgot password?
                </Link>
              </div>
              <div className="d-flex flex-row align-items-center justify-content-center pb-4 mb-4">
                {errorMessage && (
                  <p className="mb-0" style={{ color: "red" }}>
                    {errorMessage}
                  </p>
                )}
              </div>
            </div>
          </MDBCol>
          <MDBCol col="6" className="mb-5  vis">
            <div className="d-flex flex-column  justify-content-center gradient-custom-2 h-100 mb-4">
              <div className="text-white px-3 py-4 p-md-5 mx-md-4">
                <h4 class="mb-4">Jai Gurudev International School</h4>
                <p class="small mb-0">
                  Jai Gurudev International School also known as JGIS. The
                  school was established in 2011. Jai Gurudev International
                  School is a Co-Ed school affiliated to Central Board of
                  Secondary Education (CBSE). It is managed by Jai Gurudev Seva
                  Samiti.
                </p>
              </div>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <Footer />
    </>
  );
}

export default Login;
