import React from "react";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
import Aboutschooimg from "..//..//..//assets//images//course//Aboutschooimg.jpg";
function AddmisionProcess() {
  return (
    <>
      <Header></Header>
      <div>
        <div className="home-one-cta-two cta-area-1 banner_1">
          <div className="container">
            <div className="row text-center">
              <div className="col-md-12">
                <div className="about_over" />
                <div className="about_school">
                  <h1>Admission</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="missionandvision-content-contaioner">
        <div class="edu-course course-style-4 course-style-14">
          <div class="inner">
            <div class="content">
              <div className="inner-content">
                <h2>Admission Process</h2>
                <p>
                  <img
                    className="admission-process"
                    src={Aboutschooimg}
                    alt="Course Meta"
                  />
                  In our school, we have classes from Nursery to XII. Admission
                  in all the classes( Except X & XII) is provided on first come
                  first serve bases if seats are available. No direct admission
                  is allowed in Class X & XII.
                  <br />
                  <br />
                  <strong>
                    Only completely filled Registration forms will be accepted
                    with the following documents:
                  </strong>{" "}
                  <br />
                  <br />
                  <ul
                    class="features-list"
                    data-sal-delay="150"
                    data-sal="slide-up"
                    data-sal-duration="800"
                  >
                    <li>Two recent passport size photographs</li>
                    <li>Copy of Birth Certificate (Nursery to standard 1st)</li>
                    <li>Residence Proof (Local)</li>
                    <li>
                      Copy of Mark Sheet/Progress Report of the last class
                      passed (Not for Nursery)
                    </li>
                    <li>
                      Having a Certificate in original (Std. 2nd and above)
                      signed by D.E.O. or authority of the board to which last
                      attended school was affiliated.
                    </li>
                    <li>Copy of Caste Certificate</li>
                  </ul>
                </p>
                <strong>General Instructions</strong>
                <p>
                  {" "}
                  <ul
                    class="features-list"
                    data-sal-delay="150"
                    data-sal="slide-up"
                    data-sal-duration="800"
                  >
                    <li>
                      Admissions are granted in Classes I to IX through personal
                      interviews. The applicant should not have completed
                      seven/eight/nine/ten/eleven/ twelve/
                      thirteen/fourteen/sixteen years for 1st to 9th classes
                      respectively on 1st January of the year in which admission
                      is sought for these classes.
                    </li>
                    <li>
                      The students are not permitted to remain in the school
                      after completion of their 18th year, nor after failure in
                      any class for two consecutive years.
                    </li>
                    <li>
                      The school management reserves the right to make and alter
                      its own admission policy. Admission cannot be claimed as a
                      matter of right.
                    </li>
                    <li>
                      Copy of the child’s previous academic record along with
                      Original Date of Birth Certificate needs to be brought at
                      the time of admission.
                    </li>
                    <li>
                      Parents are requested NOT to stand in a queue outside the
                      school before the scheduled date/time.
                    </li>
                    <li>
                      Forms will be issued/ accepted only for children who
                      fulfill the age criteria. The school does not entertain
                      parental individual query about admission. All information
                      needed is available in the school brochure.
                    </li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
          <br />
          <br />
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}

export default AddmisionProcess;
