import React from "react";
import Header from "../../common/Header";
import Footer from "../../common/Footer";

function House() {
  return (
    <div>
      <Header></Header>
      <div class="edu-breadcrumb-area banner_1">
        <div class="container">
          <div class="breadcrumb-inner">
            <div class="page-title">
              <h1 class="title" style={{ position: "relative", zIndex: "1" }}>
                Activities
              </h1>
            </div>
          </div>
        </div>
        <div className="about_over"></div>
      </div>
      <section class="section-gap-equal">
        <div class="container">
          <div class="row row--30">
            <div class="col-lg-12">
              <div class="amenities_head">
                <h3 class="title mb-0">House</h3>
                <p style={{ textAlign: "justify" }}>
                  Various activities, events and sports/ games are organized
                  throughout the session to develop the spirit of healthy
                  competition among students. These activities help teachers to
                  identify student’s skill, also provide platform and
                  opportunity to students to express their own thoughts in
                  public. To make it more effective, students are divided into
                  four houses. Each house is headed by House Warden assisted by
                  member teachers. Each house has Captain and Vice Captain. In
                  each house, group of House Warden, Member Teachers, Captain
                  and Vice Captain share the responsibility of house activities,
                  events etc. School selects the best house on behalf of annual
                  performance. Each house has its colour and flag as mentioned
                  below.
                </p>
              </div>
            </div>
            <div class="col-lg-12">
              <div
                class="edu-blog blog-style-list amenities_main mb-0"
                data-sal-delay="150"
                data-sal="slide-up"
                data-sal-duration="800"
              >
                <div class="inner">
                  <table class="table cart-table house-table">
                    <thead>
                      <tr>
                        <th scope="col" class="product-title">
                          Sr. No.
                        </th>
                        <th scope="col" class="product-price">
                          Name of the House{" "}
                        </th>
                        <th scope="col" class="product-price">
                          Colour Allotted
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="product-title">
                          <span>1</span>
                        </td>
                        <td class="product-price" data-title="Price">
                          <span>Lotus</span>
                        </td>
                        <td class="product-price" data-title="Price">
                          <span>Blue</span>
                        </td>
                      </tr>
                      <tr>
                        <td class="product-title">
                          <span>2</span>
                        </td>
                        <td class="product-price" data-title="Price">
                          <span>Lupin</span>
                        </td>
                        <td class="product-price" data-title="Price">
                          <span>Green</span>
                        </td>
                      </tr>
                      <tr>
                        <td class="product-title">
                          <span>3</span>
                        </td>
                        <td class="product-price" data-title="Price">
                          <span>Orchid</span>
                        </td>
                        <td class="product-price" data-title="Price">
                          <span>Yellow</span>
                        </td>
                      </tr>
                      <tr>
                        <td class="product-title">
                          <span>4</span>
                        </td>
                        <td class="product-price" data-title="Price">
                          <span>Tulip</span>
                        </td>
                        <td class="product-price" data-title="Price">
                          <span>Red</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </div>
  );
}

export default House;
