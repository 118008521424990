import React from "react";

function Schoolinfo() {
  return (
    <div>
      <div className="home-one-cta-two cta-area-1 banner_1">
        <div className="container">
          <div className="row text-center">
            <div className="col-md-12">
              <div className="about_over" />
              <div className="about_school">
                <h1>Jay Gurudev International School</h1>
                <p>
                  <div style={{ color: "#ffffff" }}>
                    The school comes with an uncompromising commitment. It aims
                    to achieve specific, measurable, observable and quantifiable
                    results among all aspirants/students. Because the School has
                    a vision to provide value based education to young minds and
                    provide a dynamic learning environment. However, the School
                    aegis for every student which strives for excellence through
                    active learning and education. Moreover, the School has the
                    core committed faculty which has come from accomplished
                    backgrounds with vast experience.
                  </div>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Schoolinfo;
