




import React from "react";
import "./AdminSidebar.css";
import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarFooter,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from 'cdbreact';
import { NavLink } from 'react-router-dom';
function AdminSidebar() {
  return (
    <div style={{ display: 'flex', height: '100vh', overflow: 'scroll initial' }}>
    <CDBSidebar textColor="#fff" backgroundColor="#333">
      <CDBSidebarHeader prefix={<i className="fa fa-bars fa-large"></i>}>
        <a href="/" className="text-decoration-none" style={{ color: 'inherit' }}>
       ADMIN-JGIS
        </a>
      </CDBSidebarHeader>

      <CDBSidebarContent className="sidebar-content">
        <CDBSidebarMenu>
          <NavLink exact to="/admin" activeClassName="activeClicked">
            <CDBSidebarMenuItem icon="columns">Dashboard</CDBSidebarMenuItem>
          </NavLink>
          <NavLink exact to="/adminphoto" activeClassName="activeClicked">
            <CDBSidebarMenuItem icon="images">Photo-Gallery</CDBSidebarMenuItem>
          </NavLink>
          <NavLink exact to="/adminvideo" activeClassName="activeClicked">
            <CDBSidebarMenuItem icon="video">
          
              Video-Gallery</CDBSidebarMenuItem>
          </NavLink>
          <NavLink exact to="/adminpress" activeClassName="activeClicked">
            <CDBSidebarMenuItem icon="images">Press-Gallery</CDBSidebarMenuItem>
          </NavLink>

          <NavLink exact to="/adminnews"  activeClassName="activeClicked">
            <CDBSidebarMenuItem icon="plus">News & Events</CDBSidebarMenuItem>
          </NavLink>
        </CDBSidebarMenu>
      </CDBSidebarContent>

      <CDBSidebarFooter style={{ textAlign: 'center' }}>
        <div
          style={{
            padding: '20px 5px',
          }}
        >
          Sidebar Footer
        </div>
      </CDBSidebarFooter>
    </CDBSidebar>

</div>
  );
}

export default AdminSidebar


