import React from "react";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
import smartclassroom from "../../../assets/images/facilities/smartclassroom.jpg";
import teachingwithipad from "../../../assets/images/facilities/teachingwithipad.jpg";
import internetwithwifi from "../../../assets/images/facilities/internetwithwifi.jpg";
import playground from "../../../assets/images/facilities/Play-ground.jpg";
import cafeteria from "../../../assets/images/facilities/canteen.jpg";
import schoolbus from "../../../assets/images/facilities/schoolbus.jpg";

function Amenities() {
  return (
    <div>
      <Header></Header>
      <div class="edu-breadcrumb-area banner_1 ">
        <div class="container">
          <div class="breadcrumb-inner">
            <div class="page-title ">
              <h1 class="title" style={{ position: "relative", zIndex: "1" }}>
                Facilities
              </h1>
            </div>
          </div>
        </div>
        <div className="about_over"></div>
      </div>
      <section class="section-gap-equal">
        <div class="container">
          <div class="row row--30">
            <div class="col-lg-12">
              <div class="amenities_head ">
                <h3 class="title mb-0">Amenities</h3>
              </div>
            </div>
            <div class="col-lg-12">
              <div
                class="edu-blog blog-style-list amenities_main"
                style={{ boxShadow: "none" }}
                data-sal-delay="150"
                data-sal="slide-up"
                data-sal-duration="800"
              >
                <div class="inner">
                  <div class="thumbnail">
                    <img
                      style={{
                        width: "100%",
                        height: "270px",
                        borderRadius: "10px",
                      }}
                      src={smartclassroom}
                      alt="Blog Images"
                    />
                  </div>
                  <div class="content " style={{ textAlign: "justify" }}>
                    <h4 class="title">Smart Class Room</h4>
                    <p>
                      School has big well ventilated class rooms fitted with
                      comfortable seating arrangement, pin boards, notice board,
                      green board and other appliances. Standard smart
                      classrooms are teacher-led learning spaces that usually
                      include a computer, interactive whiteboard and projector.
                      Like a traditional class, teachers deliver front-of-class
                      learning – but the smart technologies provide students
                      more opportunities to interact with the content, the
                      teacher and each other.
                    </p>
                  </div>
                </div>
              </div>

              <div
                style={{ boxShadow: "none" }}
                class="edu-blog blog-style-list amenities_main"
                data-sal-delay="150"
                data-sal="slide-up"
                data-sal-duration="800"
              >
                <div class="inner">
                  <div
                    class="content"
                    style={{ textAlign: "justify", paddingRight: "40px" }}
                  >
                    <h4 class="title">Teaching with iPad</h4>
                    <p>
                      JSG has a facility of visualized teaching-learning. Class
                      rooms are equipped with smart class facility. It makes
                      teaching learning process more effective and fruitful.
                      This gadget plays an important role to make the concepts
                      more clear to the students. With iPad, teachers can
                      personalize settings for every student. For example,
                      teachers can use the Guided Access feature to set time
                      limits for students when using an app, lock them into a
                      specific app, and make certain features inactive.
                    </p>
                  </div>
                  <div class="thumbnail" style={{ marginRight: "0" }}>
                    <img
                      style={{
                        width: "100%",
                        height: "270px",
                        borderRadius: "10px",
                      }}
                      src={teachingwithipad}
                      alt="Blog Images"
                    />
                  </div>
                </div>
              </div>

              <div
                style={{ boxShadow: "none" }}
                class="edu-blog blog-style-list amenities_main"
                data-sal-delay="150"
                data-sal="slide-up"
                data-sal-duration="800"
              >
                <div class="inner">
                  <div class="thumbnail">
                    <img
                      style={{
                        width: "100%",
                        height: "270px",
                        borderRadius: "10px",
                      }}
                      src={internetwithwifi}
                      alt="Blog Images"
                    />
                  </div>
                  <div class="content" style={{ textAlign: "justify" }}>
                    <h4 class="title">Internet and Wi-Fi Facility</h4>
                    <p>
                      At the time of this globalization, we need to be connected
                      with the whole word to know the updates and latest
                      happenings. We have made the world within our reach 24X7
                      by providing Wi-Fi and Internet facility. This facility is
                      available to whole school family and can access it any
                      time as per their need to make their educational
                      approaches remarkable.It allows them to stay up-to-date on
                      their studies, access online materials, and stay connected
                      with fellow students. WiFi is also growing in importance
                      as mobile technology takes off among students. Students
                      are able to access their school's network and resources
                      from virtually anywhere with a mobile phone.
                    </p>
                  </div>
                </div>
              </div>

              <div
                style={{ boxShadow: "none" }}
                class="edu-blog blog-style-list amenities_main"
                data-sal-delay="150"
                data-sal="slide-up"
                data-sal-duration="800"
              >
                <div class="inner">
                  <div
                    class="content"
                    style={{ textAlign: "justify", paddingRight: "40px" }}
                  >
                    <h4 class="title">Play Grounds</h4>
                    <p>
                      School has a huge campus of 15 Acres. It has a facility of
                      different outdoor and indoor games. Separate play grounds
                      and courts for Cricket, Football, Volley Ball, Basket
                      Ball, Hand Ball , Badminton, Kho-Kho, Table-Tennis Carom,
                      Chess, Taekwondo, Skating etc are available in the school.
                      Playgrounds play an essential role in fostering play and
                      collaboration. In schools, children often need to
                      cooperate in a certain way to promote a healthy learning
                      environment. However, research supports that recess and
                      play are essential factors in helping children build
                      cognitive and social skills.
                    </p>
                  </div>
                  <div
                    class="thumbnail"
                    style={{
                      marginRight: "0",
                    }}
                  >
                    <img
                      style={{
                        width: "100%",
                        height: "270px",
                        borderRadius: "10px",
                      }}
                      src={playground}
                      alt="Blog Images"
                    />
                  </div>
                </div>
              </div>

              <div
                style={{ boxShadow: "none" }}
                class="edu-blog blog-style-list amenities_main"
                data-sal-delay="150"
                data-sal="slide-up"
                data-sal-duration="800"
              >
                <div class="inner">
                  <div class="thumbnail">
                    <img
                      style={{
                        width: "100%",
                        height: "270px",
                        borderRadius: "10px",
                      }}
                      src={cafeteria}
                      alt="Blog Images"
                    />
                  </div>
                  <div class="content" style={{ textAlign: "justify" }}>
                    <h4 class="title">Cafetria</h4>
                    <p>
                      School has a big dining hall and a modern kitchen with
                      latest kitchen machines. We focus on health and hygiene in
                      the kitchen area. We provide healthy food students and
                      staff. School canteen facility aims to provide children of
                      working parents to buy nutritious and healthy food at
                      competitive prices during the school day. Meals are either
                      cooked on the premises or bought from approved restaurants
                      and food outlets.
                    </p>
                  </div>
                </div>
              </div>
              <div
                style={{ boxShadow: "none" }}
                class="edu-blog blog-style-list amenities_main"
                data-sal-delay="150"
                data-sal="slide-up"
                data-sal-duration="800"
              >
                <div class="inner">
                  <div
                    class="content"
                    style={{ textAlign: "justify", paddingRight: "40px" }}
                  >
                    <h4 class="title">Bus Facility</h4>
                    <p>
                      School has a fleet of 21 buses for smooth movement of
                      students from home to school and back. Every morning, our
                      school bus is like a magic door that opens up to a
                      brand-new day. It's where we say bye to our houses and
                      families, and hello to exciting school adventures. No
                      matter how we feel – excited, sleepy, or curious – the bus
                      ride sets the tone for an awesome day ahead.
                    </p>
                  </div>
                  <div class="thumbnail" style={{ marginRight: "0" }}>
                    <img
                      style={{
                        width: "100%",
                        height: "270px",
                        borderRadius: "10px",
                      }}
                      src={schoolbus}
                      alt="Blog Images"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </div>
  );
}

export default Amenities;
