import React from "react";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import "./Facilities.css";
import img1 from "../../../assets/images/sports/jaigurudevsportsimg1.jpg";
import img2 from "../../../assets/images/sports/jaigurudevsportsimg2.jpg";
import img3 from "../../../assets/images/sports/jaigurudevsportsimg3.jpg";
import img4 from "../../../assets/images/sports/jaigurudevsportsimg4.jpg";
import img5 from "../../../assets/images/sports/jaigurudevsportsimg5.jpg";
import img6 from "../../../assets/images/sports/jaigurudevsportsimg6.jpg";

function Sport() {
  return (
    <div>
      <Header></Header>
      <div class="edu-breadcrumb-area banner_1">
        <div class="container">
          <div class="breadcrumb-inner">
            <div class="page-title">
              <h1 class="title" style={{ position: "relative", zIndex: "1" }}>
                Activities
              </h1>
            </div>
          </div>
        </div>
        <div className="about_over"></div>
      </div>
      <section class="section-gap-equal">
        <div class="container">
          <div class="row row--30">
            <div class="col-lg-12">
              <div class="amenities_head">
                <h3 class="title mb-0">Sports</h3>
                <p style={{ textAlign: "justify" }}>
                  Healthy body has healthy mind. Sports keep our body fit and
                  fine. In our school we have outdoor and indoor sports
                  facility. Also, Physical education is the integral part of
                  curriculum. This education is imparted by a qualified and
                  trained sports officer. He is also assisted by specialized
                  coaches. We provide four periods per class for sports per
                  week. Out of these four periods, two continuous periods are
                  for outdoor and two continuous periods for indoor games. Out
                  of many outdoor and indoor games/sports every child has to
                  select one from each. It is compulsory for every student to be
                  present at these classes. It is also offered as an optional
                  subject in classes XI and XII. Students get opportunity in
                  Cricket, Football, Basketball, Volleyball, Badminton and
                  Taekwondo. Besides these, many indoor games like Table Tennis,
                  Chess and Carom and Skating are also played under the valuable
                  guidance of coaches. The school participates in all the
                  competitive events organized by the State Education
                  Department, CBSE and local sports authority, and also in
                  Inter-school meets and holds several cherished running
                  trophies in Inter-school tournaments. We educate our students
                  a feeling of team spirit.
                </p>
              </div>
            </div>
            <div class="col-lg-12">
              <div class="sport_gallery">
                <div class="isotope-list gallery-grid-wrap">
                  <div id="animated-thumbnials" className="flexboxprop">
                    <a
                      href="assets/images/sports/jaigurudevsportsimg.jpg"
                      class="edu-popup-image edu-gallery-grid p-gallery-grid-wrap isotope-item education"
                    >
                      <div>
                        <img
                          className="mainimagestag"
                          src={img1}
                          alt="Gallery Image"
                        />
                      </div>
                    </a>

                    <a
                      href="assets/images/gallery/gallery-02.webp"
                      class="edu-popup-image edu-gallery-grid p-gallery-grid-wrap isotope-item marketing"
                    >
                      <div>
                        <img
                          className="mainimagestag"
                          src={img2}
                          alt="Gallery Image"
                        />
                      </div>
                    </a>

                    <a
                      href="assets/images/gallery/gallery-03.webp"
                      class="edu-popup-image edu-gallery-grid p-gallery-grid-wrap isotope-item education health"
                    >
                      <div>
                        <img
                          className="mainimagestag"
                          src={img3}
                          alt="Gallery Image"
                        />
                      </div>
                    </a>

                    <a
                      href="assets/images/gallery/gallery-04.webp"
                      class="edu-popup-image edu-gallery-grid p-gallery-grid-wrap isotope-item marketing"
                    >
                      <div>
                        <img
                          className="mainimagestag"
                          src={img4}
                          alt="Gallery Image"
                        />
                      </div>
                    </a>

                    <a
                      href="assets/images/gallery/gallery-05.webp"
                      class="edu-popup-image edu-gallery-grid p-gallery-grid-wrap isotope-item education"
                    >
                      <div>
                        <img
                          className="mainimagestag"
                          src={img5}
                          alt="Gallery Image"
                        />
                      </div>
                    </a>

                    <a
                      href="assets/images/gallery/gallery-06.webp"
                      class="edu-popup-image edu-gallery-grid p-gallery-grid-wrap isotope-item health"
                    >
                      <div>
                        <img
                          className="mainimagestag"
                          src={img6}
                          alt="Gallery Image"
                        />
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </div>
  );
}

export default Sport;
