import React, { useState } from "react";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import { Link } from "react-router-dom";
import { useRef } from "react";
import emailjs from "@emailjs/browser";
import { toast, ToastContainer } from "react-toastify";
function Contact() {
  const [dataArray, setDataArray] = useState([
    {
      name: "Jay Gurudev International School",
      number: "07162 292 949",
      email: "info@JGIS.com",
      address:
        "Isha Nagar, Parasia Road, Partala, Chhindwara, Madhya Pradesh-480001",
      mapAdd:
        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3697.6939809331625!2d78.90543587520598!3d22.061298679859128!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd565f28d567cfd%3A0x18e2aa65f881d240!2sJAI%20GURUDEV%20INTERNATIONAL%20SCHOOL!5e0!3m2!1sen!2sin!4v1688368437153!5m2!1sen!2sin",
    },
  ]);

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({
    user_name: "",
    user_email: "",
    "contact-phone": "",
    message: "",
  });

  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    // Validation logic
    const errors = {};
    const formData = new FormData(form.current);

    if (!formData.get("user_name")) {
      errors.user_name = "Name is required";
    }

    if (!formData.get("user_email")) {
      errors.user_email = "Email is required";
    }

    if (!formData.get("contact-phone")) {
      errors["contact-phone"] = "Phone number is required";
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      console.log(errors);
      return;
    }

    // If validation passes, send email
    emailjs
      .sendForm(
        "service_xil6hei",
        "template_bd57sei",
        form.current,
        "f-TdLoHUtNT2WVsj8"
      )
      .then((result) => {
        console.log(result.text);
        toast.success("Thank you for your submission!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
        setIsSubmitted(true);

        setFormErrors({});
        form.current.reset();
        setFormData({
          user_name: "",
          user_email: "",
          "contact-phone": "",
          message: "",
        });
      })
      .catch((error) => {
        console.log(error.text);
        toast.error("An error occurred. Please try again later.", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      });
  };

  return (
    <div>
      <Header></Header>
      <div class="edu-breadcrumb-area">
        <div class="container">
          <div class="breadcrumb-inner">
            <div class="page-title">
              <h1 class="title">Contact Us</h1>
            </div>
          </div>
        </div>
        <img src="assets/images/contact.jpg" class="contact_image" />
      </div>
      <section class="contact-us-area">
        <div class="container">
          <div class="row g-5">
            <div class="col-xl-4 col-lg-6">
              <div class="contact-us-info">
                <h3 class="heading-title">
                  We're Always Eager to Hear From You!
                </h3>
                {dataArray.map((item) => (
                  <ul class="address-list">
                    <li>
                      <h5 class="title">Address</h5>
                      <p>{item.address}</p>
                    </li>
                    <li>
                      <h5 class="title">Email</h5>
                      <p>
                        <a href="mailto:edublink@example.com">{item.email}</a>
                      </p>
                    </li>
                    <li>
                      <h5 class="title">Phone</h5>
                      <p>
                        <a href="tel:+0914135548598">{item.number}</a>
                      </p>
                    </li>
                  </ul>
                ))}
                <ul class="social-share">
                  <li>
                    <a href="#">
                      <i class="icon-share-alt"></i>
                    </a>
                  </li>
                  <li>
                    <Link to="https://www.facebook.com/jaigurudevinternationalschool">
                      <i class="icon-facebook"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="https://instagram.com/jai_gurudev_inter_school?igshid=NTc4MTIwNjQ2YQ==">
                      <i class="icon-instagram"></i>
                    </Link>
                  </li>
                  <li>
                    <Link to="https://www.youtube.com/@jaigurudevinternationalsch9606/featured">
                      <i class="icon-youtube"></i>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div class="offset-xl-2 col-lg-6">
              <div class="contact-form form-style-2">
                <div class="section-title">
                  <h4 class="title">Get In Touch</h4>
                  <p>
                    Fill out this form for booking a consultant advising
                    session.
                  </p>
                </div>
                <form
                  ref={form}
                  onSubmit={sendEmail}
                  class="rnt-contact-form rwt-dynamic-form"
                  id="contact-form"
                  method="POST"
                  action="https://edublink.html.devsblink.com/mail.php"
                >
                  <div class="row row--10">
                    <div class="form-group col-12">
                      <input
                        type="text"
                        name="user_name"
                        id="contact-name"
                        placeholder="Your name"
                      />
                      {formErrors.user_name && (
                        <div className="error-message">
                          {formErrors.user_name}
                        </div>
                      )}
                    </div>
                    <div class="form-group col-12">
                      <input
                        type="email"
                        name="user_email"
                        id="contact-email"
                        placeholder="Enter your email"
                      />
                      {formErrors.user_email && (
                        <div className="error-message">
                          {formErrors.user_email}
                        </div>
                      )}
                    </div>
                    <div class="form-group col-12">
                      <input
                        type="tel"
                        name="contact-phone"
                        id="contact-phone"
                        placeholder="Phone number"
                      />
                      {formErrors["contact-phone"] && (
                        <div className="error-message">
                          {formErrors["contact-phone"]}
                        </div>
                      )}
                    </div>
                    <div class="form-group col-12">
                      <textarea
                        name="message"
                        id="contact-message"
                        cols="30"
                        rows="4"
                        placeholder="Your message"
                      ></textarea>
                    </div>
                    <div class="form-group col-12">
                      <button
                        class="rn-btn edu-btn btn-medium submit-btn"
                        name="submit"
                        type="submit"
                      >
                        Submit Message <i class="icon-4"></i>
                      </button>
                    </div>
                  </div>
                </form>
                <ul class="shape-group">
                  <li class="shape-1 scene">
                    <img
                      data-depth="1"
                      src="assets/images/about/shape-13.png"
                      alt="Shape"
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="google-map-area">
        <div class="mapouter">
          <div class="gmap_canvas">
            {dataArray.map((i) => (
              <iframe
                id="gmap_canvas"
                src={i.mapAdd}
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
              ></iframe>
            ))}
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default Contact;
