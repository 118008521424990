import React, { useState } from "react";
import { Link } from "react-router-dom";
import Aboutschooimg from "../../assets/images/course/Aboutschooimg.jpg";
import "./AboutHome.css";
function AboutHome() {
  const [about, setAbout] = useState([
    {
      deteils:
        "The school comes with an uncompromising commitment. It aims to achieve specific, measurable, observable and quantifiable results among all aspirants/students. Because the School has a vision to provide value based education to young minds and provide a dynamic learning environment.",
      deteils1:
        "Jai Gurudev International School Partala Chhindwara is one of Best CBSE Schools in Chhindwara therefore it comes with a systematic, simple registration and admission process. It also accepts online payments and an online admission enquiry. Moreover, for enquiry on more procedure contact school through Online Admission Form",
    },
  ]);

  return (
    <div>
      {" "}
      <div className="gap-bottom-equal edu-about-area about-style-1">
        {about.map((item) => (
          <div className="container JGIS-animated-shape">
            <div className="row g-5 align-items-center flexabouthome">
              <div className="col-lg-6">
                <div
                  className="about-image-gallery aboutimggallery"
                  style={{ height: "600px" }}
                >
                  <img
                    className="aboutimagehome"
                    src={Aboutschooimg}
                    alt="About Image"
                  />
                  {/** <ul className="shape-group">
                    <li
                      className="shape-2 scene"
                      data-sal-delay={500}
                      data-sal="fade"
                      data-sal-duration={200}
                    >
                      <img
                        data-depth={-1}
                        src="assets/images/about/shape-37.png"
                        alt="Shape"
                      />
                    </li>
                    <li
                      className="shape-3 scene"
                      data-sal-delay={500}
                      data-sal="fade"
                      data-sal-duration={200}
                    >
                      <img
                        data-depth={1}
                        src="assets/images/about/shape-02.png"
                        alt="Shape"
                      />
                    </li>
                  </ul> */}
                </div>
              </div>
              <div
                className="col-lg-6"
                data-sal-delay={150}
                data-sal="slide-left"
                data-sal-duration={800}
              >
                <div className="about-content">
                  <div className="section-title section-left">
                    <span className="pre-title">About Us</span>
                    <h2 className="title">
                      Jay Gurudev
                      <span className="color-secondary mx-2">
                        International{" "}
                      </span>{" "}
                      School
                    </h2>
                    <span className="shape-line">
                      <i className="icon-19" />
                    </span>
                    <p>{item.deteils}</p>
                    <p>{item.deteils1}</p>
                  </div>
                  <ul className="features-list">
                    <Link to="/overview">
                      {" "}
                      <li>Read more</li>
                    </Link>
                  </ul>
                </div>
              </div>
            </div>
            <ul className="shape-group">
              <li
                className="shape-1 circle scene"
                data-sal-delay={500}
                data-sal="fade"
                data-sal-duration={200}
              >
                <span data-depth="-2.3" />
              </li>
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AboutHome;
