import React, { useEffect, useState } from 'react';
import ApiEndPoints from '../../NetworkCall/ApiEndPoint';
import ApiServices from '../../NetworkCall/ApiServices';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
function NewsEvent() {


  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const [title, setTitle] = useState('');
  const [eventDate, setEventDate] = useState('');
  const [description, setDescription] = useState('');
 const [event , setEvent] = useState([]);
 

const Get_news=()=>{
  ApiServices.get(ApiEndPoints.getNews)
  .then((response) => {
    if (response.status.code === 200) {
      console.log(response);
      setEvent(response.data.newsData        )
    }
  })
  .catch((error) => {
    console.log('Error fetching banner images:', error);
  });
}

useEffect(()=>{
  Get_news();
},[])


const handleCreateNewsEvent = () => {
  const payload = {
    title: title,
    date: eventDate,
    description: description,
  };

  ApiServices.post(ApiEndPoints.CreateNews, payload)
    .then((response) => {
      if (response?.status?.code === 200) {
        console.log(response);
        setTitle("");
        setEventDate("");
        setDescription("");
      } else {
        console.log('Error creating news event:', response);
      }
    })
    .catch((error) => {
      console.log('Error creating news event:', error);
    });
};



  return (

    

    <div>
         <div className="edu-blog-area blog-area-1 edu-section-gap">
          <div className="container">
            <div
              className="section-title section-center"
              data-sal-delay={100}
              data-sal="slide-up"
              data-sal-duration={800}
            >
              <span className="pre-title">Latest Articles</span>
              <h2 className="title">Get News with JGIS</h2>
              <span className="shape-line">
                <i className="icon-19" />
              </span>
            </div>
            <div style={{ width: "800px ", margin: "0 auto" }}>
        <Carousel responsive={responsive}>
{event.map((item)=>
          (<div
            style={{
              padding: "20px 35px",
              backgroundColor: "#fff",
              margin: "20px",
              borderRadius: "20px",
              border: "1px solid green",
            }}
          >
            <div className="">
              <a href="#" className="blog-category">
                {item.title}
              </a>
            </div>
            <h5 className="title">
              <a href="#"></a>
            </h5>
            <ul className="blog-meta">
              <li>
                <i className="icon-27" />
                {item.date}
              </li>
            </ul>
            <p>{item.description}</p>
          </div>
          
))}
        </Carousel>
        </div>
          </div>
          
          <ul className="shape-group">
            <li className="shape-1 scene">
              <img
                data-depth="-1.4"
                src="assets/images/about/shape-02.png"
                alt="Shape"
              />
            </li>
            <li className="shape-2 scene">
              <span data-depth="2.5" />
            </li>
            <li className="shape-3 scene">
              <img
                data-depth="-2.3"
                src="assets/images/counterup/shape-05.png"
                alt="Shape"
              />
            </li>
          </ul>
        </div>
        {/*
        <input
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        placeholder="Title"
      />
      <input
        value={eventDate}
        onChange={(e) => setEventDate(e.target.value)}
        placeholder="Date"
      />
      <input
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        placeholder="Description"
      />
          <button onClick={handleCreateNewsEvent}>Save</button> * */}
    </div>
  )
}

export default NewsEvent