import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ApiEndPoints from '../../../NetworkCall/ApiEndPoint';
import ApiServices from '../../../NetworkCall/ApiServices';
import Footer from '../../common/Footer';
import Header from '../../common/Header';

function CategoryPage() {
  const { categoryName } = useParams();
  const [categoryImages, setCategoryImages] = useState([]);
  const [imageSize, setImageSize] = useState("normal"); // "normal" or "increased"
  const [showNavigation, setShowNavigation] = useState(true);

  const fetchCategoryImages = async () => {
    try {
      const payload = { category: categoryName };
      const response = await ApiServices.post(ApiEndPoints.getPhoto, payload);
      if (response.status.code === 200) {
        setCategoryImages(response.data.photoData);
        console.log(response.data.photoData);
      }
    } catch (error) {
      console.log('Error fetching category images:', error);
    }
  };

  useEffect(() => {
    fetchCategoryImages();
  }, [categoryName]);

  const handleDeleteBanner = (bannerId) => {
    ApiServices.delete(ApiEndPoints.DeltePhoto + bannerId)
      .then((response) => {
        if (response.status.code === 200) {
          console.log('Banner deleted successfully');
          fetchCategoryImages(); // Refresh the images after successful deletion
        } else {
          console.log('Error deleting banner:', response);
        }
      })
      .catch((error) => {
        console.log('Error deleting banner:', error);
      });
  };
 
  return (
    <div>
      <Header></Header>
       <div>
        <div className="home-one-cta-two cta-area-1 banner_1">
          <div className="container">
            <div className="row text-center">
              <div className="col-md-12">
                <div className="about_over" />
                <div className="about_school">
                  <h1>Gallery</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>

    <div className="missionandvision-content-contaioner">
        <div class="edu-course course-style-4 course-style-14">
          <div class="inner">
            <div class="content ">
              <div className="inner-content">
                <h2> <h1> {categoryName}</h1></h2>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "90%",
                      columnGap: "30px",
                      rowGap: "30px",
                      flexWrap: "wrap",
                      textAlign: "center",
                    }}
                  >
                    {categoryImages.map((image) => (
                      <div>
                        <li
                          style={{
                            width: imageSize === "normal" ? "300px" : "600px",
                            height: imageSize === "normal" ? "200px" : "400px",
                            border: "2px solid grey",
                            padding: "15px",
                            listStyle: "none",
                            textDecoration: "none",
                          }}
                          onClick={() => {
                            setImageSize(imageSize === "normal" ? "increased" : "normal");
                            setShowNavigation(false);
                          }}
                        >
                          <img
                            style={{
                              width: "100%",
                              height: "100%",
                              border: "2px solid",
                              cursor: "pointer",
                            }}
                            src={image.photoImgUrl}
                            alt=""
                          />
                        </li>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
        </div>
      </div>

      {!showNavigation && (
        <div
          style={{
            position: "fixed",
            top: "20px",
            right: "20px",
            zIndex: "9999",
          }}
        >
          <button
            style={{
              border: "none",
              background: "none",
              cursor: "pointer",
              fontSize: "24px",
              color: "red",
            }}
            onClick={() => {
              setImageSize("normal");
              setShowNavigation(true);
            }}
          >
            &#10006;
          </button>
        </div>
      )}

     <Footer></Footer> 
    </div>
  );
}

export default CategoryPage;
