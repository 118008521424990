import React, { useState, useEffect } from "react";
import "./Carousel.css";
import ExploreSchool from "./ExploreSchool";
import AboutHome from "./AboutHome";
import DiscoverSchool from "./DiscoverSchool";
import Recentactivity from "./Recentactivity";
import NewsEvent from "./NewsEvent";
import Slider from "./Slider";
import ApiEndPoints from "../../NetworkCall/ApiEndPoint";
import ApiServices from "../../NetworkCall/ApiServices";
import Header from "../common/Header";
import Footer from "../common/Footer";
import BackToTop from "../common/BackToTop";

import CampusTour from "../CampusTour/CampustTour";
import Schoolinfo from "./SchoolInfo";
import AdvertisementPopup from "./Advertisementpopup/AdvertisementPopup";
//import Preloader from "../common/Preloader";
const HompPage = () => {
  return (
    <>
      <Header></Header>

      <Slider />

      <ExploreSchool />
      <AboutHome />
      <DiscoverSchool />
      <Schoolinfo />
      <NewsEvent />
      <CampusTour />
      <BackToTop></BackToTop>
      <Footer></Footer>
    </>
  );
};

export default HompPage;
//<Preloader></Preloader>
