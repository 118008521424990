import React from "react";
import { Link } from "react-router-dom";

function ExploreSchool() {
  return (
    <div>
      <div className="edu-categorie-area categorie-area-2 edu-section-gap">
        <div className="container">
          <div
            className="section-title section-center"
            data-sal-delay={150}
            data-sal="slide-up"
            data-sal-duration={800}
          >
            <h2 className="title">Explore our School</h2>
            <span className="shape-line">
              <i className="icon-19" />
            </span>
            <p>
              Education is the passport to the future, for tomorrow belongs to
              those who prepare for it today.
            </p>
          </div>
          <div className="row g-5">
            <div
              className="col-lg-4 col-md-6"
              data-sal-delay={50}
              data-sal="slide-up"
              data-sal-duration={800}
            >
              <div className="categorie-grid categorie-style-2 color-primary-style JGIS-svg-animate">
                <div className="icon">
                  <i className="icon-9" />
                </div>
                <div className="content">
                  <Link to="/sport">
                    <h5 className="title">Sports &amp; Co-Curricular</h5>
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6"
              data-sal-delay={100}
              data-sal="slide-up"
              data-sal-duration={800}
            >
              <div className="categorie-grid categorie-style-2 color-secondary-style">
                <div className="icon">
                  <i className="icon-10 art-design" />
                </div>
                <div className="content">
                  <Link to="/musicDrama">
                    <h5 className="title">Arts &amp; Design</h5>
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6"
              data-sal-delay={150}
              data-sal="slide-up"
              data-sal-duration={800}
            >
              <div className="categorie-grid categorie-style-2 color-extra01-style">
                <div className="icon">
                  <i className="icon-13 data-science" />
                </div>
                <div className="content">
                  <Link to="/highersecondry">
                    <h5 className="title">Academics</h5>
                  </Link>
                </div>
              </div>
            </div>
            {/*
              <div
                className="col-lg-4 col-md-6"
                data-sal-delay={50}
                data-sal="slide-up"
                data-sal-duration={800}
              >
                <div className="categorie-grid categorie-style-2 color-tertiary-style">
                  <div className="icon">
                  <i class="fa fa-comments" aria-hidden="true"/>

                  </div>
                  <div className="content">
                    <Link href="#">
                      <h5 className="title">Internationalism</h5>
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6"
                data-sal-delay={100}
                data-sal="slide-up"
                data-sal-duration={800}
              >
                <div className="categorie-grid categorie-style-2 color-extra02-style">
                  <div className="icon">
                    <i className="icon-13 data-science" />
                  </div>
                  <div className="content">
                 
                    <Link href="#">
                      <h5 className="title">Personal Development</h5>
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="col-lg-4 col-md-6"
                data-sal-delay={150}
                data-sal="slide-up"
                data-sal-duration={800}
              >
                <div className="categorie-grid categorie-style-2 color-extra03-style">
                  <div className="icon">
                    <i className="icon-14" />
                  </div>
                  <div className="content">
                    <Link href="#">
                      <h5 className="title">Boarding</h5>
                    </Link>
                  </div>
                </div>
              </div>
               */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExploreSchool;
