import React, { useState, useEffect, useRef } from "react";
import ApiEndPoints from "../../NetworkCall/ApiEndPoint";
import ApiServices from "../../NetworkCall/ApiServices";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import "./AdminCommon.css";

function AdminBanner() {
  const [images, setImages] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const inputFileReels = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [savingImage, setSavingImage] = useState(false);
  const [uploadedImageUrl, setUploadedImageUrl] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);
  const [showDeleteOptions, setShowDeleteOptions] = useState(false);

  const fetchImages = () => {
    ApiServices.get(ApiEndPoints.getBanner)
      .then((response) => {
        if (response.status.code === 200) {
          setImages(response.data.bannerData);
          console.log(response);
        }
      })
      .catch((error) => {
        console.log("Error fetching banner images:", error);
      });
  };

  useEffect(() => {
    fetchImages();
  }, []);

  const handleBannerImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file);
      setUploadedImageUrl(URL.createObjectURL(file)); // Set the uploaded image URL
    }
  };

  const handleAddImage = () => {
    inputFileReels.current.click();
  };

  const handleCreateBanner = () => {
    if (selectedImage) {
      setSavingImage(true); // Show loading state
      const formData = new FormData();
      formData.append("bannerimg", selectedImage, selectedImage.name);

      ApiServices.post(ApiEndPoints.CreateBanner, formData)
        .then((response) => {
          if (response?.status?.code === 200) {
            console.log(response);
            fetchImages(); // Refresh the images after successful creation
          } else {
            console.log("Error creating banner:", response);
          }
        })
        .catch((error) => {
          console.log("Error creating banner:", error);
        })
        .finally(() => {
          setSavingImage(false); // Hide loading state
          setSelectedImage(null); // Reset selectedImage after successful save
          setUploadedImageUrl(null); // Reset the uploaded image URL
          // Reset the value of the file input
          if (inputFileReels.current) {
            inputFileReels.current.value = "";
          }
        });
    }
  };

  const handleDeleteBanner = (bannerId) => {
    ApiServices.delete(ApiEndPoints.deleteBanner + bannerId)
      .then((response) => {
        if (response.status.code === 200) {
          console.log("Banner deleted successfully");
          fetchImages(); // Refresh the images after successful deletion
        } else {
          console.log("Error deleting banner:", response);
        }
      })
      .catch((error) => {
        console.log("Error deleting banner:", error);
      });
  };

  const handleImageSelection = (imageId) => {
    if (selectedImages.includes(imageId)) {
      setSelectedImages(selectedImages.filter((id) => id !== imageId));
    } else {
      setSelectedImages([...selectedImages, imageId]);
    }
  };

  const handleDeleteSelected = () => {
    selectedImages.forEach((imageId) => {
      handleDeleteBanner(imageId);
    });
    setSelectedImages([]);
    setShowDeleteOptions(false);
  };

  const handleDeleteAll = () => {
    images.forEach((image) => {
      handleDeleteBanner(image._id);
    });
    setSelectedImages([]);
    setShowDeleteOptions(false);
  };

  return (
    <div>
      <div>
        <div
          className="missionandvision-content-contaioner"
          style={{ width: "100%" }}
        >
          <div class="edu-course course-style-4 course-style-14">
            <div class="inner">
              <div class="content ">
                <div className="">
                  <div className="my-5 mx-5">
                    <h2>Add Banners</h2>
                  </div>
                  <div className="mx-5 my-5">
                    {/** <h2>Banner Image</h2> */}
                    <div className="d-flex flextdirection">
                      <div>
                        <input
                          placeholder="Click here"
                          type="file"
                          accept="images/*"
                          onChange={handleBannerImage}
                          ref={inputFileReels}
                        />
                      </div>

                      <button
                        onClick={handleAddImage}
                        style={{ display: "none" }}
                      >
                        Select Image
                        <div></div>
                      </button>
                      <div>
                        <button
                          style={{}}
                          className="btn btn-primary btn-lg"
                          onClick={handleCreateBanner}
                          disabled={!selectedImage || savingImage}
                        >
                          {savingImage ? "Saving Image..." : "Save Image"}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div>
                    {!showDeleteOptions && selectedImages.length > 0 && (
                      <button
                        style={{
                          padding: "13px",
                          fontSize: "15px",
                        }}
                        onClick={() => setShowDeleteOptions(true)}
                      >
                        Delete Images
                      </button>
                    )}
                    {showDeleteOptions && (
                      <div>
                        <button
                          style={{
                            color: "#ffffff",
                            padding: "13px",
                            fontSize: "15px",
                            marginRight: "10px",
                          }}
                          onClick={handleDeleteSelected}
                        >
                          Delete Selected
                        </button>
                        <button
                          style={{
                            color: "#ffffff",
                            padding: "13px",
                            fontSize: "15px",
                          }}
                          onClick={handleDeleteAll}
                        >
                          Delete All
                        </button>
                      </div>
                    )}
                  </div>{" "}
                  <div className="my-5 mx-5">
                    <h2>Banner images</h2>
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        gap: "30px",
                        width: "80%",
                        flexWrap: "wrap",
                        textAlign: "center",
                      }}
                    >
                      {images.map((image) => (
                        <div
                          key={image._id}
                          style={{
                            textAlign: "right",
                            position: "relative",
                          }}
                        >
                          <li
                            style={{
                              width: "300px",
                              height: "200px",
                              marginBottom: "15px",
                              borderRadius: "10px",
                              listStyle: "none",
                              textDecoration: "none",
                              backgroundColor: selectedImages.includes(
                                image._id
                              )
                                ? "#000000"
                                : "#ffffff",
                            }}
                            onClick={() => handleImageSelection(image._id)}
                          >
                            <img
                              style={{
                                width: "100%",
                                height: "100%",
                                borderRadius: "10px",
                              }}
                              src={image.bannerImgUrl}
                              alt=""
                            />
                          </li>
                          {selectedImages.includes(image._id) && (
                            <button
                              style={{
                                position: "absolute",
                                top: "5px",
                                right: "5px",
                                border: "none",
                                backgroundColor: "transparent",
                                color: "white",
                                border: "none",
                                fontWeight: "bold",
                              }}
                              onClick={() => handleDeleteBanner(image._id)}
                            >
                              Selcted
                            </button>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminBanner;
