import React from 'react'
import Footer from '../../common/Footer'
import Header from '../../common/Header'
function HigherSceondry() {
  return (
    <div>
      <Header></Header>
            <div class="edu-breadcrumb-area breadcrumb-style-2 bg-image bg-image--19">
        <div class="container">
          <div class="breadcrumb-inner">
            <div class="page-title">
              <h1 class="title">Academics</h1>
            </div>
          </div>
        </div>
      </div>
      <div class="blog-details-area section-gap-equal">
        <div class="container">
          <div class="row row--30">
            <div class="col-lg-12">
              <div class="blog-details-content foun_stage_main">
                <div class="entry-content">
                  <h3 class="title">Secondary Stage</h3>
                  <div class="thumbnail">
                    <img src="assets/images/blog/blog-large-1.jpg" alt="Blog Image" />
                  </div>
                </div>
                <p>In XI and XII Classes, we have Science Stream ( Mathematics & Biology Group) and Commerce Stream.</p>
              </div>
              <div class="course-overview">
                <div class="border-box">
                  <h5 class="title" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">What You’ll Learn?</h5>
                  <div class="row g-5">
                    <div class="col-lg-6" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                      <ul>
                        <li>
SCIENCE – MATHEMATICS	SCIENCE – BIOLOGY
Biology	Applied Mathematics
Physical Education	Physical Education
Computer Science	Computer Science
Informatics Practices	Informatics Practices
Painting	Painting
Financial Marketing Management	Financial Marketing Management</li>
                      </ul>
                    </div>
                    <div class="col-lg-6" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                      <ul>
                        <li>COMMERCE	HUMANITIES
Mathematics	Mathematics
Physical Education	Physical Education
Computer Science	Computer Science
Informatics Practices	Informatics Practices
Painting	Painting
Financial Marketing Management	Financial Marketing Management</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="second_crs tbl">
                <div class="table-responsive">
                  <table class="table cart-table">
                    <thead>
                      <tr>
                        <th scope="col" class="product-title">SCIENCE – MATHEMATICS</th>
                        <th scope="col" class="product-price">SCIENCE – BIOLOGY</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="product-title">
                          <span>Biology</span>
                        </td>
                        <td class="product-price" data-title="Price">
                          <span>Applied Mathematics</span>
                        </td>
                      </tr>
                      <tr>
                        <td class="product-title">
                          <span>Biology</span>
                        </td>
                        <td class="product-price" data-title="Price">
                          <span>Applied Mathematics</span>
                        </td>
                      </tr>
                      <tr>
                        <td class="product-title">
                          <span>Biology</span>
                        </td>
                        <td class="product-price" data-title="Price">
                          <span>Applied Mathematics</span>
                        </td>
                      </tr>
                      <tr>
                        <td class="product-title">
                          <span>Biology</span>
                        </td>
                        <td class="product-price" data-title="Price">
                          <span>Applied Mathematics</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default HigherSceondry