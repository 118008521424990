import React, { useState, useRef, useEffect } from "react";
import ApiServices from "../../NetworkCall/ApiServices";
import ApiEndPoints from "../../NetworkCall/ApiEndPoint";
import { useParams } from "react-router-dom";
import AdminNavbar from "./AdminNavbar";
import AdminSidebar from "./AdminSidebar";

function AdminPhotoCategroy() {
  const { categoryName } = useParams();
  const [categoryImages, setCategoryImages] = useState([]);

  const fetchCategoryImages = async () => {
    try {
      const payload = { category: categoryName };
      const response = await ApiServices.post(ApiEndPoints.getPhoto, payload);
      if (response.status.code === 200) {
        setCategoryImages(response.data.photoData);
        console.log(response.data.photoData);
      }
    } catch (error) {
      console.log("Error fetching category images:", error);
    }
  };

  useEffect(() => {
    fetchCategoryImages();
  }, [categoryName]);

  const handleDeleteBanner = (bannerId) => {
    ApiServices.delete(ApiEndPoints.DeltePhoto + bannerId)
      .then((response) => {
        if (response.status.code === 200) {
          console.log("Banner deleted successfully");
          fetchCategoryImages(); // Refresh the images after successful deletion
        } else {
          console.log("Error deleting banner:", response);
        }
      })
      .catch((error) => {
        console.log("Error deleting banner:", error);
      });
  };

  return (
    <div>
      <AdminNavbar />
      <div className="d-flex">
        <div style={{ marginTop: "60px" }}>
          <AdminSidebar />
        </div>

        <div
          className="missionandvision-content-contaioner"
          style={{ marginTop: "60px", width: "100%" }}
        >
          <div class="edu-course course-style-4 course-style-14">
            <div class="inner">
              <div class="content ">
                <div className="inner-content">
                  <h2>
                    {" "}
                    <h1 className="mx-4 my-4"> {categoryName}</h1>
                  </h2>
                  <div style={{ display: "flex", justifyContent: " center" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",

                        columnGap: "30px",
                        rowGap: "30px",
                        flexWrap: "wrap",
                        textAlign: "center",
                      }}
                    >
                      {categoryImages.map((image) => (
                        <>
                          <div style={{ textAlign: "right" }}>
                            <li
                              style={{
                                width: "300px",
                                height: "200px",
                                marginBottom: "15px",
                                borderRadius: "10px",
                                listStyle: "none",
                                textDecoration: "none",
                              }}
                            >
                              <img
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  borderRadius: "10px",
                                }}
                                src={image.photoImgUrl}
                                alt=""
                              />
                            </li>
                            <button
                              style={{
                                border: "none",
                                padding: "7px 20px",
                                borderRadius: " 5px",
                                backgroundColor: "#000000",
                                color: "#ffffff",
                              }}
                              onClick={() => handleDeleteBanner(image._id)}
                            >
                              Delete
                            </button>
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminPhotoCategroy;