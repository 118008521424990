import React, { useState, useEffect, useRef } from "react";
import "./Carousel.css";
import ApiEndPoints from "../../NetworkCall/ApiEndPoint";
import ApiServices from "../../NetworkCall/ApiServices";
import AdvertisementPopup from "./Advertisementpopup/AdvertisementPopup";
import img1 from "../../assets/images/slider/schoolimage.jpg";
import img2 from "../../assets/images/slider/schoolimage1.jpg";
function Slider() {
  const [images, setImages] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const inputFileReels = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [savingImage, setSavingImage] = useState(false);

  const goToPrevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? images.length - 1 : prevSlide - 1
    );
  };

  const goToNextSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === images.length - 1 ? 0 : prevSlide + 1
    );
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const fetchImages = () => {
    ApiServices.get(ApiEndPoints.getBanner)
      .then((response) => {
        if (response.status.code === 200) {
          setImages(response.data.bannerData);
          console.log(response);
        }
      })
      .catch((error) => {
        console.log("Error fetching banner images:", error);
      });
  };

  useEffect(() => {
    fetchImages();
  }, []);

  const handleBannerImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file);
    }
  };

  const handleAddImage = () => {
    inputFileReels.current.click();
  };

  const handleCreateBanner = () => {
    if (selectedImage) {
      setSavingImage(true); // Show loading state
      const formData = new FormData();
      formData.append("bannerimg", selectedImage, selectedImage.name);

      ApiServices.post(ApiEndPoints.CreateBanner, formData)
        .then((response) => {
          if (response?.status?.code === 200) {
            console.log(response);
            fetchImages(); // Refresh the images after successful creation
          } else {
            console.log("Error creating banner:", response);
          }
        })
        .catch((error) => {
          console.log("Error creating banner:", error);
        })
        .finally(() => {
          setSavingImage(false); // Hide loading state
          setSelectedImage(null); // Reset selectedImage after successful save
        });
    }
  };

  const handleDeleteBanner = (bannerId) => {
    ApiServices.delete(ApiEndPoints.deleteBanner + bannerId)
      .then((response) => {
        if (response.status.code === 200) {
          console.log("Banner deleted successfully");
          fetchImages(); // Refresh the images after successful deletion
        } else {
          console.log("Error deleting banner:", response);
        }
      })
      .catch((error) => {
        console.log("Error deleting banner:", error);
      });
  };

  return (
    <div>
      <div style={{ position: "relative" }}>
        {" "}
        <AdvertisementPopup></AdvertisementPopup>
      </div>
      <div className="hero-banner hero-style-1">
        <div id="carouselExampleIndicators" class="carousel slide">
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img
                style={{ maxHeight: "700px" }}
                src={img1}
                class="d-block w-100"
                alt="..."
              />
            </div>
            <div class="carousel-item">
              <img
                style={{ maxHeight: "700px" }}
                src={img2}
                class="d-block w-100"
                alt="..."
              />
            </div>
          </div>
          <button
            class="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev"
          >
            <span
              style={{ fontSize: "25px", fontWeight: "bold" }}
              class="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button
            class="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>

        {/* <div className="carousel">
          <div
            className="slides"
            style={{
              transform: `translateX(-${currentSlide * windowWidth}px)`,
            }}
          >
            {images &&
              images.map((image, index) => (
                <div
                  key={index}
                  className="slide"
                  style={{ width: windowWidth }}
                >
                  <img
                    src={image.bannerImgUrl}
                    alt={`Slide ${index + 1}`}
                    style={{ maxHeight: "100vh" }}
                  />
                  <button
                    onClick={() => handleDeleteBanner(image._id)}
                    style={{ display: "none" }}
                  >
                    Delete
                  </button>
                </div>
              ))}
          </div>
          <input
            placeholder="Click here"
            type="file"
            accept="images/*"
            onChange={handleBannerImage}
            ref={inputFileReels}
            style={{ display: "none" }}
          />
          <button onClick={handleAddImage} style={{ display: "none" }}>
            Select Image
          </button>
          <button
            onClick={handleCreateBanner}
            disabled={!selectedImage || savingImage}
            style={{ display: "none" }}
          >
            {savingImage ? "Saving Image..." : "Save Image"}
          </button>
          <button
            className="prev-btn"
            onClick={goToPrevSlide}
            style={{ backgroundColor: "black" }}
          >
            &lt;
          </button>
          <button
            className="next-btn"
            onClick={goToNextSlide}
            style={{ backgroundColor: "black" }}
          >
            &gt;
          </button>
        </div> */}
      </div>
    </div>
  );
}

export default Slider;
