import React, { useState, useRef, useEffect } from "react";
import ApiServices from "../../NetworkCall/ApiServices";
import ApiEndPoints from "../../NetworkCall/ApiEndPoint";
import AdminNavbar from "./AdminNavbar";
import AdminSidebar from "./AdminSidebar";
function AdminPressUpload() {
  const inputFileReels = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [savingImage, setSavingImage] = useState(false);
  const [PressData, setPressData] = useState([]);
  const [pressUrl, setPressUrl] = useState("");
  const fetchImages = () => {
    ApiServices.get(ApiEndPoints.getPress)
      .then((response) => {
        if (response.status.code === 200) {
          setPressData(response.data.pressData);
        }
      })
      .catch((error) => {
        console.log("Error fetching banner images:", error);
      });
  };

  useEffect(() => {
    fetchImages();
  }, []);

  const handleBannerImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file);
      setPressUrl(URL.createObjectURL(file));
    }
  };

  const handleAddImage = () => {
    inputFileReels.current.click();
  };

  const handleCreatePress = () => {
    if (selectedImage) {
      setSavingImage(true); // Show loading state
      const formData = new FormData();
      formData.append("pressimg", selectedImage, selectedImage.name);

      ApiServices.post(ApiEndPoints.CreatePress, formData)
        .then((response) => {
          if (response?.status?.code === 200) {
            console.log(response);
            fetchImages(); // Refresh the images after successful creation
          } else {
            console.log("Error creating banner:", response);
          }
        })
        .catch((error) => {
          console.log("Error creating banner:", error);
        })
        .finally(() => {
          setSavingImage(false); // Hide loading state
          setSelectedImage(null); // Reset selectedImage after successful save
          setPressUrl(null);

          if (inputFileReels.current) {
            inputFileReels.current.value = "";
          }
        });
    }
  };

  const handleDeletePress = (bannerId) => {
    ApiServices.delete(ApiEndPoints.DeletePress + bannerId)
      .then((response) => {
        if (response.status.code === 200) {
          console.log("Banner deleted successfully");
          fetchImages(); // Refresh the images after successful deletion
        } else {
          console.log("Error deleting banner:", response);
        }
      })
      .catch((error) => {
        console.log("Error deleting banner:", error);
      });
  };

  return (
    <>
      <AdminNavbar />
      <div className="d-flex" style={{ position: "relative" }}>
        <div style={{ marginTop: "60px", position: "fixed", zIndex: "1" }}>
          <AdminSidebar />
        </div>
        <div
          className="pressuploadposition"
          style={{
            marginTop: "60px",
            position: "absolute",

            width: "100%",
          }}
        >
          <div>
            <div></div>

            <div className="missionandvision-content-contaioner mx-5">
              <div class="edu-course course-style-4 course-style-14">
                <div class="inner">
                  <div class="content ">
                    <div className="mx-5 my-5">
                      <h2> Add Press Photos</h2>
                      <div className="d-flex">
                        <div>
                          <input
                            placeholder="Click here"
                            type="file"
                            accept="images/*"
                            onChange={handleBannerImage}
                            ref={inputFileReels}
                          />
                        </div>

                        <button
                          onClick={handleAddImage}
                          style={{ display: "none" }}
                        >
                          Select Image
                          <div></div>
                        </button>
                        <div>
                          <button
                            className="btn btn-primary btn-lg"
                            onClick={handleCreatePress}
                            disabled={!selectedImage || savingImage}
                          >
                            {savingImage ? "Saving Image..." : "Save Image"}
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="mx-5 my-5">
                      <h2>Press Gallery</h2>
                    </div>
                    <div className="inner-content">
                      <div style={{}}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            width: "80%",
                            columnGap: "30px",
                            rowGap: "30px",
                            flexWrap: "wrap",
                            textAlign: "center",
                          }}
                        >
                          {PressData.map((image) => (
                            <>
                              <div>
                                <li
                                  style={{
                                    width: "300px",
                                    height: "200px",
                                    borderRadius: "10px",
                                    listStyle: "none",
                                    textDecoration: "none",
                                  }}
                                >
                                  <img
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      borderRadius: "10px",
                                    }}
                                    src={image.pressUrl}
                                    alt=""
                                  />
                                </li>
                                <div
                                  style={{
                                    marginTop: "15px",
                                    textAlign: "right",
                                  }}
                                >
                                  <button
                                    style={{
                                      border: "none",
                                      padding: "7px 20px",
                                      borderRadius: " 5px",
                                      backgroundColor: "#000000",
                                      color: "#ffffff",
                                    }}
                                    onClick={() => handleDeletePress(image._id)}
                                  >
                                    Delete
                                  </button>
                                </div>
                              </div>
                            </>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminPressUpload;
