import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MDBBtn, MDBContainer, MDBRow, MDBCol, MDBInput } from 'mdb-react-ui-kit';
import Header from '../common/Header';
import Footer from '../common/Footer';
import ApiEndPoints from '../../NetworkCall/ApiEndPoint';
import ApiServices from '../../NetworkCall/ApiServices';

function VerifyOTP() {
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [password, setPassword] = useState('');
  const [id, setId] = useState('');
  const [isOtpVerified, setIsOtpVerified] = useState(false); // New state variable

  const optVerify = async (e) => {
    e.preventDefault();
    console.log('callllll');
    const payload = {
      userOtp: otp,
      email: email
    };

    try {
      const response = await ApiServices.post(ApiEndPoints.verifyOtp, payload);
      if (response.data.message === 'Email verified successfully') {
        setId(response.data.user._id);
        setEmail('');
        setOtp('');
        setIsOtpVerified(true); // Set the state to true
        console.log(response);
      } else {
        console.log(response);
        setErrorMessage('Please enter a valid OTP and email');
      }
    } catch (error) {
      console.log(error);
      setErrorMessage('Please enter a valid OTP and email');
    }
  };

  const changePassword = async (e) => {
    e.preventDefault();
    console.log('callllll');
    const payload = {
      _id: id,
      password: password
    };

    try {
      const response = await ApiServices.post(ApiEndPoints.changepassword, payload);
      if (response.data.message === 'Password updated successfully') {
        console.log(response);
        navigate('/login');
      } else {
        console.log(response);
        setErrorMessage('Please enter a valid OTP and email');
      }
    } catch (error) {
      console.log(error);
      setErrorMessage('Please enter a valid OTP and email');
    }
  };

  return (
    
      <div>
        <Header />
        <MDBContainer className="my-5 gradient-form">
          <MDBRow>
            <MDBCol col="6" className="mb-5">
              <div className="d-flex flex-column ms-5">
                <div className="text-center">
                  <img src="assets/images/logo/logo-dark.png" style={{ width: '185px' }} alt="logo" />
                   <h4 className="mt-1 mb-5 pb-1">
                   {isOtpVerified ? (" Save password")
    :
    ("Verify Otp")

  }

                  </h4>
                </div>
                {isOtpVerified ? (
                  <div className="changepassword">
                    <MDBInput
                      wrapperClass="mb-4"
                      label="New Password"
                      id="form2"
                      type="password"
                      value={password}
                      placeholder='enter your password'
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <div className="text-center pt-1 mb-5 pb-1">
                      <button
                        className="mb-4 w-100 gradient-custom-2"
                        onClick={changePassword}
                        style={{ backgroundColor: 'blue', color: 'white' }}
                      >
                        Save Password
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className='verifyotp'>
                    <p>Please verify your email</p>
                    <MDBInput
                      wrapperClass="mb-4"
                      label="Email"
                      id="form2"
                      type=" email"
                      placeholder=' enter your email'
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <MDBInput
                      wrapperClass="mb-4"
                      label="OTP"
                      id="form2"
                      type="number" placeholder=' enter your otp'
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                    />
                    <div className="text-center pt-1 mb-5 pb-1">
                      <button
                        className="mb-4 w-100 gradient-custom-2"
                        onClick={optVerify}
                        style={{ backgroundColor: 'blue', color: 'white' }}
                      >
                        Verify OTP
                      </button>
                      <div className="d-flex flex-row align-items-center justify-content-center pb-4 mb-4">
                        {errorMessage && (
                          <p className="mb-0" style={{ color: 'red' }}>
                            {errorMessage}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </MDBCol>
            <MDBCol col="6" className="mb-5">
              <div className="d-flex flex-column justify-content-center gradient-custom-2 h-100 mb-4">
                <div className="text-white px-3 py-4 p-md-5 mx-md-4">
                  <h4 className="mb-4">Jai Gurudev International School</h4>
                  <p className="small mb-0">
                    Jai Gurudev International School, also known as JGIS, was established in 2011. It is a Co-Ed school
                    affiliated with the Central Board of Secondary Education (CBSE) and managed by Jai Gurudev Seva Samiti.
                  </p>
                </div>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        <Footer />
      </div>
    
    
  );
}

export default VerifyOTP;
