import logo from "./logo.svg";
import React from "react";

import "react-toastify/dist/ReactToastify.css";
import { Route, Routes } from "react-router-dom";

import LoginPage from "./components/LoginPage";
import Admin from "./components/Admin/Admin";
import Protected from "./components/Protected";
import Routing from "./components/Routing";
import ForgetPass from "./components/ForgetPass/ForgetPass";
import VerifyOTP from "./components/ForgetPass/VerifyOTP";
import AdminPhotoCategroy from "./components/Admin/AdminPhotoCategroy";
import AdminPhotoGallery from "./components/Admin/AdminPhotoGallery";
import AdminVideoGallery from "./components/Admin/AdminVideoGallery";
import AdminPressUpload from "./components/Admin/AdminPressUpload";
import AdminBanner from "./components/Admin/AdminBanner";
import NewsAndEventAdminpanel from "./components/Admin/NewsAndEventAdminpanel";

function App() {
  return (
    <div className="App">
      <div id="main-wrapper" class="main-wrapper">
        <Routing />
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/admin" element={<Protected component={Admin} />} />
          <Route path="/forgetpassword" element={<ForgetPass />} />
          <Route
            path="/Admincategory/:categoryName"
            element={<Protected component={AdminPhotoCategroy} />}
          />
          <Route path="/verifyotp" element={<VerifyOTP />} />
          <Route
            path="/adminphoto"
            element={<Protected component={AdminPhotoGallery} />}
          />
          <Route
            path="/adminvideo"
            element={<Protected component={AdminVideoGallery} />}
          />{" "}
          <Route
            path="/adminpress"
            element={<Protected component={AdminPressUpload} />}
          />{" "}
          <Route
            path="/adminBanner"
            element={<Protected component={AdminBanner} />}
          />
          <Route
            path="/adminnews"
            element={<Protected component={NewsAndEventAdminpanel} />}
          />
        </Routes>
      </div>
    </div>
  );
}

export default App;
