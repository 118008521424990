export default function About() {
    return <>
        <div className="edu-breadcrumb-area breadcrumb-style-2 bg-image bg-image--19">
            <div className="container">
                <div className="breadcrumb-inner">
                    <div className="page-title">
                        <h1 className="title">
                            About Jay Gurudev <br /> International School
                        </h1>
                    </div>
                    <ul className="edu-breadcrumb">
                        <li className="breadcrumb-item">
                            <a href="index-one.html">Home</a>
                        </li>
                        <li className="separator">
                            <i className="icon-angle-right" />
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            About Us
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        {/*=====================================*/}
        {/*=       About Area Start            =*/}
        {/*=====================================*/}
        <div className="section-gap-large edu-about-area about-area-1 about-style-7">
            <div className="container">
                <div className="row g-5 align-items-center">
                    <div className="col-lg-5">
                        <div className="about-content">
                            <div
                                className="section-title section-left"
                                data-sal-delay={150}
                                data-sal="slide-up"
                                data-sal-duration={800}
                            >
                                <span className="pre-title">About Us</span>
                                <h2 className="title">
                                    We Providing The{" "}
                                    <span className="color-secondary">Best Quality</span> Online
                                    Courses.
                                </h2>
                                <span className="shape-line">
                                    <i className="icon-19" />
                                </span>
                                <p>
                                    Lorem ipsum dolor sit amet consectur adipiscing elit sed eiusmod
                                    ex tempor incididunt labore dolore magna aliquaenim ad minim.
                                </p>
                            </div>
                            <ul
                                className="features-list"
                                data-sal-delay={150}
                                data-sal="slide-up"
                                data-sal-duration={800}
                            >
                                <li>Flexible Classes</li>
                                <li>Offline Classe Mode</li>
                                <li>Educator Support</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="about-image-gallery">
                            <img
                                className="main-img-1"
                                src="assets/images/about/about-11.webp"
                                alt="About Image"
                            />
                            <ul className="shape-group">
                                <li
                                    className="shape-3 scene"
                                    data-sal-delay={500}
                                    data-sal="fade"
                                    data-sal-duration={200}
                                >
                                    <img
                                        data-depth="-1.8"
                                        src="assets/images/about/shape-04.png"
                                        alt="Shape"
                                    />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <ul className="shape-group">
                <li
                    className="shape-1"
                    data-sal-delay={500}
                    data-sal="fade"
                    data-sal-duration={200}
                />
            </ul>
        </div>
        {/*=====================================*/}
        {/*=       Brand Area Start            =*/}
        {/*=====================================*/}
        {/* Start Brand Area  */}
        <div className="edu-brand-area edu-brand-area-1 brand-area-4 gap-lg-bottom-equal">
            <div className="container">
                <div className="brand-grid-wrap brand-style-2">
                    <div className="brand-grid">
                        <img src="assets/images/brand/brand-01.png" alt="Brand Logo" />
                    </div>
                    <div className="brand-grid">
                        <img src="assets/images/brand/brand-02.png" alt="Brand Logo" />
                    </div>
                    <div className="brand-grid">
                        <img src="assets/images/brand/brand-03.png" alt="Brand Logo" />
                    </div>
                    <div className="brand-grid">
                        <img src="assets/images/brand/brand-04.png" alt="Brand Logo" />
                    </div>
                    <div className="brand-grid">
                        <img src="assets/images/brand/brand-05.png" alt="Brand Logo" />
                    </div>
                    <div className="brand-grid">
                        <img src="assets/images/brand/brand-06.png" alt="Brand Logo" />
                    </div>
                </div>
            </div>
        </div>
        {/* End Brand Area  */}
        {/*=====================================*/}
        {/*=       Why Choose Area Start       =*/}
        {/*=====================================*/}
        {/* Start Why Choose Area  */}
        <section className="why-choose-area-2 section-gap-large">
            <div className="container edublink-animated-shape">
                <div
                    className="section-title section-center"
                    data-sal-delay={150}
                    data-sal="slide-up"
                    data-sal-duration={800}
                >
                    <span className="pre-title">Why choose edublink</span>
                    <h2 className="title">
                        The Best <span className="color-secondary">Beneficial</span> Side{" "}
                        <br /> of EduBlink
                    </h2>
                    <span className="shape-line">
                        <i className="icon-19" />
                    </span>
                </div>
                <div className="row g-5">
                    <div
                        className="col-lg-4"
                        data-sal-delay={150}
                        data-sal="slide-up"
                        data-sal-duration={800}
                    >
                        <div className="why-choose-box features-box color-primary-style">
                            <div className="icon">
                                <i className="icon-45" />
                            </div>
                            <div className="content">
                                <h4 className="title">Our Mission</h4>
                                <p>
                                    Lorem ipsum dolor sit amet conset ur elit sed eiusmod ex tempor
                                    inc labore dolore magna.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div
                        className="col-lg-4"
                        data-sal-delay={150}
                        data-sal="slide-up"
                        data-sal-duration={800}
                    >
                        <div className="why-choose-box features-box color-secondary-style">
                            <div className="icon">
                                <i className="icon-46" />
                            </div>
                            <div className="content">
                                <h4 className="title">Our Vission</h4>
                                <p>
                                    Lorem ipsum dolor sit amet conset ur elit sed eiusmod ex tempor
                                    inc labore dolore magna.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div
                        className="col-lg-4"
                        data-sal-delay={150}
                        data-sal="slide-up"
                        data-sal-duration={800}
                    >
                        <div className="why-choose-box features-box color-extra08-style">
                            <div className="icon">
                                <i className="icon-47" />
                            </div>
                            <div className="content">
                                <h4 className="title">About School</h4>
                                <p>
                                    Lorem ipsum dolor sit amet conset ur elit sed eiusmod ex tempor
                                    inc labore dolore magna.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <ul className="shape-group">
                    <li
                        className="shape-5"
                        data-sal-delay={500}
                        data-sal="fade"
                        data-sal-duration={200}
                    >
                        <span />
                    </li>
                </ul>
            </div>
            <ul className="shape-group">
                <li
                    className="shape-1 scene"
                    data-sal-delay={500}
                    data-sal="fade"
                    data-sal-duration={200}
                >
                    <span data-depth={1} />
                </li>
                <li
                    className="shape-2 scene"
                    data-sal-delay={500}
                    data-sal="fade"
                    data-sal-duration={200}
                >
                    <img
                        data-depth={-2}
                        src="assets/images/about/shape-13.png"
                        alt="shape"
                    />
                </li>
                <li
                    className="shape-3 scene"
                    data-sal-delay={500}
                    data-sal="fade"
                    data-sal-duration={200}
                >
                    <span data-depth={-1} />
                </li>
                <li
                    className="shape-4 scene"
                    data-sal-delay={500}
                    data-sal="fade"
                    data-sal-duration={200}
                >
                    <img
                        data-depth={2}
                        src="assets/images/about/shape-40.png"
                        alt="shape"
                    />
                </li>
            </ul>
        </section>
        {/* End Why Choose Area  */}
        {/*=====================================*/}
        {/*=       Testimonial Area Start      =*/}
        {/*=====================================*/}
        {/* Start Testimonial Area  */}
        <div className="testimonial-area-5 gap-lg-bottom-equal">
            <div className="container">
                <div className="row g-lg-5">
                    <div className="col-lg-5">
                        <div className="testimonial-heading-area">
                            <div
                                className="section-title section-left"
                                data-sal-delay={150}
                                data-sal="slide-up"
                                data-sal-duration={800}
                            >
                                <span className="pre-title">Testimonials</span>
                                <h2 className="title">What Our Students Have To Say</h2>
                                <span className="shape-line">
                                    <i className="icon-19" />
                                </span>
                                <p>
                                    Lorem ipsum dolor sit amet consectur adipiscing elit sed eiusmod
                                    tempor incididunt labore dolore magna aliquaenim ad minim.
                                </p>
                                <a href="#" className="edu-btn btn-large">
                                    View All
                                    <i className="icon-4" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7">
                        <div className="swiper-testimonial-slider-wrapper swiper testimonial-coverflow">
                            <div className="swiper-wrapper">
                                <div className="swiper-slide">
                                    <div className="testimonial-grid">
                                        <div className="thumbnail">
                                            <img
                                                src="assets/images/testimonial/testimonial-01.png"
                                                alt="Testimonial"
                                            />
                                            <span className="qoute-icon">
                                                <i className="icon-26" />
                                            </span>
                                        </div>
                                        <div className="content">
                                            <p>
                                                Lorem ipsum dolor amet consec tur elit adicing sed do
                                                usmod zx tempor enim minim veniam quis nostrud exer
                                                citation.
                                            </p>
                                            <div className="rating-icon">
                                                <i className="icon-23" />
                                                <i className="icon-23" />
                                                <i className="icon-23" />
                                                <i className="icon-23" />
                                                <i className="icon-23" />
                                            </div>
                                            <h5 className="title">Ray Sanchez</h5>
                                            <span className="subtitle">Student</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="testimonial-grid">
                                        <div className="thumbnail">
                                            <img
                                                src="assets/images/testimonial/testimonial-02.png"
                                                alt="Testimonial"
                                            />
                                            <span className="qoute-icon">
                                                <i className="icon-26" />
                                            </span>
                                        </div>
                                        <div className="content">
                                            <p>
                                                Lorem ipsum dolor amet consec tur elit adicing sed do
                                                usmod zx tempor enim minim veniam quis nostrud exer
                                                citation.
                                            </p>
                                            <div className="rating-icon">
                                                <i className="icon-23" />
                                                <i className="icon-23" />
                                                <i className="icon-23" />
                                                <i className="icon-23" />
                                                <i className="icon-23" />
                                            </div>
                                            <h5 className="title">Thomas Lopez</h5>
                                            <span className="subtitle">Designer</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="testimonial-grid">
                                        <div className="thumbnail">
                                            <img
                                                src="assets/images/testimonial/testimonial-03.png"
                                                alt="Testimonial"
                                            />
                                            <span className="qoute-icon">
                                                <i className="icon-26" />
                                            </span>
                                        </div>
                                        <div className="content">
                                            <p>
                                                Lorem ipsum dolor amet consec tur elit adicing sed do
                                                usmod zx tempor enim minim veniam quis nostrud exer
                                                citation.
                                            </p>
                                            <div className="rating-icon">
                                                <i className="icon-23" />
                                                <i className="icon-23" />
                                                <i className="icon-23" />
                                                <i className="icon-23" />
                                                <i className="icon-23" />
                                            </div>
                                            <h5 className="title">Amber Page</h5>
                                            <span className="subtitle">Developer</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="testimonial-grid">
                                        <div className="thumbnail">
                                            <img
                                                src="assets/images/testimonial/testimonial-04.png"
                                                alt="Testimonial"
                                            />
                                            <span className="qoute-icon">
                                                <i className="icon-26" />
                                            </span>
                                        </div>
                                        <div className="content">
                                            <p>
                                                Lorem ipsum dolor amet consec tur elit adicing sed do
                                                usmod zx tempor enim minim veniam quis nostrud exer
                                                citation.
                                            </p>
                                            <div className="rating-icon">
                                                <i className="icon-23" />
                                                <i className="icon-23" />
                                                <i className="icon-23" />
                                                <i className="icon-23" />
                                                <i className="icon-23" />
                                            </div>
                                            <h5 className="title">Robert Tapp</h5>
                                            <span className="subtitle">Content Creator</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-pagination" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* End Testimonial Area  */}
        {/*=====================================*/}
        {/*=       CounterUp Area Start        =*/}
        {/*=====================================*/}
        {/* <div class="counterup-area-8 gap-lg-bottom-equal">
      <div class="container">
          <div class="row g-5">
              <div class="col-lg-3 col-sm-6" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                  <div class="edu-counterup counterup-style-4">
                      <div class="icon primary-color">
                          <i class="icon-48"></i>
                      </div>
                      <h2 class="counter-item count-number">
                          <span class="odometer" data-odometer-final="29.3">.</span><span>K</span>
                      </h2>
                      <h6 class="title">Student Enrolled</h6>
                  </div>
              </div>
              <div class="col-lg-3 col-sm-6" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                  <div class="edu-counterup counterup-style-4">
                      <div class="icon secondary-color">
                          <i class="icon-47"></i>
                      </div>
                      <h2 class="counter-item count-number">
                          <span class="odometer" data-odometer-final="32.4">.</span><span>K</span>
                      </h2>
                      <h6 class="title">Class Completed</h6>
                  </div>
              </div>
              <div class="col-lg-3 col-sm-6" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                  <div class="edu-counterup counterup-style-4">
                      <div class="icon extra08-color">
                          <i class="icon-49"></i>
                      </div>
                      <h2 class="counter-item count-number">
                          <span class="odometer" data-odometer-final="100">.</span><span>%</span>
                      </h2>
                      <h6 class="title">Satisfaction Rate</h6>
                  </div>
              </div>
              <div class="col-lg-3 col-sm-6" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                  <div class="edu-counterup counterup-style-4">
                      <div class="icon extra05-color">
                          <i class="icon-50"></i>
                      </div>
                      <h2 class="counter-item count-number">
                          <span class="odometer" data-odometer-final="354">.</span><span>+</span>
                      </h2>
                      <h6 class="title">Top Instructors</h6>
                  </div>
              </div>
          </div>
      </div>
      <ul class="shape-group">
          <li class="shape-1" data-sal-delay="500" data-sal="fade" data-sal-duration="200">
              <img src="assets/images/others/map-shape-3.png" alt="Shape">
          </li>
      </ul>
  </div> */}
        {/*=====================================*/}
        {/*=        Team Area Start       	    =*/}
        {/*=====================================*/}
        {/* Start Team Area  */}
        <div className="edu-team-area team-area-5 section-gap-large">
            {/* <div class="container">
          <div class="section-title section-center" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
              <span class="pre-title">Instructors</span>
              <h2 class="title">Course Instructors</h2>
              <span class="shape-line"><i class="icon-19"></i></span>
          </div>
          <div class="row g-5">

              <div class="col-lg-4 col-md-6" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                  <div class="edu-team-grid team-style-2">
                      <div class="inner">
                          <div class="thumbnail-wrap">
                              <div class="thumbnail">
                                  <a href="team-details.html">
                                      <img src="assets/images/team/team-05.webp" alt="team images">
                                  </a>
                              </div>
                              <ul class="team-share-info">
                                  <li><a href="#"><i class="icon-facebook"></i></a></li>
                                  <li><a href="#"><i class="icon-twitter"></i></a></li>
                                  <li><a href="#"><i class="icon-linkedin2"></i></a></li>
                              </ul>
                          </div>
                          <div class="content">
                              <h5 class="title"><a href="team-details.html">Jane Seymour</a></h5>
                              <span class="designation">UI Designer</span>
                              <p>Consectetur adipisicing elit, sed do eius mod tempor incididunt</p>
                          </div>
                      </div>
                  </div>
              </div>

              <div class="col-lg-4 col-md-6" data-sal-delay="200" data-sal="slide-up" data-sal-duration="800">
                  <div class="edu-team-grid team-style-2">
                      <div class="inner">
                          <div class="thumbnail-wrap">
                              <div class="thumbnail">
                                  <a href="team-details.html">
                                      <img src="assets/images/team/team-06.webp" alt="team images">
                                  </a>
                              </div>
                              <ul class="team-share-info">
                                  <li><a href="#"><i class="icon-facebook"></i></a></li>
                                  <li><a href="#"><i class="icon-twitter"></i></a></li>
                                  <li><a href="#"><i class="icon-linkedin2"></i></a></li>
                              </ul>
                          </div>
                          <div class="content">
                              <h5 class="title"><a href="team-details.html">Edward Norton</a></h5>
                              <span class="designation">Web Developer</span>
                              <p>Consectetur adipisicing elit, sed do eius mod tempor incididunt</p>
                          </div>
                      </div>
                  </div>
              </div>

              <div class="col-lg-4 col-md-6" data-sal-delay="300" data-sal="slide-up" data-sal-duration="800">
                  <div class="edu-team-grid team-style-2">
                      <div class="inner">
                          <div class="thumbnail-wrap">
                              <div class="thumbnail">
                                  <a href="team-details.html">
                                      <img src="assets/images/team/team-07.webp" alt="team images">
                                  </a>
                              </div>
                              <ul class="team-share-info">
                                  <li><a href="#"><i class="icon-facebook"></i></a></li>
                                  <li><a href="#"><i class="icon-twitter"></i></a></li>
                                  <li><a href="#"><i class="icon-linkedin2"></i></a></li>
                              </ul>
                          </div>
                          <div class="content">
                              <h5 class="title"><a href="team-details.html">Penelope Cruz</a></h5>
                              <span class="designation">Digital Marketer</span>
                              <p>Consectetur adipisicing elit, sed do eius mod tempor incididunt</p>
                          </div>
                      </div>
                  </div>
              </div>

          </div>
      </div> */}
            <div className="container">
                <div className="row g-5">
                    <div
                        className="col-lg-3 col-sm-6"
                        data-sal-delay={150}
                        data-sal="slide-up"
                        data-sal-duration={800}
                    >
                        <div className="edu-counterup counterup-style-4">
                            <div className="icon primary-color">
                                <i className="icon-48" />
                            </div>
                            <h2 className="counter-item count-number">
                                <span className="odometer" data-odometer-final="29.3">
                                    .
                                </span>
                                <span>K</span>
                            </h2>
                            <h6 className="title">Student Enrolled</h6>
                        </div>
                    </div>
                    <div
                        className="col-lg-3 col-sm-6"
                        data-sal-delay={150}
                        data-sal="slide-up"
                        data-sal-duration={800}
                    >
                        <div className="edu-counterup counterup-style-4">
                            <div className="icon secondary-color">
                                <i className="icon-47" />
                            </div>
                            <h2 className="counter-item count-number">
                                <span className="odometer" data-odometer-final="32.4">
                                    .
                                </span>
                                <span>K</span>
                            </h2>
                            <h6 className="title">Class Completed</h6>
                        </div>
                    </div>
                    <div
                        className="col-lg-3 col-sm-6"
                        data-sal-delay={150}
                        data-sal="slide-up"
                        data-sal-duration={800}
                    >
                        <div className="edu-counterup counterup-style-4">
                            <div className="icon extra08-color">
                                <i className="icon-49" />
                            </div>
                            <h2 className="counter-item count-number">
                                <span className="odometer" data-odometer-final={100}>
                                    .
                                </span>
                                <span>%</span>
                            </h2>
                            <h6 className="title">Satisfaction Rate</h6>
                        </div>
                    </div>
                    <div
                        className="col-lg-3 col-sm-6"
                        data-sal-delay={150}
                        data-sal="slide-up"
                        data-sal-duration={800}
                    >
                        <div className="edu-counterup counterup-style-4">
                            <div className="icon extra05-color">
                                <i className="icon-50" />
                            </div>
                            <h2 className="counter-item count-number">
                                <span className="odometer" data-odometer-final={354}>
                                    .
                                </span>
                                <span>+</span>
                            </h2>
                            <h6 className="title">Top Instructors</h6>
                        </div>
                    </div>
                </div>
            </div>
            <ul className="shape-group">
                <li
                    className="shape-1 scene"
                    data-sal-delay={500}
                    data-sal="fade"
                    data-sal-duration={200}
                >
                    <span data-depth=".8" />
                </li>
                <li
                    className="shape-2 scene"
                    data-sal-delay={500}
                    data-sal="fade"
                    data-sal-duration={200}
                >
                    <img
                        data-depth={-2}
                        src="assets/images/about/shape-13.png"
                        alt="shape"
                    />
                </li>
                <li
                    className="shape-3"
                    data-sal-delay={500}
                    data-sal="fade"
                    data-sal-duration={200}
                >
                    <span />
                </li>
            </ul>
        </div>
        {/* End Team Area  */}
        {/*=====================================*/}
        {/*=        Footer Area Start       	=*/}
        {/*=====================================*/}
        {/* Start Footer Area  */}
    </>
}