import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AdminNavbar from "./AdminNavbar";
import AdminSidebar from "./AdminSidebar";
import AdminBanner from "./AdminBanner";
import "./AdminCommon.css";
import NewsAndEventAdminpanel from "./NewsAndEventAdminpanel";
import AdminPhotoGallery from "./AdminPhotoGallery";
import AdminVideoGallery from "./AdminVideoGallery";
import AdminPressUpload from "./AdminPressUpload";
import "./style.css";
function Admin() {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("login");
    navigate("/login");
  };

  const LogoutAuto = () => {
    setTimeout(() => {
      handleLogout();
    }, 60 * 60 * 1000); // 30 minutes
  };

  useEffect(() => {
    LogoutAuto();
  }, []);

  return (
    <div>
      <button onClick={handleLogout} style={{ display: "none" }}>
        Logout
      </button>
      <AdminNavbar />

      <div className="d-flex" style={{ position: "relative" }}>
        <div style={{ marginTop: "60px", position: "fixed", zIndex: "1" }}>
          {" "}
          <AdminSidebar />
        </div>
        <div
          className="bannerimagposition"
          style={{
            marginTop: "60px",
            width: "100%",
            position: "absolute",
          }}
        >
          {" "}
          <AdminBanner />
        </div>
      </div>
    </div>
  );
}

export default Admin;
