import React from 'react'
import Header from '../../common/Header'
import Footer from '../../common/Footer'
function PrincipleMessage() {
  return (
<>
<Header></Header>
<div>
        <div className="home-one-cta-two cta-area-1 banner_1">
          <div className="container">
            <div className="row text-center">
              <div className="col-md-12">
                <div className="about_over" />
                <div className="about_school">
                  <h1>About Us</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="missionandvision-content-contaioner">
        <div class="edu-course course-style-4 course-style-14">
          <div class="inner">
            <div class="content">
              <div className="inner-content">
                <h2>Principal's Message</h2>
                <p>
                  <img
                    className="principal-message-paragraph-img"
                    src="https://media.istockphoto.com/id/1214428300/vector/default-profile-picture-avatar-photo-placeholder-vector-illustration.jpg?s=612x612&w=0&k=20&c=vftMdLhldDx9houN4V-g3C9k0xl6YeBcoB_Rk6Trce0="
                    alt="Course Meta"
                  />
                A student who is taught discipline will learn the power of self-control. Freedom, to be truly effective, needs the guidance of discipline. To be successful, we need self-discipline which teaches us to sacrifice individual interests in favor of group interest Our children represent our hopes and dreams. As a parent, you want the best for your child and we are proud to be working with you to ensure that they are given the right knowledge to face future challenges.
                  <br />
                  Education is not just a process of giving knowledge for a future job but a lifelong process that creates an understanding of moral and ethical values to guide one’s life and make our students the future hope of the country.
                  <br /> Parents, students, and the school authorities are a team and complement each other’s efforts. we hope to create an atmosphere of reverence for education and a healthy environment where work, sports, and co-curricular activities will mold our students and spur them on to be the brightest and the best. In order to do this, the parents and the school authorities must work together to inculcate certain values in the students. Respect for one’s elders, especially the teachers and a commitment to discipline are engraved in Indian culture. Let us inculcate these ideals in our younger generation. A student who has respect for the teacher will be more receptive to authority and learning. This will enable him/her to get the best out of the school. <br />
                  Teach your children to respect school discipline and to be proud of it. The parents and school authorities together can instill proper discipline and basic values in the children while preparing them academically for their future.
                  <p>
                  A large part of a child’s active life is spent in the school. However, every child gets his/her emotional stability from the parents. It is the parent’s attitude that a child absorbs and the interest and time that parents invest in children, is crucial for their development.
                  </p>
                  Please spend half an hour with your child and have a talk about his/her day at school. Encourage him/her to do his/her work regularly from day one. Know your children; not just the homework they need to complete but also the friends they make and the problems they face in school, on the school bus or at play. Try to create a sense of purpose in the children and be their friend
                   <br/>
                   Parents are requested to attend PTMs regularly to make the growth of the child in a proper direction. A healthy and purposeful relation between school and child’s family gives a required result of child development.

We welcome your suggestions. Please send them addressed to ‘The Principal, JGIS, Chhindwara” and we shall ensure that necessary action is taken. You can mail/fax your letter or write it in our suggestion register kept in the school office. Finally, please go through the almanac for information about various activities and encourage your child to participate in them.<br/><br/><br/>
                </p>
               
                <p className="principal-Message-thought">
                  <strong>
                    "Education is the most precious wealth in everyone's life".
                  </strong>
                </p>
                <div className="principal-signature">
                  <b>
                     <br />
                    (principal)
                  </b>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
        </div>
      </div>
      <Footer></Footer>
</>
  )
}

export default PrincipleMessage