import React, { useState, useRef, useEffect } from "react";
import ApiEndPoints from "../../../NetworkCall/ApiEndPoint";
import ApiServices from "../../../NetworkCall/ApiServices";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
function PressRelease() {
  const inputFileReels = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [savingImage, setSavingImage] = useState(false);
  const [PressData, setPressData] = useState([]);
  const fetchImages = () => {
    ApiServices.get(ApiEndPoints.getPress)
      .then((response) => {
        if (response.status.code === 200) {
          setPressData(response.data.pressData);
        }
      })
      .catch((error) => {
        console.log("Error fetching banner images:", error);
      });
  };

  useEffect(() => {
    fetchImages();
  }, []);

  const handleBannerImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file);
    }
  };

  const handleAddImage = () => {
    inputFileReels.current.click();
  };

  const handleCreatePress = () => {
    if (selectedImage) {
      setSavingImage(true); // Show loading state
      const formData = new FormData();
      formData.append("pressimg", selectedImage, selectedImage.name);

      ApiServices.post(ApiEndPoints.CreatePress, formData)
        .then((response) => {
          if (response?.status?.code === 200) {
            console.log(response);
            fetchImages(); // Refresh the images after successful creation
          } else {
            console.log("Error creating banner:", response);
          }
        })
        .catch((error) => {
          console.log("Error creating banner:", error);
        })
        .finally(() => {
          setSavingImage(false); // Hide loading state
          setSelectedImage(null); // Reset selectedImage after successful save
        });
    }
  };

  return (
    <div>
      <Header></Header>
      <div style={{ display: "none" }}>
        <input
          placeholder="Click here"
          type="file"
          accept="images/*"
          onChange={handleBannerImage}
          ref={inputFileReels}
          style={{ display: "none" }}
        />
        <button onClick={handleAddImage}>Select Image</button>
        <button
          onClick={handleCreatePress}
          disabled={!selectedImage || savingImage}
        >
          {savingImage ? "Saving Images..." : "Save Images"}
        </button>
      </div>
      <div>
        <div className="home-one-cta-two cta-area-1 banner_1">
          <div className="container">
            <div className="row text-center">
              <div className="col-md-12">
                <div className="about_over" />
                <div className="about_school">
                  <h1>Gallery</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="missionandvision-content-contaioner">
        <div class="edu-course course-style-4 course-style-14">
          <div class="inner">
            <div class="content ">
              <div className="inner-content">
                <h2>Press Gallery</h2>
                <div style={{ display: "flex", justifyContent: " center" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      width: "80%",
                      columnGap: "30px",
                      rowGap: "30px",
                      flexWrap: "wrap",
                      textAlign: "center",
                    }}
                  >
                    {PressData.map((image) => (
                      <>
                        <div>
                          <li
                            style={{
                              width: "300px",
                              height: "200px",
                              borderRadius: "10px",
                              listStyle: "none",
                              textDecoration: "none",
                            }}
                          >
                            <img
                              style={{
                                width: "100%",
                                height: "100%",
                                borderRadius: "10px",
                              }}
                              src={image.pressUrl}
                              alt=""
                            />
                          </li>
                        </div>
                      </>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
        </div>
      </div>

      <Footer></Footer>
    </div>
  );
}

export default PressRelease;
