import React from "react";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
import libraryimage from "../../../assets/images/facilities/libraryimage.jpg";
function Libbrery() {
  return (
    <div>
      <Header></Header>
      <div class="edu-breadcrumb-area banner_1">
        <div class="container">
          <div class="breadcrumb-inner">
            <div class="page-title">
              <h1 class="title " style={{ position: "relative", zIndex: "1" }}>
                Facilities
              </h1>
            </div>
          </div>
        </div>
        <div className="about_over"></div>
      </div>
      <section class="section-gap-equal">
        <div class="container">
          <div class="row row--30">
            <div class="col-lg-12">
              <div class="amenities_head">
                <h3 class="title mb-0">Library</h3>
              </div>
            </div>
            <div class="col-lg-12">
              <div
                style={{ boxShadow: "none" }}
                class="edu-blog blog-style-list amenities_main"
                data-sal-delay="150"
                data-sal="slide-up"
                data-sal-duration="800"
              >
                <div class="inner">
                  <img
                    style={{ width: "100%" }}
                    src={libraryimage}
                    alt="Blog Images"
                  />
                </div>
              </div>

              <div
                style={{ boxShadow: "none" }}
                class="edu-blog blog-style-list amenities_main"
                data-sal-delay="150"
                data-sal="slide-up"
                data-sal-duration="800"
              >
                <div class="inner">
                  <div class="content" style={{ textAlign: "justify" }}>
                    <p>
                      Library plays an important role in developing reading
                      habits. A collection of good books motivate students for
                      reading. We have a rich library full of thousands of books
                      related to Literature, Language, Novels, Story, GK,
                      Encyclopedia, Dictionary, fairy tales, Rhymes, Science,
                      Prose, Poetry, Quiz and Reference Books. Students visit
                      the Library as per their time table schedule. A school
                      library is a store house of knowledge which satisfies our
                      eternal desire for knowledge of the unknown and unseen. A
                      school library is a place where the teachers and students
                      can read, and from where they can borrow books. In our
                      school, we also have a school library. It is housed in a
                      separate two storied building. Indeed, the library is well
                      constructed. There are many almirahs. The books are
                      arranged on different shelves according to their subjects.
                      There are separate reading room and an office room for the
                      librarian. In our school library, we have a librarian who
                      is a very nice person. There is a big reading room which
                      is well furnished. So, we can read books there as we wish.
                      When we take the help of the catalogue, the librarian at
                      once finds the book, and thus he helps us a lot. We are
                      given library cards by which we can issue books. The
                      issued books can be kept for two weeks. Silence is must in
                      the library. So, nobody can make any noise. Thus, anyone
                      can study here with full attention without any
                      disturbance. A school library is a part and parcel of a
                      school. In other words, a school con never be complete
                      without a library. Therefore, due to having such
                      opportunity, I am proud of my school library.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </div>
  );
}

export default Libbrery;
