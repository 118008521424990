import React from 'react'
import Header from '../../common/Header'
import Footer from '../../common/Footer'
function AccedmicStructure() {
  return (
    <>
        <Header></Header>


    <div>
      <div className="home-one-cta-two cta-area-1 banner_1">
        <div className="container">
          <div className="row text-center">
            <div className="col-md-12">
              <div className="about_over" />
              <div className="about_school">
                <h1>About Us</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="missionandvision-content-contaioner">
      <div class="edu-course course-style-4 course-style-14">
        <div class="inner">
          <div class="content">
            <div className="inner-content">
              {" "}
              <h2>Academic Structure</h2>
              As per the guidelines of CBSE, we follow the curriculum from
              class Nursery to XII as mentioned below.
              <br />
              <br />
              <p>
                <div>
                  <a
                    className="academic-structure-image-anchor"
                    href="course-details.html"
                  >
                    <img
                      className="academic-structure-image"
                      src="https://www.bhandarischool.com/wp-content/uploads/2023/02/Academic-Structure.png%22"
                      alt="Course Meta"
                    />
                  </a>
                </div>
              </p>
            </div>
          </div>
        </div>
        <br />
        <br />
      </div>
    </div>

    <Footer></Footer>
  </>
  )
}

export default AccedmicStructure