import React from 'react'
import Header from '../../common/Header'
import Footer from '../../common/Footer'

function Bus() {
  return (
    <div>
      <Header></Header>
      <Footer></Footer>
      Bus</div>
  )
}

export default Bus