import React, { useState } from "react";
import "./Careers.css";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import carrerimg from "../../../assets/images/contacts/careerimage.jpg";
import { useRef } from "react";
import emailjs from "@emailjs/browser";
import { toast, ToastContainer } from "react-toastify";
function Careers() {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    const formData = new FormData(form.current);

    const errors = {};

    if (!formData.get("user_name")) {
      errors.user_name = "Name is required";
    }

    if (!formData.get("user_email")) {
      errors.user_email = "Email is required";
    }

    if (!formData.get("contact_phone")) {
      errors.contact_phone = "Phone number is required";
    }

    if (!formData.get("user_qualification")) {
      errors.user_qualification = "Qualification is required";
    }

    if (!formData.get("user_city")) {
      errors.user_city = "Enter your city is required";
    }
    if (!formData.get("user_appliedfor")) {
      errors.user_appliedfor = "Position is required";
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    // If validation passes, send email
    emailjs
      .sendForm(
        "service_xil6hei",
        "template_bd57sei",
        form.current,
        "f-TdLoHUtNT2WVsj8"
      )
      .then((result) => {
        console.log(result.text);
        toast.success("Thank you for your submission!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
        setIsSubmitted(true);

        setFormErrors({});
        form.current.reset();
      })
      .catch((error) => {
        console.log(error.text);
        toast.error("An error occurred. Please try again later.", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      });
  };
  return (
    <div>
      {" "}
      <Header></Header>
      <div>
        <div className="home-one-cta-two cta-area-1 banner_1">
          <div className="container">
            <div className="row text-center">
              <div className="col-md-12">
                <div className="about_over" />
                <div className="about_school">
                  <h1>Careers</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="missionandvision-content-contaioner">
        <div class="edu-course course-style-4 course-style-14">
          <div class="inner">
            <div class="content ">
              <div className="inner-content">
                <h2>Careers</h2>

                <p>
                  <div className="careerimage-contaier">
                    <a href="course-details.html">
                      <img
                        src={carrerimg}
                        // src="https://scontent.fixb1-1.fna.fbcdn.net/v/t39.30808-6/311569265_475017837979662_4079450510857644886_n.jpg?stp=dst-jpg_p960x960&_nc_cat=110&ccb=1-7&_nc_sid=e3f864&_nc_ohc=W_ui48P5D8MAX8pVV7U&_nc_ht=scontent.fixb1-1.fna&oh=00_AfCpnmnpNkgXos89jDiq5Oc0faNJcDvyoZHr-32C0-mq7A&oe=64AB0978"
                        alt="Course Meta"
                        style={{ height: "280px" }}
                      />
                    </a>
                  </div>
                  Jai Gurudev International School also known as JGIS The school
                  was established in 2011, spread over an area of about 10618.95
                  Sq. Metres of lush, green lawns, is a co-educational
                  day-cum-boarding school. Founded in 2011, Jai Gurudev
                  International School is a Senior Secondary, affiliated to
                  CBSE. Affiliation ID is 1030705. Address of the school is:
                  Isha Nagar Parasia Road Partala Chindwara,. PIN Code: 480001.
                  Email address of the school is rashmibhargava@gmail.com. The
                  school is being managed by Jai Gurudev Seva Samiti.
                </p>
                <p>
                  The school comes with an uncompromising commitment. It aims to
                  achieve specific, measurable, observable and quantifiable
                  results among all aspirants/students. Because the School has a
                  vision to provide value based education to young minds and
                  provide a dynamic learning environment. However, the School
                  aegis for every student which strives for excellence through
                  active learning and education. Moreover, the School has the
                  core committed faculty which has come from accomplished
                  backgrounds with vast experience.
                </p>
                <br />
                <br />

                <div>
                  <form
                    class="rnt-contact-form rwt-dynamic-form"
                    id="contact-form"
                    method="POST"
                    action="https://edublink.html.devsblink.com/mail.php"
                    ref={form}
                    onSubmit={sendEmail}
                  >
                    <div className="careerpage">
                      <div className="careerpage-row">
                        <div className="careerpage-input-container">
                          <label>Name:</label>
                          <input type="text" name="user_name" required />
                          {formErrors.user_name && (
                            <div className="error-message">
                              {formErrors.user_name}
                            </div>
                          )}
                        </div>{" "}
                        <div className="careerpage-input-container">
                          <label>Email:</label>
                          <input type="email" name="user_email" required />{" "}
                          {formErrors.user_email && (
                            <div className="error-message">
                              {formErrors.user_email}
                            </div>
                          )}
                        </div>{" "}
                        <div className="careerpage-input-container">
                          <label>Mobile Number:</label>
                          <input
                            type="number"
                            name="contact_phone"
                            required
                          />{" "}
                          {formErrors.contact_phone && (
                            <div className="error-message">
                              {formErrors.contact_phone}
                            </div>
                          )}
                        </div>
                      </div>{" "}
                      <div className="careerpage-row">
                        <div className="careerpage-input-container">
                          <label>Qualification:</label>

                          <input
                            type="text"
                            name="user_qualification"
                            required
                          />
                          {formErrors.user_qualification && (
                            <div className="error-message">
                              {formErrors.user_qualification}
                            </div>
                          )}
                        </div>{" "}
                        <div className="careerpage-input-container">
                          <label>City:</label>
                          <input type="text" name="user_city" required />
                          {formErrors.user_city && (
                            <div className="error-message">
                              {formErrors.user_city}
                            </div>
                          )}
                        </div>
                        <div className="careerpage-input-container">
                          <label>Post Applied For:</label>
                          <input type="text" name="user_appliedfor" required />
                          {formErrors.user_appliedfor && (
                            <div className="error-message">
                              {formErrors.user_appliedfor}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="careerpage-third-row">
                        {/**<div className="careerpage-input-container">
                          <input type="file" name="user_document" required />
                          {formErrors.user_document && (
                            <div className="error-message">
                              {formErrors.user_document}
                            </div>
                          )}
                        </div> */}
                        <div className="careerpage-input-container textalign">
                          <button name="submit" type="submit">
                            Send Message
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default Careers;
