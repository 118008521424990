import React, { useState, useEffect, useRef } from "react";
import "./PhotoGallery.css";
import ApiEndPoints from "../../../NetworkCall/ApiEndPoint";
import ApiServices from "../../../NetworkCall/ApiServices";
import { useNavigate } from "react-router-dom";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
function PhotoGallery() {
  const inputFileReels = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [savingImage, setSavingImage] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [category, setCategory] = useState([]);
  const [categories, setCategories] = useState({});
  const [showAllImages, setShowAllImages] = useState(false);
  const [addingMoreImages, setAddingMoreImages] = useState(false);
  const [currentCategory, setCurrentCategory] = useState("");

  const navigate = useNavigate();

  const fetchImages = () => {
    const payload = {
      category: selectedCategory, // Pass selected category in the payload
    };

    ApiServices.post(ApiEndPoints.getPhoto, payload)
      .then((response) => {
        if (response.status.code === 200) {
          console.log(response);
        }
      })
      .catch((error) => {
        console.log("Error fetching banner images:", error);
      });
  };

  const fetchCategories = () => {
    // Fetch the existing categories from the server
    ApiServices.get(ApiEndPoints.getCategories)
      .then((response) => {
        if (response.status.code === 200) {
          const categoriesData = response.data.categories;
          setCategory(categoriesData);
        }
      })
      .catch((error) => {
        console.log("Error fetching categories:", error);
      });
  };

  const fetchImagess = () => {
    const payload = {};

    ApiServices.post(ApiEndPoints.getPhoto, payload)
      .then((response) => {
        if (response.status.code === 200) {
          const photoData = response.data.photoData;
          const updatedCategories = {};

          // Group images by category
          photoData.forEach((item) => {
            const category = item.category;
            if (!updatedCategories[category]) {
              updatedCategories[category] = [];
            }
            updatedCategories[category].push(item);
          });

          setCategories(updatedCategories);

          // Extract the unique categories from the photoData
          const uniqueCategories = [
            ...new Set(photoData.map((item) => item.category)),
          ];
          setCategory(uniqueCategories);
        }
      })
      .catch((error) => {
        console.log("Error fetching banner images:", error);
      });
  };

  useEffect(() => {
    fetchImages();
    fetchImagess();
    fetchCategories();
  }, []);

  const handleBannerImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file);
    }
  };

  const handleShowMore = (category) => {
    // Navigate to a new page with the selected category as a parameter
    navigate(`/category/${encodeURIComponent(category)}`);
    setAddingMoreImages(true);
    setCurrentCategory(category);
  };

  const handleAddImage = () => {
    inputFileReels.current.click();
  };

  const handleCreatePhoto = () => {
    if (selectedImage) {
      setSavingImage(true);
      const formData = new FormData();
      formData.append("photoimg", selectedImage, selectedImage.name);
      formData.append("category", selectedCategory || "");

      if (selectedCategory && !category.includes(selectedCategory)) {
        setCategory([...category, selectedCategory]);
      }

      ApiServices.post(ApiEndPoints.CreatePhoto, formData)
        .then((response) => {
          if (response?.status?.code === 200) {
            console.log(response);
            fetchCategories();
            fetchImages();
            fetchImagess();
          } else {
            console.log("Error creating banner:", response);
          }
        })
        .catch((error) => {
          console.log("Error creating banner:", error);
        })
        .finally(() => {
          setSavingImage(false);
          setSelectedImage(null);
          setSelectedCategory("");
        });
    }
  };

  const handleSaveImages = () => {
    if (selectedCategory && selectedImage) {
      const formData = new FormData();
      formData.append("category", currentCategory || "");
      Array.from(selectedImage).forEach((image) => {
        formData.append("photoimg[]", image, image.name);
      });

      if (currentCategory && !category.includes(currentCategory)) {
        setCategory([...category, currentCategory]);
      }

      ApiServices.post(ApiEndPoints.CreatePhoto, formData)
        .then((response) => {
          if (response?.status?.code === 200) {
            console.log(response);
          } else {
            console.log("Error creating banner:", response);
          }
        })
        .catch((error) => {
          console.log("Error creating banner:", error);
        })
        .finally(() => {
          setAddingMoreImages(false);
          setSelectedImage(null);
          setSelectedCategory("");
          setCurrentCategory("");
        });
    }
  };

  const handleAddMoreImages = () => {
    setAddingMoreImages(true);
  };

  const isCategorySelected = selectedCategory !== "";

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  return (
    <>
      <Header></Header>
      <div>
        <div className="home-one-cta-two cta-area-1 banner_1">
          <div className="container">
            <div className="row text-center">
              <div className="col-md-12">
                <div className="about_over" />
                <div className="about_school">
                  <h1>Gallery </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="missionandvision-content-contaioner">
          <div class="edu-course course-style-4 course-style-14">
            <div class="inner">
              <div class="content ">
                <div className="inner-content">
                  <h2>Photo Gallery</h2>
                  <div className="inside-photogallery">
                    <div>
                      <div className="inside-photogallery-child">
                        {Object.keys(categories).map((category) => (
                          <div key={category}>
                            <a
                              className="inside-photogallery-child-anchor"
                              onClick={() => handleShowMore(category)}
                            >
                              <li>
                                {showAllImages ? (
                                  categories[category].map((item) => (
                                    <div>
                                      <img
                                        key={item.id}
                                        src={
                                          categories[category][0].photoImgUrl
                                        }
                                        alt={item.name}
                                      />
                                    </div>
                                  ))
                                ) : (
                                  <img
                                    key={categories[category][0].id}
                                    src={categories[category][0].photoImgUrl}
                                    alt={categories[category][0].name}
                                  />
                                )}
                              </li>
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={() => handleShowMore(category)}
                              >
                                {category}
                              </span>
                            </a>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>{" "}
                <br />
                <br />
                <br />
                <br />
              </div>{" "}
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}

export default PhotoGallery;
