import React, { useState, useRef, useEffect } from "react";
import ApiEndPoints from "../../../NetworkCall/ApiEndPoint";
import ApiServices from "../../../NetworkCall/ApiServices";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
function VideoGalary() {
  const inputFileReels = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [savingImage, setSavingImage] = useState(false);
  const [video, setVideo] = useState([]);
  const fetchImages = () => {
    ApiServices.get(ApiEndPoints.getVideos)
      .then((response) => {
        if (response.status.code === 200) {
          setVideo(response.data.videoData);
          console.log(response);
        }
      })
      .catch((error) => {
        console.log("Error fetching banner images:", error);
      });
  };

  useEffect(() => {
    fetchImages();
  }, []);

  const handleBannerImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file);
    }
  };

  const handleAddImage = () => {
    inputFileReels.current.click();
  };

  const handleCreateBanner = () => {
    if (selectedImage) {
      setSavingImage(true); // Show loading state
      const formData = new FormData();
      formData.append("videos", selectedImage, selectedImage.name);

      ApiServices.post(ApiEndPoints.CreateVideo, formData)
        .then((response) => {
          if (response?.status?.code === 200) {
            console.log(response);
            fetchImages(); // Refresh the images after successful creation
          } else {
            console.log("Error creating banner:", response);
          }
        })
        .catch((error) => {
          console.log("Error creating banner:", error);
        })
        .finally(() => {
          setSavingImage(false); // Hide loading state
          setSelectedImage(null); // Reset selectedImage after successful save
        });
    }
  };

  return (
    <div>
      <Header></Header>
      <div>
        <div className="home-one-cta-two cta-area-1 banner_1">
          <div className="container">
            <div className="row text-center">
              <div className="col-md-12">
                <div className="about_over" />
                <div className="about_school">
                  <h1>Gallery</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <input
        placeholder="Click here"
        type="file"
        accept="videos/*"
        onChange={handleBannerImage}
        ref={inputFileReels}
        style={{ display: "none" }}
      />
      <button onClick={handleAddImage} style={{ display: "none" }}>
        Select Videos
      </button>
      <button
        onClick={handleCreateBanner}
        style={{ display: "none" }}
        disabled={!selectedImage || savingImage}
      >
        {savingImage ? "Saving Video..." : "Save Video"}
      </button>

      <div className="missionandvision-content-contaioner">
        <div class="edu-course course-style-4 course-style-14">
          <div class="inner">
            <div class="content ">
              <div className="inner-content">
                <h2>Video Gallery</h2>
                <div>
                  <div className="row">
                    {video.map((item) => (
                      <div
                        key={item.id}
                        className="col-md-4 innerrow"
                        style={{ overflow: "hidden" }}
                      >
                        <iframe
                          style={{ overflow: "hidden" }}
                          src={item.videoUrl}
                          height="200"
                          width="100%"
                          title="Iframe Example"
                          frameborder="0"
                          type="video/mp4"
                          autoplay
                          scrolling="no"
                        ></iframe>
                      </div>
                    ))}
                  </div>
                </div>
                <br />
                <br />
              </div>
            </div>
          </div>
          <br />
          <br />
        </div>
      </div>

      <Footer></Footer>
    </div>
  );
}

export default VideoGalary;
