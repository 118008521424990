import React from "react";
import Footer from "../../common/Footer";
import Header from "../../common/Header";
import "../Facilites/Facilities.css";
import musicdrama1 from "../../../assets/images/activities/musicdrama1.jpg";
import musicdrama2 from "../../../assets/images/activities/musicdrama2.jpg";
import musicdrama3 from "../../../assets/images/activities/musicdrama3.jpg";
import musicdrama4 from "../../../assets/images/activities/musicdrama4.jpg";
import musicdrama5 from "../../../assets/images/activities/musicdrama5.jpg";
import musicdrama6 from "../../../assets/images/activities/musicdrama6.jpg";

function MusicDrama() {
  return (
    <div>
      <Header></Header>
      <div class="edu-breadcrumb-area banner_1">
        <div class="container">
          <div class="breadcrumb-inner">
            <div class="page-title">
              <h1 class="title" style={{ position: "relative", zIndex: "1" }}>
                Activities
              </h1>
            </div>
          </div>
        </div>
        <div className="about_over"></div>
      </div>
      <section class="section-gap-equal">
        <div class="container">
          <div class="row row--30">
            <div class="col-lg-12">
              <div class="amenities_head">
                <h3 class="title mb-0">MUSIC, ART & DRAMA</h3>
                <p style={{ textAlign: "justify" }}>
                  Various activities, events and sports/ games are organized
                  throughout the session to develop the spirit of healthy
                  competition among students. These activities help teachers to
                  identify student’s skill, also provide platform and
                  opportunity to students to express their own thoughts in
                  public. To make it more effective, students are divided into
                  four houses.
                </p>
              </div>
            </div>

            <div class="mb-0">
              <div class="isotope-list gallery-grid-wrap">
                <div id="animated-thumbnials" className="flexboxprop">
                  <a
                    href="assets/images/gallery/gallery-01.webp"
                    class="edu-popup-image edu-gallery-grid p-gallery-grid-wrap isotope-item education"
                  >
                    <div>
                      <img
                        src={musicdrama1}
                        alt="Gallery Image"
                        className="mainimagestag"
                      />
                    </div>
                  </a>

                  <a
                    href="assets/images/gallery/gallery-02.webp"
                    class="edu-popup-image edu-gallery-grid p-gallery-grid-wrap isotope-item marketing"
                  >
                    <div>
                      <img
                        className="mainimagestag"
                        src={musicdrama2}
                        alt="Gallery Image"
                      />
                    </div>
                  </a>

                  <a
                    href="assets/images/gallery/gallery-03.webp"
                    class="edu-popup-image edu-gallery-grid p-gallery-grid-wrap isotope-item education health"
                  >
                    <div>
                      <img
                        className="mainimagestag"
                        src={musicdrama3}
                        alt="Gallery Image"
                      />
                    </div>
                  </a>

                  <a
                    href="assets/images/gallery/gallery-04.webp"
                    class="edu-popup-image edu-gallery-grid p-gallery-grid-wrap isotope-item marketing"
                  >
                    <div>
                      <img
                        className="mainimagestag"
                        src={musicdrama4}
                        alt="Gallery Image"
                      />
                    </div>
                  </a>

                  <a
                    href="assets/images/gallery/gallery-05.webp"
                    class="edu-popup-image edu-gallery-grid p-gallery-grid-wrap isotope-item education"
                  >
                    <div>
                      <img
                        className="mainimagestag"
                        src={musicdrama5}
                        alt="Gallery Image"
                      />
                    </div>
                  </a>

                  <a
                    href="assets/images/gallery/gallery-06.webp"
                    class="edu-popup-image edu-gallery-grid p-gallery-grid-wrap isotope-item health"
                  >
                    <div>
                      <img
                        className="mainimagestag"
                        src={musicdrama6}
                        alt="Gallery Image"
                      />
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </div>
  );
}

export default MusicDrama;
