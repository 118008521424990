import React from 'react'
import Header from '../../common/Header'
import Footer from '../../common/Footer'

function Missionvission() {
  return (
<>
<Header></Header>
<div>
        <div className="home-one-cta-two cta-area-1 banner_1">
          <div className="container">
            <div className="row text-center">
              <div className="col-md-12">
                <div className="about_over" />
                <div className="about_school">
                  <h1>About Us</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="missionandvision-content-contaioner">
        <div class="edu-course course-style-4 course-style-14">
          <div class="inner">
            <div class="content">
              <div className="inner-content">
                <h2>Mission & Vision</h2>
                <div>
                  <h6 class="title">
                    <a href="course-details.html">Mission</a>
                  </h6>
                  <div className="image-parent-float-left">
                    <a href="course-details.html">
                      <img
                        style={{height:"140px"}}
                        src="https://t4.ftcdn.net/jpg/00/96/54/53/360_F_96545306_cX6N4Fv2TTVRMKahA3aoCvxlUOGm2KkV.jpg"
                        alt="Course Meta"
                      />
                    </a>
                  </div>
                  <p>
                  The pursuit of excellence encouraged at JGIS rests on the positive belief that every man has it in him to produce work and excel. We strive to provide an exciting and rigorous educational experience designed to help each student grow into a caring, thoughtful principled personality and socially well-balanced human being. Each student right from primary classes will have opportunities to become technologically literate, learn to communicate effectively, work in teams and be a risk-taker in a supported environment

It is the aim of Jai Gurudev International School to provide the best possible education to its students. Our primary aim is to develop in the student qualities of integrity, honesty, trust, tolerance and compassion, to promote a spirit of enquiry, to foster a scientific temper within the bonds of humanism, to help the student to become a meaningful part of his environment and to know that courage and industry have their due reward.
                  </p>
                </div>
              </div>
              <div className="inner-content">
                <br /> <br /> <br />
                <div>
                  <h6 class="title">
                    <a href="course-details.html">Vision</a>
                  </h6>
                  <div className="image-parent-float-left">
                    <a href="course-details.html">
                      <img
                        style={{height:"140px"}}
                        src="https://thumbs.dreamstime.com/b/business-team-light-bulb-questions-two-women-men-wearing-glasses-standing-near-concrete-wall-two-question-marks-91508919.jpg"
                        alt="Course Meta"
                      />
                    </a>
                  </div>
                  <p>
                  We are committed to providing quality education to young minds that encompass not only academic excellence but fosters ethical, social and spiritual awareness. Jai Gurudev International School understands that its task as a premier school for boys and girls is to create an environment that nurtures its pupil’s ambitions and abilities where students find their voices, develop their innate talents and learn leadership skills to equip them for their roles as global citizens ready to face the challenges by responding creatively in a world of relentless competitions.

To empower our students in an environment of sharing and caring so that they evolve as compassionate, self-reliant and productive citizens, responsive to the rich culture and heritage of our country, inspired to embrace challenges and find joy and self-worth through achievement, academically excelling, holistic individuals with a scientific temper.
                  </p>
                </div>
              </div>
            </div>
          </div>{" "}
          <br />
          <br />
        </div>
      </div>
      <Footer></Footer>
</>
  )
}

export default Missionvission