import Axios from 'axios';

const axiosInstance = Axios.create();

axiosInstance.interceptors.request.use(
  (config) => {
    config.headers = { 'Access-Control-Allow-Origin': '*' };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const getFormData = (object) => {
  const formData = new FormData();
  Object.keys(object).forEach((key) => formData.append(key, object[key]));
  return formData;
};

const ApiServices = {
  login: async (username, password) => {
    const config = {
      method: 'post',
      url: 'https://api.example.com/login',
      data: {
        username,
        password
      },
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      }
    };
    try {
      const response = await axiosInstance(config);
      return response.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
      return { status: 400 };
    }
  },
  get: async (url, params = {}) => {
    const config = {
      method: 'get',
      url,
      params,
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    };
    try {
      const response = await axiosInstance(config);
      return response.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
      return { status: 400 };
    }
  },
  post: async (url, data = {}, formData = false) => {
    const config = {
      method: 'post',
      url,
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    };
    if (formData) {
      config.data = getFormData(data);
    } else {
      config.data = data;
    }
    try {
      const response = await axiosInstance(config);
      return response.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
      return { status: 400 };
    }
  },
  delete: async (url) => {
    const config = {
      method: 'delete',
      url,
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    };
    try {
      const response = await axiosInstance(config);
      return response.data;
    } catch (error) {
      if (error.response) {
        return error.response.data;
      }
      return { status: 400 };
    }
  }
};

export default ApiServices;
