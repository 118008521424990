import React, { useRef, useState } from "react";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import emailjs from "@emailjs/browser";
import { toast, ToastContainer } from "react-toastify";

const AddmisionEnqurey = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    const formData = new FormData(form.current);

    const errors = {};

    if (!formData.get("user_name")) {
      errors.user_name = "Name is required";
    }

    if (!formData.get("user_email")) {
      errors.user_email = "Email is required";
    }

    if (!formData.get("contact_phone")) {
      errors.contact_phone = "Phone number is required";
    }

    if (!formData.get("parent_name")) {
      errors.parent_name = "Parent name is required";
    }

    if (!formData.get("message")) {
      errors.message = "Enter your class is required";
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    // If validation passes, send email
    emailjs
      .sendForm(
        "service_xil6hei",
        "template_bd57sei",
        form.current,
        "f-TdLoHUtNT2WVsj8"
      )
      .then((result) => {
        console.log(result.text);
        toast.success("Thank you for your submission!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
        setIsSubmitted(true);

        setFormErrors({});
        form.current.reset();
      })
      .catch((error) => {
        console.log(error.text);
        toast.error("An error occurred. Please try again later.", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      });
  };
  return (
    <>
      <Header></Header>
      <div className="edu-breadcrumb-area">
        <div className="container">
          <div className="breadcrumb-inner">
            <div className="page-title">
              <h1 className="title">Admission Enquiry</h1>
            </div>
          </div>
        </div>
        <img src="assets/images/contact.jpg" className="contact_image" />
      </div>

      <section className="contact-us-area enquiry_area">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-8 m-auto">
              <div className="contact-form form-style-2">
                <div className="section-title">
                  <h4 className="title">Admission Enquiry</h4>
                  <p>
                    Fill out this form for booking a consultant advising
                    session.
                  </p>
                </div>
                <form
                  ref={form}
                  onSubmit={sendEmail}
                  className="rnt-contact-form rwt-dynamic-form enqury_frm"
                  id="contact-form"
                  method="POST"
                  action="https://edublink.html.devsblink.com/mail.php"
                >
                  <div className="row row--10">
                    <div className="form-group col-12">
                      <input
                        type="text"
                        name="user_name"
                        id="contact-name"
                        placeholder="Student name"
                      />
                      {formErrors.user_name && (
                        <div className="error-message">
                          {formErrors.user_name}
                        </div>
                      )}
                    </div>
                    <div className="form-group col-12">
                      <input
                        type="text"
                        name="parent_name"
                        id="contact-email"
                        placeholder="Parent Name"
                      />
                      {formErrors.parent_name && (
                        <div className="error-message">
                          {formErrors.parent_name}
                        </div>
                      )}
                    </div>
                    <div className="form-group col-12">
                      <textarea
                        name="message"
                        id="contact-message"
                        cols="30"
                        rows="4"
                        placeholder="Enter class"
                      ></textarea>
                      {formErrors.message && (
                        <div className="error-message">
                          {formErrors.message}
                        </div>
                      )}
                    </div>
                    <div className="form-group col-12">
                      <input
                        type="email"
                        name="user_email"
                        id="contact-email"
                        placeholder="Enter email address"
                      />
                      {formErrors.user_email && (
                        <div className="error-message">
                          {formErrors.user_email}
                        </div>
                      )}
                    </div>
                    <div className="form-group col-12">
                      <input
                        type="number"
                        name="contact_phone"
                        id="contact-phone"
                        placeholder="Phone Number"
                      />
                      {formErrors.contact_phone && (
                        <div className="error-message">
                          {formErrors.contact_phone}
                        </div>
                      )}
                    </div>
                    <div className="form-group col-12">
                      <button
                        className="rn-btn edu-btn btn-medium submit-btn"
                        name="submit"
                        type="submit"
                      >
                        Submit Message <i className="icon-4" />
                      </button>
                    </div>
                  </div>
                </form>
                <ul className="shape-group">
                  <li className="shape-1 scene">
                    <img
                      data-depth={1}
                      src="assets/images/about/shape-13.png"
                      alt="Shape"
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer></Footer>
    </>
  );
};

export default AddmisionEnqurey;
