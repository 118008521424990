import React from 'react'
import Footer from '../../common/Footer'
import Header from '../../common/Header'

function PrimaryStage() {
  return (
    <div>
      <Header></Header>
          <div class="edu-breadcrumb-area breadcrumb-style-2 bg-image bg-image--19">
        <div class="container">
          <div class="breadcrumb-inner">
            <div class="page-title">
              <h1 class="title">Academics</h1>
            </div>
          </div>
        </div>
      </div>
      <div class="blog-details-area section-gap-equal">
        <div class="container">
          <div class="row row--30">
            <div class="col-lg-12">
              <div class="blog-details-content foun_stage_main">
                <div class="entry-content">
                  <h3 class="title">Preparatory Stage</h3>
                  <div class="thumbnail">
                    <img src="assets/images/blog/blog-large-1.jpg" alt="Blog Image" />
                  </div>
                </div>
                <p> </p>
              </div>
              <div class="course-overview">
                <div class="border-box">
                    <h5 class="title" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">What You’ll Learn?</h5>
                    <div class="row g-5">
                        <div class="col-lg-6" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                            <ul>
                                <li>English – First Language</li>
                                <li>Hindi Second Language</li>
                            </ul>
                        </div>
                        <div class="col-lg-6" data-sal-delay="150" data-sal="slide-up" data-sal-duration="800">
                            <ul>
                                <li>Environment Science</li>
                                <li>Life Skills</li>
                            </ul>
                        </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default PrimaryStage